import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { City, Country, District, ISelect, InputFieldI } from ".";
import Api from "../../service";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import DHL from "./icons/dhl.svg";
import ReactSelect, { SingleValue } from "react-select";
import useCart from "../../hooks/use-cart";
import Select from "react-select";
import { breakPoints } from "../../constant/breakpoints";
import PaymentContext from "./context/payment-context";
import { toast } from "react-toastify";
import LoadingPage from "../../components/LoadingPage";
import { CustomRenderOption } from "./Step1";
import { isValidZipCode } from "../../utils/utils";
import ZipCode from "./components/zipcode/ZipCode";

const Container = styled.div`
  width: 100%;
  .input-select {
    height: 4rem;
    margin-bottom: 1.8rem;
    min-width: 15rem;
    color: var(--grey-90, #222);
    font-family: "Archivo";
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    outline: none !important;
    &:focus-within {
      border: none;
    }
  }
  .input-select-phone-code {
    height: 4rem;
    min-width: 15rem;
    color: var(--grey-90, #222);
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    outline: none !important;
    &:focus-within {
      border: none;
    }
  }
  .input-select-error {
    margin-bottom: 1.8rem;
    min-width: 15rem;
    color: var(--grey-90, #222);
    font-family: Archivo;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    &:focus-within {
      border: none;
    }
  }
  .input-select-error > .select__control {
    border: 1px solid rgb(246 79 89) !important;
  }

  .input-select > input {
    min-width: 5rem !important;
    max-width: 5rem !important;
  }
  .select__control {
    border: 1px solid #0000008a !important;
    outline: none !important;
    box-shadow: none !important;
  }
  /* @media (max-width: ${breakPoints.ipad}) {
    .input-select {
      font-size: 1.4rem;
    }
  } */
`;
const Input = styled.input<{ error?: boolean }>`
  height: 4rem;
  font-family: "Archivo";
  border: 1px solid ${({ error }) => (error ? "rgb(246 79 89)" : "#0000008a")};
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 1.8rem;
  &:focus {
    outline: none !important;
  }
  /* @media (max-width: ${breakPoints.ipad}) {
    font-size: 1.4rem;
  } */
`;
const InputPhoneNumber = styled(Input)`
  margin-bottom: 0;
`;
const SelectCustom = styled.select`
  /* width: 12rem; */
  width: 100%;
  height: 4rem;
  border: 1px solid #0000008a;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 32px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 1.8rem;
  &:focus {
    outline: none !important;
  }
`;

const TextField = styled.div`
  font-size: 2rem;
  margin-bottom: 1.2rem;
  font-weight: 600;
`;

const Flex2Col = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 49%;
  }
`;
const ShipmentError = styled.p`
  font-size: 1.6rem;
  color: red;
`;

const Step2 = ({
  onCountryChangeHandler,
  onCityChangeHandler,
  onDistrictChangeHandler,
  onPhoneCodeReceiverChangeHandler,
  setSupplier,
  setListCities,
  setCompany,
  setApartment,
  addressInput,
  postalCodeInput,
  phoneCodeReceiver,
  phoneNumberReceiverInput,
  listCountries,
  listCities,
  country,
  countryError,
  city,
  cityError,
  district,
  districtError,
  company,
  apartment,
  supplier,
}: {
  onCountryChangeHandler: (event: any) => void;
  onCityChangeHandler: (event: any) => void;
  onDistrictChangeHandler: (event: any) => void;
  onPhoneCodeReceiverChangeHandler: (event: any) => void;
  setSupplier: (supplier: any) => void;
  setListCities: Dispatch<SetStateAction<City[]>>;
  setCompany: Dispatch<SetStateAction<string>>;
  setApartment: Dispatch<SetStateAction<string>>;
  addressInput: InputFieldI;
  postalCodeInput: InputFieldI;
  phoneCodeReceiver: ISelect | null;
  phoneNumberReceiverInput: InputFieldI;
  listCountries: Country[];
  listCities: City[];
  country: ISelect | null;
  countryError: boolean;
  city: ISelect | null;
  cityError: boolean;
  district: ISelect | null;
  districtError: boolean;
  company: string;
  apartment: string;
  supplier: any;
}) => {
  const { t } = useTranslation();
  const [listSuppliers, setListSuppliers] = useState<any[]>([]);
  const [listDistricts, setListDistricts] = useState<District[]>([]);
  const [shipmentError, setShipmentError] = useState(false);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);

  const { cart } = useCart();

  const getTotalQuantity = useCallback(() => {
    let totalQuantity = 0;
    cart?.items?.forEach((item) => {
      totalQuantity += item.quantity;
    });

    return totalQuantity;
  }, [cart?.items]);

  const onSupplierChangeHandler = async (value: any) => {
    const supplier = await listSuppliers.find(
      (s: any) => s.productName === value.label
    );
    setSupplier(supplier);
  };

  const fetchCities = useCallback(async () => {
    if (!country || !country.value) return;

    const response = await Api.Payment.getCities({ country_id: country.value });
    if (response.data) {
      setListCities(response.data);
    }
  }, [country, setListCities]);

  const fetchSuppliers = async (newCity?: SingleValue<ISelect>) => {
    try {
      setShipmentError(false);
      if (
        !country ||
        !city ||
        !city.code ||
        !country.code ||
        !addressInput.value
      )
        return;
      setLoadingSuppliers(true);
      const response = await Api.Payment.getSuppliers({
        country_code: country.code,
        state_code: newCity?.code ? newCity.code : city.code,
        quantity: getTotalQuantity(),
        address_line_1: addressInput.value,
      });
      if (response.data) {
        setListSuppliers(response.data);
        setSupplier(response.data[0]);
      }
      if (response.data && response.data.length === 0) {
        setShipmentError(true);
        setSupplier(null);
      }
    } finally {
      setLoadingSuppliers(false);
    }
  };

  const fetchDistricts = useCallback(async () => {
    if (!country || !city || !city.value || !country.value) return;

    const response = await Api.Payment.getDistricts({
      country_id: +country.value,
      state_id: +city.value,
    });
    if (response.data) {
      setListDistricts(response.data);
    }
  }, [city]);

  useEffect(() => {
    fetchCities();
    fetchSuppliers();
    fetchDistricts();
  }, [fetchCities, fetchDistricts]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const phoneCodeOptions = listCountries.filter(
    (country) => country?.phone_code !== ""
  );

  const removedCharacters = phoneCodeOptions.map((code) => {
    return { ...code, phone_code_sort: +code.phone_code.replace(/\D/g, "") };
  });

  removedCharacters.sort((a, b) =>
    a.phone_code_sort > b.phone_code_sort ? 1 : -1
  );

  const uniquePhoneCodeOptions = [
    ...new Map(
      removedCharacters.map((item) => [item["phone_code_sort"], item])
    ).values(),
  ];

  const placeHolderAddress = t("Street Name, Building, House No.");

  return (
    <Container>
      {loadingSuppliers && <LoadingPage />}
      <TextField>{t("Shipping Address")} (*)</TextField>
      <ReactSelect
        classNamePrefix="select"
        className={!countryError ? "input-select" : "input-select-error"}
        onChange={onCountryChangeHandler}
        placeholder={`${t("Country/Region")} (*)`}
        value={country}
        options={listCountries.map((country) => {
          return {
            label: country.name,
            value: country.id,
            code: country.iso2,
            id: country.id,
          };
        })}
      />
      <Flex2Col>
        <div>
          <ReactSelect
            classNamePrefix="select"
            className={!cityError ? "input-select" : "input-select-error"}
            value={city}
            onChange={(event) => {
              onCityChangeHandler(event);
              fetchSuppliers(event);
            }}
            placeholder={`${t("City/Province")} (*)`}
            options={listCities.map((city) => {
              return {
                label: city.name,
                value: city.id,
                code: city.state_code,
                id: city.id,
              };
            })}
          />
        </div>
        <div>
          <ReactSelect
            classNamePrefix="select"
            className={!districtError ? "input-select" : "input-select-error"}
            value={district}
            onChange={onDistrictChangeHandler}
            placeholder={`${t("District")}`}
            options={listDistricts.map((district) => {
              return {
                label: district.name,
                value: district.id,
                code: district.state_code,
                id: district.id,
              };
            })}
          />
        </div>
      </Flex2Col>

      <Input
        placeholder={`${placeHolderAddress} (*)`}
        onChange={addressInput.valueChangeHandler}
        value={addressInput.value}
        error={addressInput.error}
        onFocus={() => {
          addressInput.setError(false);
        }}
        onBlur={() => {
          fetchSuppliers();
        }}
      />
      <Input
        value={apartment}
        placeholder={t(`Apartment, suite, ect. (optional)`)}
        onChange={(e) => setApartment(e.target.value)}
      />
      <Input
        placeholder={t(`Company (Optional)`)}
        value={company}
        onChange={(e) => setCompany(e.target.value)}
      />

      <div style={{ display: "flex", gap: "1rem", marginBottom: "1.8rem" }}>
        <div>
          <Select
            defaultValue={phoneCodeReceiver}
            classNamePrefix="select"
            className="input-select-phone-code"
            onChange={onPhoneCodeReceiverChangeHandler}
            options={uniquePhoneCodeOptions.map((code) => {
              return {
                label: code.phone_code,
                value: code.phone_code,
                code: code.phone_code,
                emoji: code.emoji,
              };
            })}
            styles={{}}
            formatOptionLabel={({ value, label, emoji }) => (
              <CustomRenderOption>
                <span>
                  {!label?.includes("+") && <span>+&nbsp;</span>}
                  {label} {emoji}
                </span>
              </CustomRenderOption>
            )}
          />
        </div>
        <InputPhoneNumber
          type="number"
          value={phoneNumberReceiverInput.value}
          onChange={phoneNumberReceiverInput.valueChangeHandler}
          error={phoneNumberReceiverInput.error}
          onFocus={() => {
            phoneNumberReceiverInput.setError(false);
          }}
        />
      </div>

      <TextField>{t("Post/Zip Code")} (*)</TextField>
      <Input
        onChange={(e: any) => {
          if (
            isValidZipCode.test(e.target.value) &&
            e.target.value.length <= 7
          ) {
            postalCodeInput.valueChangeHandler(e);
          }
        }}
        value={postalCodeInput.value}
        error={postalCodeInput.error}
        onFocus={() => {
          postalCodeInput.setError(false);
        }}
      />
      <ZipCode
        country={country?.label}
        city={city?.label}
        district={district?.label}
      />

      <TextField>{t("Shipping Method")} (*)</TextField>
      <ReactSelect
        classNamePrefix="select"
        className="input-select"
        onChange={onSupplierChangeHandler}
        value={{
          label: supplier?.productName,
          value: supplier?.productName,
          code: supplier?.productCode,
        }}
        placeholder={t("Delivery")}
        isDisabled={
          !city || !country || listSuppliers.length <= 0 ? true : false
        }
        options={listSuppliers.map((supplier: any) => {
          return {
            label: supplier.productName,
            value: supplier.productName,
            code: supplier.productCode,
          };
        })}
      />
      {shipmentError && (
        <ShipmentError>
          <span>(*)</span>{" "}
          {t(
            "This City is not available for shipping, please try again or choosing another city"
          )}
        </ShipmentError>
      )}
    </Container>
  );
};

export default Step2;
