import Footer from "./footer/Footer";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import Header from "./header/Header";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { breakPoints } from "../../constant/breakpoints";
import StickyButton from "../ChatBotSticky";
import ChatBot from "../ChatBot";
import { useEffect } from "react";
import useCart from "../../hooks/use-cart";
import { PaymentMethod } from "../../pages/payment";
import InitialPopup from "./InitialPopup";
import TelegramStickyV2 from "../TelegramSticky/TelegramStickyV2";
import { toast } from "react-toastify";

const LayoutWrap = styled.div`
  position: relative;
  @media (max-width: ${breakPoints.mobile}) {
    padding: 0;
  }
  overflow-y: auto;
`;
export const MainLayoutContainer = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  padding: 0 2.4rem;
  @media (min-width: ${breakPoints.mobile}) {
    max-width: ${breakPoints.mobile};
  }
  @media (min-width: ${breakPoints.ipad}) {
    max-width: ${breakPoints.ipad};
  }
  @media (min-width: ${breakPoints.laptop}) {
    max-width: ${breakPoints.laptop};
  }
  @media (min-width: ${breakPoints.desktop}) {
    padding: 0 5rem;
    max-width: ${breakPoints.desktop};
  }
  @media (min-width: ${breakPoints.lgDesktop}) {
    max-width: 1920px;
  }
  @media (min-width: ${breakPoints.xDesktop}) {
    max-width: 1920px;
  }
`;
export const OutLayoutContainer = styled.div`
  width: 100%;
  min-height: 100dvh;
`;
export const MainLayout = () => {
  return (
    <MainLayoutContainer>
      <Outlet />
    </MainLayoutContainer>
  );
};

export const OutLayout = () => {
  return (
    <OutLayoutContainer>
      <Outlet />
    </OutLayoutContainer>
  );
};

export const PDFLayout = () => {
  return (
    <MainLayoutContainer style={{ background: "#525659" }}>
      <Outlet />
    </MainLayoutContainer>
  );
};

const MarginOutlet = styled.div`
  /* margin-top: 10rem;
  @media screen and (max-width: ${breakPoints.ipad}) {
    margin-top: 5.5rem;
  } */
`;

function Layout() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { changePaymentMethod } = useCart();

  const step = searchParams.get("step");
  useEffect(() => {
    if (!pathname.includes("/purchase/product")) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    toast.dismiss();
  }, [pathname]);

  useEffect(() => {
    if (!step || step !== "3") {
      changePaymentMethod(PaymentMethod.Paypal);
    }
  }, [step]);

  return (
    <LayoutWrap id="layout-wrap">
      <Header />
      <MarginOutlet>
        <Outlet />
      </MarginOutlet>
      <Footer />
      {/* <TelegramStickyV2 />
      <ChatBot />
      <StickyButton />
      <InitialPopup /> */}
    </LayoutWrap>
  );
}

export default Layout;
