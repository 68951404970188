import React from "react";

const TelegramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <path
        d="M19.7266 4.65088L2.375 11.5002L8.76768 12.4134M19.7266 4.65088L17.4435 18.3495L8.76768 12.4134M19.7266 4.65088L8.76768 12.4134M8.76768 12.4134V17.4362L11.7348 14.4436"
        stroke="#fff"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TelegramIcon;
