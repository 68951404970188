import React, { Suspense } from "react";
import Layout, {
  MainLayout,
  OutLayout,
  PDFLayout,
} from "./components/layout/Layout";
import "./i18n";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages";
import PrivacyPolicy from "./pages/privacyPolicy";
import CustomToast from "./components/layout/CustomToast";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import LoadingPage from "./components/LoadingPage";
import Page404 from "./pages/404";
import LayoutLogin from "./components/layoutLogin";
import { ProductOutlet } from "./context/product-context";
import { PaymentOutlet } from "./pages/payment/context/payment-context";
import PurchaseMain from "./pages/PurchaseMain";
import Purchase from "./pages/Purchase";
import Payment from "./pages/payment";
import SignIn from "./pages/signIn";
import SignUp from "./pages/signUp";
import WhyAIEars from "./pages/about/components/whyAiEars";
import OutTeam from "./pages/about/components/outTeam";
import Coporate from "./pages/about/components/corporate";
import Support from "./pages/about/components/support";
import AboutUs from "./pages/about";
import AboutCommunity from "./pages/about/components/community";
import ContactUS from "./pages/about/components/contactUs";
import FAQs from "./pages/about/components/FAQs";
import Community from "./pages/Community";
import PrivacyPolicyPoolsKeyboard from "./pages/privacyPolicyPoolsKeyboard";
import PrivacyPolicyPoolsDashboard from "./pages/privacyPolicyPoolsDashboard";
import PrivacyPolicyPoolsSlide from "./pages/privacyPolicyPoolsSlide";
import PrivacyPolicyPoolsWalk from "./pages/privacyPolicyPoolsWalk";
import PrivacyPolicyPoolsCall from "./pages/privacyPolicyPoolsCall";
import PrivacyPolicyPoolsPlayEarn from "./pages/privacyPolicyPoolsPlayEarn";
import PrivacyPolicyPoolsScratch from "./pages/privacyPolicyPoolsScratch";
import TermOfUsePolicy from "./pages/termOfUsePolicy";
dayjs.extend(relativeTime);

function App() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route element={<CustomToast />}>
          <Route element={<PDFLayout />}>
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Route>
          <Route element={<PDFLayout />}>
            <Route path="/term-of-use-policy" element={<TermOfUsePolicy />} />
          </Route>
          <Route element={<PDFLayout />}>
            <Route path="/pools-keyboard-privacy-policy" element={<PrivacyPolicyPoolsKeyboard />} />
          </Route>
          <Route element={<PDFLayout />}>
            <Route path="/pools-dashboard-privacy-policy" element={<PrivacyPolicyPoolsDashboard />} />
          </Route>
          <Route element={<PDFLayout />}>
            <Route path="/pools-slide-privacy-policy" element={<PrivacyPolicyPoolsSlide />} />
          </Route>
          <Route element={<PDFLayout />}>
            <Route path="/pools-walk-privacy-policy" element={<PrivacyPolicyPoolsWalk />} />
          </Route>
          <Route element={<PDFLayout />}>
            <Route path="/pools-call-privacy-policy" element={<PrivacyPolicyPoolsCall />} />
          </Route>
          <Route element={<PDFLayout />}>
            <Route path="/pools-scratch-privacy-policy" element={<PrivacyPolicyPoolsScratch />} />
          </Route>
          <Route element={<PDFLayout />}>
            <Route path="/pools-play-earn-privacy-policy" element={<PrivacyPolicyPoolsPlayEarn />} />
          </Route>
          <Route element={<Layout />}>
            <Route element={<OutLayout />}>
              <Route path="/" element={<HomePage />} />
            </Route>
            {/* <Route element={<OutLayout />}>
              <Route path="/purchase" element={<ProductOutlet />}>
                <Route element={<PaymentOutlet />}>
                  <Route index element={<PurchaseMain />} />
                  <Route path="product/:color" element={<Purchase />} />
                  <Route path="payment" element={<Payment />} />
                </Route>
              </Route>
              <Route path="/about-us">
                <Route index element={<AboutUs />} />
                <Route path="why-ai-ears" element={<WhyAIEars />} />
                <Route path="our-team" element={<OutTeam />} />
                <Route path="corporate" element={<Coporate />} />
                <Route path="our-community" element={<AboutCommunity />} />
                <Route path="FAQs" element={<FAQs />} />
                <Route path="contact-us" element={<ContactUS />} />
              </Route>
              <Route element={<MainLayout />}>
                <Route path="/community" element={<Community />} />
              </Route>
            </Route> */}
          </Route>
          {/* <Route element={<LayoutLogin />}>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
          </Route> */}
          <Route path="/*" element={<Page404 />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
