import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSSR, useTranslation } from "react-i18next";
import styled from "styled-components";
import { ButtonGradient } from "../../components/ButtonGradient";
import useModal from "../../components/modal/useModal";
import Step1 from "./Step1";
import PinInput from "react-pin-input";
import Step2 from "./Step2";
import Step3 from "./Step3";
import useInput from "../../hooks/use-input";
import PaymentContext, { InfoI, ShippingI } from "./context/payment-context";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { breakPoints } from "../../constant/breakpoints";
import Api, { IDetail, OrderI } from "../../service";
import CountDown from "../../components/Countdown";
import PaymentConfirmation from "./components/paymentConfirmation/PaymentConfirmation";
import { PaymentTestData } from "./sampleData";
import useCart from "../../hooks/use-cart";
import FormV2, { Note } from "../Purchase/components/FormV2";
import Step0 from "./Step0";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LoadingPage from "../../components/LoadingPage";
import { IOrderTracking } from "../orderTracking";
import CustomModal from "../../components/modal/CustomModal";
import AuthContext from "../../context/auth-context";
import { LocalStorageKey } from "./constant";
import useCountry from "../../hooks/use-country";
import { isValidZipCode } from "../../utils/utils";

const Linkterm = styled.a`
  text-decoration: none;
  color: #3765de;
  cursor: pointer;
  font-size: 1.2rem;
  &:hover {
    text-decoration: underline;
  }
`;
const ButtonBack = styled.div`
  font-family: "Archivo";
  display: flex;
  align-items: center;
  font-size: 2rem;
  gap: 1rem;
  cursor: pointer;
  /* font-family: "Rubik"; */
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.4rem;
  }
`;

const Layout = styled.div`
  padding: 8rem 0;
  @media (max-width: 1280px) {
    padding: 8rem 0;
  }
`;

const LogoText = styled.div`
  font-family: Archivo;
  margin-top: 6rem;
  font-size: 6rem;
  font-weight: 500;
  @media (max-width: 1280px) {
    margin-top: 3rem;
    font-size: 4.5rem;
  }
`;

const MainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${breakPoints.ipad}) {
    flex-direction: column;
  }
  /* gap: 5rem; */
`;

const LayoutStep = styled.div<{ step?: number }>`
  width: ${({ step }) => (step !== 0 ? "50%" : "80%")};
  /* padding-right: 10rem; */

  @media (max-width: ${breakPoints.desktop}) {
    width: ${({ step }) => (step !== 0 ? "45%" : "80%")};
  }
  @media (max-width: 820px) {
    flex: 1;
    width: 100%;
    padding-right: 0rem;
  }
`;

const DivStep = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 5rem;
  align-items: center;
  @media (max-width: 1280px) {
    margin-bottom: 3rem;
    gap: 1rem;
  }
  @media (max-width: ${breakPoints.mobile}) {
    > div > svg {
      width: 12px;
    }
  }
`;

const TextResend = styled.div`
  text-align: center;
  margin-top: 1rem;
  font-size: 1.2rem;
`;

const LayoutProduct = styled.div`
  width: 40%;
  @media (max-width: 820px) {
    display: none;
  }
`;

const TitleModal = styled.div`
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
`;

const SubTitle = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const Card = styled.div`
  max-width: 40rem;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 3rem;
`;
const CardTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M15.875 5.75L9.125 12.5L15.875 19.25"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Button = styled(ButtonGradient)<{ step?: number; disabled?: boolean }>`
  /* margin: 0 auto; */
  /* width: 55%; */
  width: ${({ step }) => (step === 0 ? "100%" : "35%")};
  margin-top: 4rem;
  margin-bottom: 10rem;
  padding: 1.1rem 3rem;
  font-size: 1.75rem;
  opacity: ${(props) => (props.disabled ? "0.6" : 1)};
  position: relative;
  @media (max-width: 1030px) {
    width: 100%;
    margin-top: 1rem;
  }
  @media (max-width: 820px) {
    font-size: 1.5rem;
    /* margin-top: 2rem; */
    margin-bottom: 4rem;
  }
`;
export const Back = (props: any) => {
  const { t } = useTranslation();
  return (
    <ButtonBack onClick={props.onClick}>
      <Icon />
      {t("Back")}
    </ButtonBack>
  );
};

const Img = styled.img`
  max-width: 7rem;
  max-height: 7rem;
`;
const Text = styled.div`
  font-size: 2rem;
  font-weight: 600;
`;
const Row = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const VerifyEmailContent = styled.div`
  background-color: #fff;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border-radius: 1.6rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
`;
const NoteContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${breakPoints.mobile}) {
    bottom: -3.2rem;
  }
`;

const OneStep = ({ title, active }: { title: string; active: boolean }) => {
  return (
    <ButtonBack style={{ color: active ? "black" : "#ccc" }}>
      {title}
    </ButtonBack>
  );
};

export interface InputFieldI {
  value: string | undefined;
  setValue: Dispatch<SetStateAction<string | undefined>>;
  isValid: any;
  hasError: boolean;
  error: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  valueChangeHandler: (value: any) => void;
  reset: () => void;
}
export enum PaymentMethod {
  Visa = "VISA",
  International = "International",
  Paypal = "PAYPAL",
  Stripe = "STRIPE",
  Pools = "POOLS",
}
export interface Country {
  id: number;
  name: string;
  iso2: string;
  numeric_code: string;
  phone_code: string;
  capital: string;
  currency_name: string;
  currency: string;
  emoji: string;
}
export interface City {
  id: number;
  name: string;
  country_id: number;
  country_code: string;
  country_name: string;
  state_code: string;
}
export interface District {
  id: number;
  name: string;
  state_id: number;
  state_code: string;
  country_id: number;
  country_code: string;
}
export interface ISelect {
  label?: string;
  value?: string | number;
  code?: string;
  id?: number;
  emoji?: string;
}
const Payment = () => {
  const { t } = useTranslation();
  const { Modal, open, isOpen } = useModal();
  const [verifyModal, setVerifyModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const firstStep = searchParams.get("firstStep");

  const [step, setStep] = useState<number>(0);
  const navigate = useNavigate();

  const { data, setData, resetData } = useContext(PaymentContext);
  const { cart } = useCart();
  const { user } = useContext(AuthContext);

  // const [listCountries, setListCountries] = useState<Country[]>([]);
  const { listCountries } = useCountry();
  const [listCities, setListCities] = useState<City[]>([]);

  // contract
  const [order_code, setOrder_code] = useState("");
  const [totalPools, setTotalPools] = useState(0);

  // info
  const fullNameInput = useInput((value: string) => true);
  const phoneNumberInput = useInput((value: string) => true);
  const emailInput = useInput((value: string) => true);
  const [phoneCode, setPhoneCode] = useState<ISelect>({
    value: "84",
    label: "84",
    code: "84",
    emoji: "🇻🇳",
  });

  // shipping
  const [country, setCountry] = useState<ISelect | null>(
    data?.shipping?.country
      ? {
          value: data.shipping.country_id,
          label: data.shipping.country,
          code: data.shipping.country_code,
        }
      : null
  );
  const [countryError, setCountryError] = useState(false);
  const [city, setCity] = useState<ISelect | null>(
    data?.shipping?.state
      ? {
          value: data.shipping.state_id,
          label: data.shipping.state,
          code: data.shipping.state_code,
        }
      : null
  );
  const [cityError, setCityError] = useState(false);

  const [district, setDistrict] = useState<ISelect | null>(
    data?.shipping?.city
      ? {
          value: data.shipping.city_id?.toString(),
          label: data.shipping.city?.toString(),
          code: data.shipping.city_id?.toString(),
        }
      : null
  );
  const [districtError, setDistrictError] = useState(false);

  const [phoneCodeReceiver, setPhoneCodeReceiver] = useState<ISelect>({
    value: "84",
    label: "84",
    code: "84",
    emoji: "🇻🇳",
  });
  const phoneNumberReceiverInput = useInput((value: string) => true);

  const [supplier, setSupplier] = useState<any>(null);
  const [company, setCompany] = useState("");
  const [apartment, setApartment] = useState("");
  const addressInput = useInput((value: string) => true);
  const postalCodeInput = useInput((value: string) => true);

  // payment
  const [checkAgree, setCheckAgree] = useState<boolean>(false);
  const [method, setMethod] = useState<PaymentMethod | null>(null);

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const initDataFromLocalStorage = useCallback(() => {
    const payment_step1 = localStorage.getItem(LocalStorageKey.payment_step1);
    const payment_step2 = localStorage.getItem(LocalStorageKey.payment_step2);
    if (payment_step1) {
      const step1 = JSON.parse(payment_step1);
      const phoneCode = listCountries.find(
        (c) => c.phone_code === step1.phone_code
      );
      if (phoneCode) {
        setPhoneCode({
          value: phoneCode.phone_code,
          label: phoneCode.phone_code,
          code: phoneCode.phone_code,
          emoji: phoneCode?.emoji,
        });
      }
      phoneNumberInput.setValue(step1.phone_number);
    }
    if (payment_step2) {
      const step2 = JSON.parse(payment_step2);
      setCountry({
        value: step2.country_id,
        label: step2.country,
        code: step2.country_code,
      });
      setCity({
        value: step2.state_id,
        label: step2.state,
        code: step2.state_code,
      });
      if (step2.city_id) {
        setDistrict({
          value: step2.city_id,
          label: step2.city,
          code: step2.city_code,
        });
      }
      const phoneCode = listCountries.find(
        (c) => c.phone_code === step2.receiver_phone_code
      );
      if (phoneCode) {
        setPhoneCodeReceiver({
          value: phoneCode.phone_code,
          label: phoneCode.phone_code,
          code: phoneCode.phone_code,
          emoji: phoneCode?.emoji,
        });
      }
      addressInput.setValue(step2.address);

      postalCodeInput.setValue(
        isValidZipCode.test(step2.postal_code) ? step2.postal_code : ""
      );
      phoneNumberReceiverInput.setValue(step2.receiver_phone_number);
      setApartment(step2.apartment);
      setCompany(step2.company_name);
    }
  }, [listCountries]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      shipping: {
        ...prev?.shipping,
        shipment_product: supplier,
      },
    }));
  }, [supplier, setData]);

  useEffect(() => {
    if (data?.info) {
      fullNameInput.setValue(data.info.full_name);
      const phoneCode = listCountries.find(
        (c) => c.phone_code === data?.info?.phone_code
      );
      if (phoneCode) {
        setPhoneCode({
          value: phoneCode.phone_code,
          label: phoneCode.phone_code,
          code: phoneCode.phone_code,
          emoji: phoneCode?.emoji,
        });
      }
      phoneNumberInput.setValue(data.info.phone_number);
      emailInput.setValue(data.info.email);
    } else {
      if (user) {
        fullNameInput.setValue(user.fullname);
        emailInput.setValue(user.email);
      }
    }
    if (data?.shipping?.receiver_phone_number) {
      phoneNumberReceiverInput.setValue(
        data.shipping?.receiver_phone_number || ""
      );
    }
  }, [data, user]);

  useEffect(() => {
    initDataFromLocalStorage();
  }, [initDataFromLocalStorage]);

  useEffect(() => {
    if (firstStep === "1") {
      setStep(0);
    }
  }, [firstStep]);

  const onPhoneCodeChangeHandler = (value: any) => {
    setPhoneCode(value);
    if (!data?.shipping?.receiver_phone_code) {
      setPhoneCodeReceiver(value);
    }
  };
  const onPhoneCodeReceiverChangeHandler = (value: any) => {
    setPhoneCodeReceiver(value);
  };
  const onCountryChangeHandler = (value: any) => {
    setCity(null);
    setDistrict(null);
    setCountry(value);
    setCountryError(false);
  };
  const onCityChangeHandler = (value: any) => {
    setCity(value);
    setCityError(false);
  };
  const onDistrictChangeHandler = (value: any) => {
    setDistrict(value);
    setDistrictError(false);
  };

  const verifyEmailHandler = async (value: any, index?: any) => {
    if (!emailInput.value || !emailInput.value.includes("@")) {
      toast.error(t("Your email is not valid!"));
      return;
    }
    try {
      const response = await Api.User.checkVerifyCode({
        email: emailInput.value,
        code: value,
      });

      if (response.data) {
        toast.success(t("You have successfully verified your email"));
        // isOpen(false);
        setVerifyModal(false);
        setStep(2);
        searchParams.set("step", "2");
        setSearchParams(searchParams);
      } else {
        toast.error(t("Verify code is not corrected."));
      }
    } catch (error) {
      toast.error(t("Can not verify email."));
    }
  };
  const handleStep1 = async () => {
    if (!fullNameInput.value) {
      fullNameInput.setError(true);
      toast.error(t("Full name is required."));
      return;
    }
    if (!phoneNumberInput.value) {
      phoneNumberInput.setError(true);
      toast.error(t("Phone number is required."));
      return;
    }
    if (!emailInput.value) {
      emailInput.setError(true);
      toast.error(t("Email is required."));
      return;
    }
    if (!emailInput.value.includes("@")) {
      emailInput.setError(true);
      toast.error(t("Your email is not valid."));
      return;
    }

    const info: InfoI = {
      full_name: fullNameInput.value,
      phone_code: phoneCode?.value?.toString() || "",
      phone_number: phoneNumberInput.value,
      email: emailInput.value,
    };
    localStorage.setItem(LocalStorageKey.payment_step1, JSON.stringify(info));
    setData((prev: any) => ({ ...prev, info }));

    const payment_step2 = localStorage.getItem(LocalStorageKey.payment_step2);
    if (!payment_step2 && !data?.shipping?.receiver_phone_number) {
      phoneNumberReceiverInput.setValue(phoneNumberInput.value);
    }

    // if (emailInput.value === "nvdai1506@playgroundvina.com") {
    //   setStep(2);
    //   return;
    // }
    if (emailInput.value === user?.email) {
      setStep(2);
      searchParams.set("step", "2");
      setSearchParams(searchParams);
      return;
    }
    try {
      const response = await Api.User.verifyEmail({
        email: emailInput.value,
        specificType: "BUYER",
      });
      if (response.data) {
        // isOpen(true);
        setVerifyModal(true);
      }
    } catch (error) {
      toast.error(t("Email is not valid."));
    }
  };
  const handleStep2 = () => {
    if (!country || !country.value || !country.label || !country.code) {
      setCountryError(true);
      toast.error(t("Country is required."));
      return;
    }
    if (!city || !city.value || !city.label || !city.code) {
      setCityError(true);
      toast.error(t("City is required."));
      return;
    }
    // if (!district || !district.value || !district.label || !district.code) {
    //   setDistrictError(true);
    //   toast.error(t("District is required."));
    //   return;
    // }
    if (!addressInput.value) {
      addressInput.setError(true);
      toast.error(t("Address is required."));
      return;
    }
    if (!phoneNumberReceiverInput.value) {
      phoneNumberReceiverInput.setError(true);
      toast.error(t("Phone number receiver is required."));
      return;
    }
    if (!postalCodeInput.value) {
      postalCodeInput.setError(true);
      toast.error(t("Post/Zip Code is required."));
      return;
    } else if (
      postalCodeInput.value.length < 4 ||
      postalCodeInput.value.length > 7
    ) {
      postalCodeInput.setError(true);
      toast.error(t("Post/Zip Code must be 4-7 characters."));
      return;
    }
    if (!supplier) {
      toast.error(t("Shipping method is required."));
      return;
    }
    const shipping: ShippingI = {
      country_id: +country.value,
      country: country.label,
      country_code: country.code,
      state_id: +city.value,
      state: city.label,
      state_code: city.code,
      city: district?.label,
      city_id: district?.value ? +district.value : undefined,
      address: addressInput.value,
      postal_code: postalCodeInput.value,
      company_name: company,
      apartment: apartment,
      receiver_phone_code: phoneCodeReceiver.value?.toString() || "",
      receiver_phone_number: phoneNumberReceiverInput.value,
      shipment_product_code: supplier.productCode,
      shipment_product: supplier,
    };
    localStorage.setItem(
      LocalStorageKey.payment_step2,
      JSON.stringify(shipping)
    );
    setData((prev: any) => ({ ...prev, shipping }));
    setStep(3);
    searchParams.set("step", "3");
    setSearchParams(searchParams);
  };
  const handleStep3 = async () => {
    const dataToTest = PaymentTestData;
    isOpen(false);
    setLoadingSubmit(true);
    if (!checkAgree) {
      toast.info(t("Please agree to the terms and conditions"));
      return;
    }
    if (
      !data ||
      !data.info ||
      !cart.items ||
      !data.shipping ||
      !data.shipping.receiver_phone_number ||
      !method
    ) {
      toast.error("Data to post not valid, please check again!");
      return;
    }
    const details: IDetail[] = cart.items.map((item) => {
      const gift = item.product.gifts[0];
      const availableGiftQuantity = gift.quantity - gift.quantitySold;
      const quantityGift =
        item.quantity > availableGiftQuantity
          ? availableGiftQuantity
          : item.quantity;
      return {
        product_code: item.product.code,
        quantity: item.quantity,
        quantityGift: quantityGift,
      };
    });

    const dataToPost: OrderI = {
      customer_name: data?.info?.full_name || "",
      customer_email: data?.info?.email || "",
      phone_code: data?.info?.phone_code || "",
      phone_number: data?.info?.phone_number || "",
      receiver_phone_number:
        data.shipping.receiver_phone_code + data.shipping.receiver_phone_number,
      company_name: data?.shipping?.company_name,
      apartment: data?.shipping?.apartment,
      address: data?.shipping?.address || "",
      country_code: data?.shipping?.country_code || "",
      state_code: data?.shipping?.state_code || "",
      city_id: data?.shipping?.city_id ? +data?.shipping?.city_id : null,
      payment_method: method,
      shipment_product_code: data?.shipping?.shipment_product_code || "",
      shipment_product: JSON.stringify(data?.shipping?.shipment_product),
      zip_code: data?.shipping?.postal_code || "",
      ref_code: data.referral,
      details: details,
    };
    // console.log("dataToPost:", dataToPost);
    // return;
    try {
      switch (method) {
        case PaymentMethod.Visa: {
          break;
        }
        case PaymentMethod.International: {
          const response = await Api.Payment.orderShipCOD(dataToPost);
          if (response) {
            toast.success(t("You have successfully placed an order"));
            resetData();
            navigate("/order-tracking");
          }
          break;
        }
        case PaymentMethod.Paypal: {
          const response: any = await Api.Payment.order(dataToPost);
          if (response.data) {
            window.location.href = response.data.href;
            // window.open(response.data);
          }
          break;
        }
        case PaymentMethod.Stripe: {
          const response: any = await Api.Payment.stripeOrder(dataToPost);
          if (response.data) {
            window.location.href = response.data;
            // window.open(response.data);
          }

          break;
        }
        case PaymentMethod.Pools: {
          try {
            const response: any = await Api.Payment.draftOrder(dataToPost);
            if (response.data) {
              setOrder_code((prev) => response.data.createdOrder.orders_code);
              setTotalPools(
                (prev) => response.data.createdOrder.final_amount * 10
              );
            }
          } catch (error: any) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoadingSubmit(false);
    }
  };
  const onClickBtnHandler = () => {
    switch (step) {
      case 0: {
        searchParams.delete("firstStep");
        searchParams.set("step", "1");
        setSearchParams(searchParams);
        setStep(1);
        break;
      }
      case 1: {
        handleStep1();
        break;
      }
      case 2: {
        handleStep2();
        break;
      }
      case 3: {
        // handleStep3()
        if (!method) {
          toast.error("Please select payment method");
          break;
        }
        isOpen(true);
        break;
      }
    }
  };

  const windowDimensions = useWindowDimensions();
  const isIpad = windowDimensions.width <= 820;

  let orderInfo: IOrderTracking = {
    payment: data ? data : undefined,
    cart,
    method: method || PaymentMethod.Paypal,
  };

  return (
    <Layout>
      {loadingSubmit && <LoadingPage />}
      <Back onClick={() => navigate("/purchase/product/black")} />
      <LogoText>Start24</LogoText>
      <MainLayout>
        <LayoutStep step={step}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <DivStep>
              <OneStep title={t("Cart")} active={step >= 0 ? true : false} />
              <div style={{ rotate: "-180deg" }}>
                <Icon />
              </div>
              <OneStep title={t("Billing")} active={step >= 1 ? true : false} />
              <div style={{ rotate: "-180deg" }}>
                <Icon />
              </div>
              <OneStep
                title={t("Shipping")}
                active={step >= 2 ? true : false}
              />
              <div style={{ rotate: "-180deg" }}>
                <Icon />
              </div>
              <OneStep
                title={t("Payment")}
                active={step === 3 ? true : false}
              />
            </DivStep>
            {step === 0 && <Step0 />}
            {step === 1 && (
              <Step1
                fullNameInput={fullNameInput}
                phoneNumberInput={phoneNumberInput}
                emailInput={emailInput}
                listCountries={listCountries}
                phoneCode={phoneCode}
                onPhoneCodeChangeHandler={onPhoneCodeChangeHandler}
              />
            )}
            {step === 2 && (
              <Step2
                onCountryChangeHandler={onCountryChangeHandler}
                onCityChangeHandler={onCityChangeHandler}
                onDistrictChangeHandler={onDistrictChangeHandler}
                setSupplier={setSupplier}
                setListCities={setListCities}
                setCompany={setCompany}
                setApartment={setApartment}
                onPhoneCodeReceiverChangeHandler={
                  onPhoneCodeReceiverChangeHandler
                }
                addressInput={addressInput}
                phoneCodeReceiver={phoneCodeReceiver}
                postalCodeInput={postalCodeInput}
                phoneNumberReceiverInput={phoneNumberReceiverInput}
                listCountries={listCountries}
                listCities={listCities}
                country={country}
                countryError={countryError}
                city={city}
                cityError={cityError}
                district={district}
                districtError={districtError}
                company={company}
                apartment={apartment}
                supplier={supplier}
              />
            )}
            {step === 3 && (
              <Step3
                checkAgree={checkAgree}
                setCheckAgree={setCheckAgree}
                setMethod={setMethod}
                method={method}
                order_code={order_code}
                totalPools={totalPools}
                setLoadingSubmit={setLoadingSubmit}
              />
            )}
            {/* Cart mini on ipad & mobile */}
            {step !== 0 && step !== 3 && isIpad && (
              <div style={{ margin: "2rem 0" }}>
                <FormV2 hideBtn={true} hideRefInput={true} />
              </div>
            )}
            {/*  */}
            {cart.items.length > 0 && (
              <ButtonContainer
                style={{
                  justifyContent: `${step === 0 ? "center" : "space-between"}`,
                }}
              >
                {step !== 0 && (
                  <Button
                    onClick={() => {
                      setStep((prev) => (prev > 0 ? prev - 1 : prev));
                      searchParams.set("step", `${step - 1}`);
                      setSearchParams(searchParams);
                    }}
                  >
                    {t("Back")}
                  </Button>
                )}
                <Button
                  onClick={onClickBtnHandler}
                  disabled={
                    step === 3 && (!checkAgree || !method)
                      ? true
                      : false || !cart.items.length || loadingSubmit
                  }
                  loading={loadingSubmit}
                  step={step}
                >
                  {step === 0 && t("Check Out")}
                  {step === 1
                    ? user?.email === emailInput.value
                      ? t("Next")
                      : t("VERIFY EMAIL")
                    : undefined}
                  {step === 2 && t("Next")}
                  {step === 3 &&
                    t(
                      `${
                        method === PaymentMethod.Visa ||
                        method === PaymentMethod.Paypal ||
                        method === PaymentMethod.Stripe
                          ? "Pay"
                          : "Pay"
                      }`
                    )}
                  {step === 3 && (
                    <NoteContainer>
                      <Note>
                        {t("100% refund if cancelled within 7 days.")}
                      </Note>
                    </NoteContainer>
                  )}
                </Button>
              </ButtonContainer>
            )}
          </div>
        </LayoutStep>
        {step !== 0 && !isIpad && <FormV2 hideBtn={true} hideRefInput={true} />}
      </MainLayout>
      {step === 1 && (
        <CustomModal isOpen={verifyModal} onClose={() => setVerifyModal(false)}>
          <VerifyEmailContent>
            <TitleModal>{t("Enter verify code")}</TitleModal>
            <SubTitle>
              {t("We have sent the verification code to your email address")}
            </SubTitle>
            <PinInput
              length={4}
              // initialValue=""
              onChange={(value, index) => {
                // console.log(value, index);
              }}
              type="numeric"
              inputMode="number"
              inputStyle={{
                background: "#ccc",
                borderRadius: "1rem",
                border: "0.897px solid #4D4D4D",
                fontSize: "2rem",
              }}
              inputFocusStyle={{}}
              onComplete={verifyEmailHandler}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {verifyModal && (
              <TextResend>
                {t("Didn’t recieve code")}? <Linkterm>{t("Resend")}</Linkterm> (
                <CountDown
                  time={30}
                  onEnd={() => {
                    isOpen(false);
                  }}
                />
                s)
              </TextResend>
            )}
          </VerifyEmailContent>
        </CustomModal>
      )}
      {open && step === 3 && (
        <Modal p={isIpad ? "4rem 2.4rem" : "4.8rem"}>
          <PaymentConfirmation
            handleStep3={handleStep3}
            setStep={setStep}
            isOpen={isOpen}
            orderInfo={orderInfo}
          />
        </Modal>
      )}
    </Layout>
  );
};

export default Payment;
