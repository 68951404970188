import React from "react";
import { breakPoints } from "../../../constant/breakpoints";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";

import FacebookIcon from "../icons/FacebookIcon";
import TwitterIcon from "../icons/TwitterIcon";
import InstagramIcon from "../icons/InstagramIcon";
import YoutubeIcon from "../icons/YoutubeIcon";
import TiktokIcon from "../icons/TiktokIcon";
import { styled } from "styled-components";
import TelegramIcon from "../icons/TelegramIcon";

const FooterBottom = () => {
  const { t } = useTranslation();
  return (
    <BottomContainer>
      <BottomWrapper>
        <ul style={{ paddingLeft: "2rem" }}>
          <li>
            {t("Marketing")}:{" "}
            <Link to="mailTo: contact@poolsclub.com">contact@poolsclub.com</Link>
          </li>
        </ul>
        <ul>
          <li>
            {t("Sales | Partnership | Business Proposal")}:{" "}
            <Link to="mailTo: contact@poolsclub.com">contact@poolsclub.com</Link>
          </li>
        </ul>
        <ul>
          <li>
            {t("Contact")} | CS:{" "}
            <Link to="mailTo: contact@poolsclub.com">contact@poolsclub.com</Link>
          </li>
        </ul>
        <div>
          © {moment().year()} {t("POOLS Phone All Rights Reserved.")}
        </div>
        <ul>
          <li className="disable">
            <a href="/" target="_blank">
              {t("Referral policy")}
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              href="/privacy-policy"
              target="_blank"
            >
              {t("Privacy policy")}
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              href="https://daziu008xh8wa.cloudfront.net/materials-lib/others/1707296389940.pdf"
              target="_blank"
            >
              {t("Cookie policy")}
            </a>
          </li>
          <li>
            <a
              rel="noreferrer"
              href="/term-of-use-policy"
              target="_blank"
            >
              {t("Terms of use")}
            </a>
          </li>
        </ul>
        <div>
          <IconsContainer>
            <SocialContainers>
              <SocialItem
                href="https://www.facebook.com/bpoolsclub"
                target="_blank"
              >
                <FacebookIcon />
              </SocialItem>
              <Break />
              <SocialItem href="https://twitter.com/pools_club" target="_blank">
                <TwitterIcon />
              </SocialItem>
              <Break />
              <SocialItem
                href="https://www.instagram.com/poolclubs123"
                target="_blank"
              >
                <InstagramIcon />
              </SocialItem>
              <Break />
              <SocialItem
                href="https://www.youtube.com/@poolscontact"
                target="_blank"
              >
                <YoutubeIcon />
              </SocialItem>
              <Break />
              <SocialItem
                href="https://www.tiktok.com/@poolsclubvn"
                target="_blank"
              >
                <TiktokIcon />
              </SocialItem>
              <Break />
              <SocialItem href="https://t.me/Poolsclub" target="_blank">
                <TelegramIcon />
              </SocialItem>
            </SocialContainers>
          </IconsContainer>
        </div>
      </BottomWrapper>
    </BottomContainer>
  );
};

export default FooterBottom;

export const IconsContainer = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;
  @media screen and (max-width: ${breakPoints.ipad}) {
    justify-content: center;
    row-gap: 2rem;
  }
`;
export const SocialContainers = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  @media screen and (max-width: ${breakPoints.ipad}) {
    gap: 1.5rem;
  }
`;
export const SocialItem = styled.a`
  &:hover {
    filter: brightness(0.8);
  }
  > svg {
    width: 2.2rem;
  }
`;
export const Break = styled.div`
  width: 1px;
  height: 70%;
  background-color: #727272;
`;
const BottomContainer = styled.div`
  width: 100%;
  background-color: #2a2c2d;
`;
const BottomWrapper = styled.div`
  align-self: flex-start;
  width: 90%;
  padding: 1rem 0 1rem 4rem;
  display: grid;
  grid-template-columns: 0.75fr 1fr 0.75fr;
  justify-content: flex-start;
  /* column-gap: 4rem; */
  .disable {
    pointer-events: none;
  }

  > ul {
    display: flex;
    gap: 4rem;
  }
  > ul > li {
    &::marker {
      color: #fff;
      font-size: 2rem;
    }
    //styleName: new1.1/Body/4;
    font-family: "Lexend";
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    > a {
      color: #fff;
      &:hover {
        filter: brightness(0.8);
      }
    }
  }
  > div {
    font-family: "Lexend";
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
  }

  @media screen and (max-width: ${breakPoints.desktop}) {
    width: 100%;
    grid-template-columns: 0.6fr 1fr 0.75fr;
  }
  @media screen and (max-width: ${breakPoints.mDesktop}) {
    > ul {
      gap: 3rem;
    }
  }
`;
