import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import {
  Break,
  IconsContainer,
  SocialContainers,
  SocialItem,
} from "./FooterBottom";

import FacebookIcon from "../icons/FacebookIcon";
import TwitterIcon from "../icons/TwitterIcon";
import InstagramIcon from "../icons/InstagramIcon";
import YoutubeIcon from "../icons/YoutubeIcon";
import TiktokIcon from "../icons/TiktokIcon";
import moment from "moment";
import TelegramIcon from "../icons/TelegramIcon";

const FooterBottomMobile = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <ul>
        <li>
          {t("Marketing")}:{" "}
          <Link to="mailTo: contact@poolsclub.com">contact@poolsclub.com</Link>
        </li>
      </ul>

      <ul>
        <li>
          {t("Contact")} | CS:{" "}
          <Link to="mailTo: contact@poolsclub.com">contact@poolsclub.com</Link>
        </li>
      </ul>
      <ul>
        <li>
          {t("Sales | Partnership | Business Proposal")}:{" "}
          <Link to="mailTo: contact@poolsclub.com">contact@poolsclub.com</Link>
        </li>
      </ul>
      <ul className="ul-li-marker">
        <li className="disable">
          <a href="/" target="_blank">
            {t("Referral policy")}
          </a>
        </li>
        <li>
          <a
            rel="noreferrer"
            href="/privacy-policy"
            target="_blank"
          >
            {t("Privacy policy")}
          </a>
        </li>
        <li>
          <a
            rel="noreferrer"
            href="https://daziu008xh8wa.cloudfront.net/materials-lib/others/1707296389940.pdf"
            target="_blank"
          >
            {t("Cookie policy")}
          </a>
        </li>
        <li>
          <a
            rel="noreferrer"
            href="/term-of-use-policy"
            target="_blank"
          >
            {t("Terms of use")}
          </a>
        </li>
      </ul>
      <div>
        <IconsContainer>
          <SocialContainers>
            <SocialItem
              href="https://www.facebook.com/bpoolsclub"
              target="_blank"
            >
              <FacebookIcon />
            </SocialItem>
            <Break />
            <SocialItem href="https://twitter.com/pools_club" target="_blank">
              <TwitterIcon />
            </SocialItem>
            <Break />
            <SocialItem
              href="https://www.instagram.com/poolclubs123"
              target="_blank"
            >
              <InstagramIcon />
            </SocialItem>
            <Break />
            <SocialItem
              href="https://www.youtube.com/@poolscontact"
              target="_blank"
            >
              <YoutubeIcon />
            </SocialItem>
            <Break />
            <SocialItem
              href="https://www.tiktok.com/@poolsclubvn"
              target="_blank"
            >
              <TiktokIcon />
            </SocialItem>
            <Break />

            <SocialItem href="https://t.me/Poolsclub" target="_blank">
              <TelegramIcon />
            </SocialItem>
          </SocialContainers>
        </IconsContainer>
      </div>
      <div className="copyright">
        © {moment().year()} {t("POOLS Phone All Rights Reserved.")}
      </div>
    </Container>
  );
};

export default FooterBottomMobile;
const Container = styled.div`
  background: #121212;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-bottom: 4rem;
  .disable {
    pointer-events: none;
  }
  > ul {
    display: flex;
    gap: 4rem;
  }
  > ul > li {
    list-style: none;
    //styleName: new1.1/Body/5;
    font-family: "Lexend";
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.1rem;
    letter-spacing: 0em;
    text-align: center;

    color: #fff;
    > a {
      color: #fff;
      &:hover {
        filter: brightness(0.8);
      }
    }
  }
  .ul-li-marker {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-start;
    justify-items: flex-start;
    gap: 1rem;
    > li {
      list-style: disc;
    }
  }
  .copyright {
    //styleName: new1.1/Body/5;
    font-family: "Lexend";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: #fff;
  }
`;
