import React from "react";
import { IMenu } from "../../constant/menu";
import MenuItem from "./MenuItem";
import styled from "styled-components";
import { breakPoints } from "../../constant/breakpoints";

const Menu = ({ items }: { items: IMenu[] }) => {
  return (
    <MenuItemsContainer>
      {items.map((item) => (
        <MenuItem key={item.id} item={item} />
      ))}
    </MenuItemsContainer>
  );
};

export default Menu;

const MenuItemsContainer = styled.ul`
  display: none;
  column-gap: 2rem;

  @media (min-width: ${breakPoints.ipad}) {
    display: none;
  }

  @media (min-width: ${breakPoints.laptop}) {
    column-gap: 3.2rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
    column-gap: 4rem;
  }
`;
