import React from "react";
import "./i18n";
import ReactDOM from "react-dom/client";
import "./index.css";
import "swiper/css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/auth-context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CartContextProvider } from "./context/cart-context";
import { ThemeContextProvider } from "./context/theme-context";
import { config } from "./config/wagmi";
import { WagmiConfig } from "wagmi";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeContextProvider>
    <CartContextProvider>
      <AuthContextProvider>
        <GoogleOAuthProvider clientId="225445204563-rudorq0hqf0rljbpror4ttpbimvopr1a.apps.googleusercontent.com">
          <BrowserRouter>
            <WagmiConfig config={config}>
              <App />
            </WagmiConfig>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </AuthContextProvider>
    </CartContextProvider>
  </ThemeContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
