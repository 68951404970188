import React from "react";

const YoutubeIcon = ({
  width = 32,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M30.0626 8.55919C29.9042 7.9264 29.5816 7.34662 29.1274 6.87839C28.6733 6.41017 28.1036 6.0701 27.4759 5.89252C25.1826 5.33252 16.0092 5.33252 16.0092 5.33252C16.0092 5.33252 6.83589 5.33252 4.54256 5.94585C3.91489 6.12343 3.3452 6.46351 2.89102 6.93173C2.43685 7.39995 2.11428 7.97973 1.95589 8.61252C1.53618 10.9399 1.33087 13.3009 1.34256 15.6659C1.3276 18.0486 1.53292 20.4276 1.95589 22.7725C2.1305 23.3856 2.4603 23.9434 2.91342 24.3918C3.36653 24.8403 3.92765 25.1643 4.54256 25.3325C6.83589 25.9459 16.0092 25.9459 16.0092 25.9459C16.0092 25.9459 25.1826 25.9459 27.4759 25.3325C28.1036 25.1549 28.6733 24.8149 29.1274 24.3466C29.5816 23.8784 29.9042 23.2986 30.0626 22.6659C30.479 20.356 30.6843 18.013 30.6759 15.6659C30.6909 13.2831 30.4855 10.9041 30.0626 8.55919Z"
        stroke="#fff"
        stroke-width="2.08811"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 20.0271L20.6667 15.6671L13 11.3071V20.0271Z"
        fill="#fff"
        stroke="#fff"
        stroke-width="1.22605"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default YoutubeIcon;
