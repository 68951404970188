import * as React from "react";
import styled from "styled-components";
import { MainLayoutContainer } from "../../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import PhoneBlack from "../assets/images/back-black.png";
import PhoneWhite from "../assets/images/back-white.png";
import { Swiper, SwiperSlide } from "swiper/react";
import Slide1 from "../assets/images/slide-1.png";
import Slide2 from "../assets/images/slide-2.png";
import Slide3 from "../assets/images/slide-3.png";
import { Autoplay, Pagination } from "swiper/modules";
import { breakPoints } from "../../../constant/breakpoints";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import ScrollAnimation from "react-animate-on-scroll";

const slides = [Slide1, Slide2, Slide3, Slide1, Slide2, Slide3];

function OwnYourLook() {
  const { t } = useTranslation();
  const { isIpad } = useWindowDimensions();
  const [showUrl, setShowUrl] = React.useState<string>("");

  return (
    <Container>
      <Heading>
        <h5>{t("Available Colors")}</h5>
        <h1>{t("Own Your Look")}</h1>
      </Heading>
      {isIpad ? (
        <ContentMobile>
          <SwiperContainer
            modules={[Pagination]}
            pagination={{
              el: "#mobile-custom-pagination",
              clickable: true,
              renderBullet: (_, className) => {
                return `<span class="${className}"></span>`;
              },
            }}
            breakpoints={{
              0: {
                slidesPerView: 1.5,
                spaceBetween: 24,
                slidesOffsetAfter: 60,
              },
              640: {
                slidesPerView: 2.1,
              },
            }}
          >
            <SwiperSlide>
              <Banner>
                <img src={PhoneBlack} alt="" />
                <BoxRight>
                  <Text>{t("OBSIDIAN\nNOIR")}</Text>
                </BoxRight>
              </Banner>
            </SwiperSlide>
            <SwiperSlide>
              <Banner>
                <img src={PhoneWhite} alt="" />
                <BoxRight>
                  <Text>{t("ALABASTER\nBLANC")}</Text>
                </BoxRight>
              </Banner>
            </SwiperSlide>
          </SwiperContainer>
          <SwiperPagination id="mobile-custom-pagination"></SwiperPagination>
          <SloganMobile>
            <h5>{t("Own Your Timeless Styles")}</h5>
            <h5>{t("Be Bold, Be Elegant")}</h5>
          </SloganMobile>
        </ContentMobile>
      ) : (
        <Content>
          <Banner>
            <ScrollAnimation animateIn="fadeInLeft">
              <img src={PhoneBlack} alt="" />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInRight">
              <BoxRight>
                <Text>{t("OBSIDIAN\nNOIR")}</Text>
              </BoxRight>
            </ScrollAnimation>
          </Banner>
          <ScrollAnimation animateIn="fadeIn">
            <Slogan>
              <h5>{t("Own Your Timeless Styles")}</h5>
              <h5>{t("Be Bold, Be Elegant")}</h5>
            </Slogan>
          </ScrollAnimation>
          <Banner>
            <ScrollAnimation animateIn="fadeInRight">
              <img src={PhoneWhite} alt="" />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInRight">
              <BoxLeft>
                <Text>{t("ALABASTER\nBLANC")}</Text>
              </BoxLeft>
            </ScrollAnimation>
          </Banner>
        </Content>
      )}
      <SwiperContainer
        breakpoints={{
          0: {
            slidesPerView: 1.5,
            spaceBetween: 16,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
          },
          768: {
            slidesPerView: 2.2,
          },
          1024: {
            spaceBetween: 24,
            slidesPerView: 2.5,
            slidesOffsetBefore: 50,
            slidesOffsetAfter: 50,
          },
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        pagination={{
          el: "#custom-pagination",
          clickable: true,
          renderBullet: (_, className) => {
            return `<span class="${className}"></span>`;
          },
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <SwiperImage src={slide} alt="" onClick={() => setShowUrl(slide)} />
          </SwiperSlide>
        ))}
      </SwiperContainer>
      <SwiperPagination id="custom-pagination"></SwiperPagination>
      {showUrl && <WatchImageContainer onClick={() => setShowUrl("")}>
        <img src={showUrl} onClick={e => e.stopPropagation()} />
        <button>x</button>
      </WatchImageContainer>}
    </Container>
  );
}

export default OwnYourLook;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  @media (min-width: ${breakPoints.ipad}) {
  }
  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 3.2rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
  }
`;

const Heading = styled(MainLayoutContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 0.4rem;
  > h1 {
    color: #262626;
    font-family: Unbounded;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.16; /* 120.833% */
  }
  > h5 {
    color: #262626;
    font-family: Unbounded;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.33; /* 133.333% */
  }

  @media (min-width: ${breakPoints.ipad}) {
    > h1 {
      font-size: 32px;
    }
    > h5 {
      font-size: 16px;
    }
  }
  @media (min-width: ${breakPoints.laptop}) {
    > h1 {
      font-size: 64px;
    }
    > h5 {
      font-size: 20px;
    }
  }
  @media (min-width: ${breakPoints.desktop}) {
    > h1 {
      font-size: 96px;
    }
    > h5 {
      font-size: 24px;
    }
  }
`;

const Content = styled(MainLayoutContainer)`
  display: flex;
  align-items: center;
  column-gap: 3rem;
`;

const Banner = styled.div`
  max-width: 144px !important;
  margin: 0 auto;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  max-width: 36.8rem;

  img {
    width: 100%;
    height: auto;
    transition: all ease 300ms;
    &:hover {
      transform: scale(1.05);
    }
  }
  @media (min-width: ${breakPoints.ipad}) {
    max-width: 200px !important;
  }
  @media (min-width: ${breakPoints.laptop}) {
    padding: 2.3rem 2rem;
    max-width: 366px !important;
  }
`;

const Slogan = styled.div`
  min-width: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 2rem;
  transition: all ease 300ms;
  > h5:first-child {
    color: #4292ff;
    font-family: Unbounded;

    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }

  > h5:nth-child(2) {
    color: #262626;
    font-family: Unbounded;

    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
  }
  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: ${breakPoints.ipad}) {
    > h5:first-child {
      font-size: 32px;
    }

    > h5:nth-child(2) {
      font-size: 24px;
    }
  }

  @media (min-width: ${breakPoints.laptop}) {
    > h5:first-child {
      font-size: 28px;
    }

    > h5:nth-child(2) {
      font-size: 32px;
    }
  }
  @media (min-width: ${breakPoints.lgDesktop}) {
    > h5:first-child {
      font-size: 56.151px;
    }

    > h5:nth-child(2) {
      font-size: 75.95px;
    }
  }
`;

const BoxRight = styled.div`
  position: absolute;
  right: 0;
  bottom: 20%;
  transform: translateX(50%);
  padding: 0.8rem 1.2rem;
  border-radius: 12px;
  background: var(--new-11-card-bg, rgba(251, 251, 255, 0.58));
  box-shadow: 0px -5.446px 6.959px 0px #bdcaec inset,
    0px 2.42px 22.873px 0px #fff inset,
    15.127px 20.573px 35.882px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8.691429138183594px);

  transition: all ease 300ms;
  &:hover {
    transform: translateX(50%) scale(1.1);
  }

  @media (min-width: ${breakPoints.laptop}) {
    border-radius: 22.286px;
    padding: 1.6rem 2rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
    padding: 25.071px 37.886px;
  }
`;

const BoxLeft = styled.div`
  position: absolute;
  left: 0;
  bottom: 20%;
  transform: translateX(-50%);
  padding: 0.8rem 1.2rem;
  border-radius: 12px;
  background: var(--new-11-card-bg, rgba(251, 251, 255, 0.58));
  box-shadow: 0px -5.446px 6.959px 0px #bdcaec inset,
    0px 2.42px 22.873px 0px #fff inset,
    15.127px 20.573px 35.882px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8.691429138183594px);
  transition: all ease 300ms;
  &:hover {
    transform: translateX(-50%) scale(1.1);
  }

  @media (min-width: ${breakPoints.laptop}) {
    border-radius: 22.286px;
    padding: 1.6rem 2rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
    padding: 25.071px 37.886px;
  }
`;

const Text = styled.p`
  color: #0047ff;
  text-align: center;
  font-family: Unbounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: pre-line;
  @media (min-width: ${breakPoints.ipad}) {
    font-size: 2rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 24px;
  }
`;

const SwiperContainer = styled(Swiper)`
  margin-top: 1.8rem;
  width: 100%;
  overflow-y: visible;
`;

const SwiperImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 1.2rem;
  box-shadow: 0px 12px 48px 0px rgba(0, 71, 255, 0.22);
  transition: all ease 300ms;
  transition: all ease 300ms;
  &:hover {
    transform: scale(1.05);
  }
  @media (min-width: ${breakPoints.laptop}) {
    border-radius: 3.2rem;
  }
`;

const SwiperPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;

  .swiper-pagination-bullet {
    width: 16px;
    height: 8px;
    border-radius: 5rem;
    background-color: #bfbfbf;
    opacity: 0.5;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 53.3px;
    opacity: 1;
    background-color: #0047ff;
  }

  @media (min-width: ${breakPoints.laptop}) {
    .swiper-pagination-bullet {
      width: 24px;
      height: 12px;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 80px;
    }
  }
`;

const ContentMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2.4rem;
  text-align: center;
`;

const SloganMobile = styled(MainLayoutContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.2rem;
  > h5:first-child {
    color: #4292ff;
    text-align: center;
    font-family: Unbounded;
    font-size: 21.074px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  > h5:nth-child(2) {
    color: #262626;
    font-family: Unbounded;
    font-size: 25.604px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
  }
`;

const WatchImageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000089;
  z-index: 10000;
  img {
    width: 80%;
    height: auto;
  }
  button {
    position: absolute;
    top: 30px;
    right: 40px;
    font-size: 30px;
    padding: 0 12px 3px;
    border-radius: 100rem;
    color: #1f1f1f;
    border: none;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
`;
