import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { ButtonGradient } from "../../../components/ButtonGradient";
import ClearIcon from "../icons/ClearIcon";
import PaymentContext from "../../payment/context/payment-context";
import { useNavigate } from "react-router";
import Api from "../../../service";
import { useLocation, useSearchParams } from "react-router-dom";
import useCart from "../../../hooks/use-cart";

import CartIcon from "../icons/cart.svg";
import CartItems from "../../../components/cart/CartItems";
import DHLIcon from "../icons/dhl.svg";
import { breakPoints } from "../../../constant/breakpoints";
import { getLocalItem, removeLocalItem } from "../../../utils/localStorage";
import dayjs from "dayjs";
import { PaymentMethod } from "../../payment";
import PoolIcon from "../../payment/icons/pools.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";

let firstTime = true;

const Container = styled.div`
  min-width: 46rem;
  font-family: "Archivo";
  padding: 3.2rem;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
  background-color: white;
  color: #222;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  height: fit-content;
  > h5 {
    font-family: "Archivo";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
  @media (max-width: ${breakPoints.mobile}) {
    max-width: 100%;
    min-width: 0;
    row-gap: 1.6rem;
  }
`;

const SVG = styled.span<{ method?: PaymentMethod }>`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: var(--gray-scale-black-80, #2d2d2d);

  text-align: right;
  font-family: "Archivo";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 3.12rem */
  img {
    max-width: 2.8rem;
    max-height: 2.8rem;
  }
`;

const Label = styled.p`
  color: var(--gray-scale-black-60, #525252);
  font-family: "Archivo";
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%; /* 2.6rem */
`;
const Price = styled.p`
  color: var(--gray-scale-black-80, #2d2d2d);
  text-align: right;
  font-family: "Archivo";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 3.12rem */
`;

const FieldItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2.4rem;
  > span {
    color: var(--gray-scale-black-40, #8f8f8f);
    text-align: right;
    font-family: "Archivo";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
  label {
    color: var(--gray-scale-black-60, #525252);
    font-family: Archivo;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 2.6rem */
  }
  @media (max-width: ${breakPoints.mobile}) {
    p:first-child {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 170%; /* 2.72rem */
      letter-spacing: 0.016rem;
    }
    > p:last-child {
      font-size: 2rem;
      font-weight: 500;
      line-height: 130%; /* 2.6rem */
    }
  }
`;

const ShippingFee = styled.div`
  display: flex;
  gap: 0.5rem;

  > p {
    color: var(--gray-scale-black-80, #2d2d2d);
    text-align: right;
    font-family: "Archivo";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    align-items: center;
    svg {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
`;
const FieldDiscount = styled.div<{ method?: PaymentMethod }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2.4rem;
  > div {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
    background: #f2f2f2;
    color: #616161;
    padding: 1rem;
    font-size: 1.6rem;
    font-style: normal;
    line-height: 130%;
    > p:first-child {
      font-weight: 700;
    }
  }
  > p {
    color: ${({ method }) =>
      method === PaymentMethod.Pools ? "#2d2d2d" : "#d0011b"};
    font-family: Archivo;
    text-align: right;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    align-items: center;
    svg {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
`;

const BreakLine = styled.div`
  background: #b1b1b1;
  display: block;
  width: 100%;
  height: 0.2rem;
`;

const Referral = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
  > p {
    font-family: "Archivo";
    color: #515151;
    font-size: 2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
  }
  > div {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 1.2rem 0.8rem;
    border-radius: 0.8rem;
    background: #f2f2f2;
    > input {
      font-family: "Archivo";
      flex: 1;
      border: none;
      background-color: transparent;
      color: #232323;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      &:focus {
        outline: none;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: var(--purple, #b43bf0);
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
    > span {
      color: #d0011b;
      font-family: Archivo;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 300;
      line-height: 130%; /* 2.08rem */
    }
  }
  > span {
    font-family: "Archivo";
    color: #1268e9;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.012rem;
  }
`;

const ButtonSubmit = styled.div`
  flex: 1;
  display: block;
  padding: 1.4rem 1.6rem;
  color: #f7f7f7;
  text-align: center;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const CartHead = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  span {
    color: var(--gray-scale-black-80, #2d2d2d);
    font-family: "Archivo";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Note = styled.p`
  text-align: right;
  font-family: "Archivo";
  font-weight: 600;
  font-style: italic;
  font-size: 1.2rem;
  color: #000;
`;

function FormV2({
  hideBtn,
  hideRefInput,
}: {
  hideBtn?: boolean;
  hideRefInput?: boolean;
}) {
  const { t } = useTranslation();
  const { cart, paymentMethod, getNumberOfItems, updateRefCode } = useCart();
  const { data, setData, setDiscount } = useContext(PaymentContext);
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const referralInputRef = useRef(null);
  const [referral, setReferral] = useState<string>(
    data?.referral || cart.refCode || ""
  );
  const [invalidReferral, setInvalidReferral] = useState(false);

  const navigate = useNavigate();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const ref = queryParams.get("ref");

  const handleSubmit = () => {
    if (cart.items.length) {
      navigate("/purchase/payment");
    }
  };

  const onCheckReferralCode = async () => {
    if (referral) {
      try {
        const response = await Api.User.checkRefCode({ ref_code: referral });
        if (response && response.data) {
          const data = response.data;
          setInvalidReferral(!data.is_valid);
          setDiscount(data.discount_amount, referral);
          if (data.is_valid) {
            updateRefCode(referral);
          }
        }
      } catch (error) {
        setDiscount(0, "");
        setInvalidReferral(true);
      }
    } else {
      setInvalidReferral(false);
      setDiscount(0, "");
      updateRefCode("");
    }
  };

  const reqCheckReferralCode = async (ref: string) => {
    try {
      const response = await Api.User.checkRefCode({ ref_code: ref });
      if (response.data) {
        const data = response.data;
        setInvalidReferral(!data.is_valid);
        setDiscount(data.discount_amount, ref);
      }
    } catch (error) {
      setDiscount(0, "");
      setInvalidReferral(true);
    }
  };

  useEffect(() => {
    if (!firstTime) {
      var timeoutId = setTimeout(() => {
        // Handle the debounced input value here (e.g., make an API call)
        onCheckReferralCode();
      }, 400); // Adjust the delay (in milliseconds) according to your requirements
    } else {
      firstTime = false;
    }

    return () => {
      clearTimeout(timeoutId); // Clear the timeout on component unmount or input change
    };
  }, [referral]);

  useEffect(() => {
    if (ref) {
      setReferral(ref);
      reqCheckReferralCode(ref);
    }
  }, [ref]);

  const totalDiscount =
    paymentMethod === PaymentMethod.Pools
      ? (data?.discount || 0) * getNumberOfItems() * 10
      : (data?.discount || 0) * getNumberOfItems();

  const shippingFee =
    paymentMethod === PaymentMethod.Pools
      ? (data?.shipping?.shipment_product?.totalPrice[0]?.price || 0) * 10
      : data?.shipping?.shipment_product?.totalPrice[0]?.price;

  const subTotal = cart.totalPrice;
  const finalPrice = (subTotal - totalDiscount + (shippingFee || 0)).toFixed(2);

  return (
    <Container>
      <CartHead>
        <img src={CartIcon} alt="cart" />
        <span>
          {getNumberOfItems()}&nbsp;{t("items")}
        </span>
      </CartHead>
      <CartItems />

      {!hideRefInput && (
        <Referral>
          {/* <p>Referral Code</p> */}

          <div>
            <input
              ref={referralInputRef}
              value={referral}
              onChange={(e) => {
                setReferral(e.target.value);
              }}
              type="text"
              placeholder="Enter Referral code"
              //onBlur={onCheckReferralCode}
              onFocus={() => {
                setInvalidReferral(false);
              }}
            />
            {referral && (
              <div
                onClick={() => {
                  removeLocalItem("refCode");
                  removeLocalItem("refCodeExpiredTime");
                  setReferral("");
                  //@ts-ignore
                  referralInputRef?.current?.focus();
                  // setDiscount(0);
                  // setData((prev) => ({
                  //   referral,
                  //   discount: 0,
                  // }));
                }}
              >
                <ClearIcon />
              </div>
            )}
          </div>
          {invalidReferral && (
            <div>
              <span>{t("Invalid code, please try again")}</span>
            </div>
          )}
          <span>{t("Add referral code to get discount")}</span>
        </Referral>
      )}

      <BreakLine />

      <FieldItem>
        <Label>{t("Subtotal")}</Label>
        <Price>
          <SVG>
            {paymentMethod === PaymentMethod.Pools ? (
              <>
                <img src={PoolIcon} alt="" />
                {subTotal}
              </>
            ) : (
              `$${subTotal}`
            )}
          </SVG>
        </Price>
      </FieldItem>
      {totalDiscount !== 0 ? (
        <FieldDiscount method={paymentMethod}>
          <div>
            <p>Ref: {data?.referral}</p>
          </div>
          <p>
            <SVG
              style={{
                color: `${
                  paymentMethod === PaymentMethod.Pools ? "#2d2d2d" : "#d0011b"
                }`,
              }}
            >
              -
              {paymentMethod === PaymentMethod.Pools ? (
                <>
                  <img src={PoolIcon} alt="" />
                  {totalDiscount}
                </>
              ) : (
                `$${totalDiscount}`
              )}
            </SVG>
          </p>
        </FieldDiscount>
      ) : undefined}

      {data?.shipping?.shipment_product && (step === "2" || step === "3") && (
        <>
          <FieldItem>
            <Label>{t("Shipping")}</Label>
            <span>{data?.shipping?.shipment_product?.productName}</span>
          </FieldItem>
          <FieldItem>
            <Label>{t("Shipping Fee")}</Label>
            <ShippingFee>
              {/* {paymentMethod !== PaymentMethod.Pools && (
              <img src={DHLIcon} alt="DHL" />
            )} */}
              <p>
                <SVG>
                  {paymentMethod === PaymentMethod.Pools ? (
                    <>
                      <img src={PoolIcon} alt="" />
                      {shippingFee?.toFixed(2)}
                    </>
                  ) : (
                    `$${shippingFee?.toFixed(2)}`
                  )}
                </SVG>
              </p>
            </ShippingFee>
          </FieldItem>
          <FieldItem>
            <Label>{t("Receive In")}</Label>
            <span>
              {/* {moment().format("DD MMM")} -{" "} */}
              {moment().add(2, "months").format("MMM YYYY")}
            </span>
          </FieldItem>
        </>
      )}

      <FieldItem>
        <label>{t("Total")}</label>
        <Price>
          <SVG>
            {/* {method === PaymentMethod.crypto ? <PoolIcon /> : "$"} */}

            <SVG>
              {paymentMethod === PaymentMethod.Pools ? (
                <>
                  <img src={PoolIcon} alt="" />
                  {finalPrice}
                </>
              ) : (
                `$${finalPrice}`
              )}
            </SVG>
          </SVG>
        </Price>
      </FieldItem>
      {shippingFee && (step === "2" || step === "3") && (
        <Note>{t("Includes products and shipping fee only")}</Note>
      )}
      {!step && <Note>{t("100% refund if cancelled within 7 days.")}</Note>}

      {/* coming soon */}

      {!hideBtn && (
        <ButtonGradient
          disabled={
            data?.discount === 0 && referral !== ""
              ? true
              : false || !cart.items.length
          }
        >
          <ButtonSubmit onClick={handleSubmit}>{t("Pre-Order")}</ButtonSubmit>
        </ButtonGradient>
      )}
      {/* {!hideBtn && (
        <ButtonGradient disabled>
          <ButtonSubmit>Coming Soon</ButtonSubmit>
        </ButtonGradient>
      )} */}
    </Container>
  );
}

export default FormV2;
