export interface IMenu {
  id: number;
  label: string;
  url: string;
  children?: IMenu[];
}

export const menuItems: IMenu[] = [
  {
    id: 1,
    label: "Trailers",
    url: "/",
  },
  {
    id: 2,
    label: "WHY POOLS Phone",
    url: "/why-pools-phone",
  },
  {
    id: 3,
    label: "Features",
    url: "/features",
    // children: [

    // ],
  },
  {
    id: 4,
    label: "Support",
    url: "/support",
  },

];
