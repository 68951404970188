import React from "react";
import { styled } from "styled-components";

// import Background from "../../assets/images/hero-background-2.png";
import Background from "../../assets/images/hero-background-new.jpg";
import BackgroundMobile from "../../assets/images/hero-background-mobile.png";
import BackgroundPhone from "../../assets/images/background-phone.png";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { breakPoints } from "../../../../constant/breakpoints";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";
import { useTranslation } from "react-i18next";
import HeroFeature from "./HeroFeature";
import { ButtonCustom } from "../../../../components/Button/ButtonCustom";
import ScrollAnimation from "react-animate-on-scroll";

const NewHero = ({
  ownTheFutureRef,
}: {
  ownTheFutureRef: React.RefObject<HTMLDivElement>;
}) => {
  const { isIpad } = useWindowDimensions();
  const { t } = useTranslation();
  return (
    <Container>
      <img src={isIpad ? Background : Background} alt="background" />
      <PhoneContainer>
        <h2>
          {t("The first")} <br /> {t("Phone-to-earn")}
        </h2>
        <PhoneContent>
          <div className="scroll-down">
            <ScrollDownButton>
              <ArrowDownIcon />
              <span>{t("Scroll Down")}</span>
            </ScrollDownButton>
          </div>
          <ScrollAnimation animateIn="powerful" duration={0.3}>
            <HeroFeature title="Powerful AI" sub="Web3 D-App Features" />
          </ScrollAnimation>

          <ScrollAnimation animateIn="smart" duration={0.3}>
            <HeroFeature
              title="Smart & Secure"
              sub="Confident with fintech trading"
            />
          </ScrollAnimation>

          <ScrollAnimation animateIn="infinite" duration={0.3}>
            <HeroFeature title="Infinite" sub="Monetization & App Rewards" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="referral" duration={0.3}>
            <HeroFeature
              title="Referral Reward"
              sub="Becomes sale Agent - Earn Commission"
            />
          </ScrollAnimation>
          <ScrollAnimation animateIn="unlimited" duration={0.3}>
            <HeroFeature
              title="Unlimited"
              sub="Free & Discount Premium Offer"
            />
          </ScrollAnimation>
        </PhoneContent>
        <ScrollAnimation animateIn="fadeIn" duration={0.3} className="explore">
          <ButtonContainer>
            <ButtonExplore
              width={isIpad ? "auto" : "30rem"}
              onClick={() =>
                ownTheFutureRef.current?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              <span>{t("Explore")}</span>
            </ButtonExplore>
            <p>{t("Join the POOLS - Own, Earn, Evolve")}</p>
          </ButtonContainer>
        </ScrollAnimation>
      </PhoneContainer>
    </Container>
  );
};

export default NewHero;
const Container = styled.div`
  position: relative;
  > img {
    width: 100%;
    height: auto;
  }
`;
const ContentContainer = styled.div`
  /* width: 100%; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const PhoneContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  padding: 10rem 0;
  h2 {
    font-family: "Dela Gothic One";
    font-size: 80px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0em;
    text-align: center;
    color: #fafafa;
    /* white-space: nowrap; */
  }
  .explore {
    margin-top: -6rem;
  }
  @media screen and (max-width: ${breakPoints.desktop}) {
    padding: 8rem 0;
    > h2 {
      font-size: 64px;
    }
  }
  @media screen and (max-width: 1280px) {
    padding-bottom: 5rem;
  }
  @media screen and (max-width: ${breakPoints.mdTablet}) {
    padding: 2rem 0;
    padding-bottom: 1rem;
    > h2 {
      font-size: 18px;
    }
  }
`;

const PhoneContent = styled.div`
  position: relative;
  width: 30%;
  max-height: 75%;
  margin: 0 auto;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  > img {
    width: auto;
    max-height: 80%;
    height: 100%;
    margin: 0 auto;
  }
  .scroll-down {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    animation: scroll-down-jump linear 2s infinite;
    @keyframes scroll-down-jump {
      0%,
      100% {
        transform: translate(-50%, 0);
      }
      50% {
        transform: translate(-50%, -10px);
      }
    }
  }
  .powerful {
    position: absolute;
    top: 32%;
    left: -75%;
    animation: powerful-animation ease-in 0.3s;
    @keyframes powerful-animation {
      0% {
        left: 0%;
      }
      100% {
        left: -75%;
      }
    }
  }
  .smart {
    position: absolute;
    top: 67%;
    left: -75%;
    animation: smart-animation ease-in 0.3s;
    @keyframes smart-animation {
      0% {
        left: -150%;
      }
      100% {
        left: -75%;
      }
    }
  }

  .infinite {
    position: absolute;
    top: 15%;
    right: -10%;
    animation: infinite-animation ease-in 0.3s;
    @keyframes infinite-animation {
      0% {
        right: 20%;
      }
      100% {
        right: -10%;
      }
    }
  }
  .referral {
    position: absolute;
    top: 44%;
    right: 15%;
    animation: referral-animation ease-in 0.3s;
    @keyframes referral-animation {
      0% {
        right: -15%;
      }
      100% {
        right: 15%;
      }
    }
  }
  .unlimited {
    position: absolute;
    top: 80%;
    right: -2%;
    animation: unlimited-animation ease-in 0.3s;
    @keyframes unlimited-animation {
      0% {
        right: -20%;
      }
      100% {
        right: -2%;
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .powerful,
    .smart {
      left: -100%;
    }
    .scroll-down {
      top: 5%;
    }
  }
  @media screen and (max-width: ${breakPoints.desktop}) {
    > img {
      margin: 0 auto;
    }
    .powerful,
    .smart {
      left: -65%;
    }
    .scroll-down {
      top: 7%;
    }
  }
  @media screen and (max-width: 1280px) {
    .powerful,
    .smart {
      left: -75%;
    }
    .scroll-down {
      top: 4%;
    }
  }
  @media screen and (max-width: ${breakPoints.mdTablet}) {
    .powerful {
      top: 18%;
      left: -75%;
    }
    .infinite {
      top: 4%;
      right: -3%;
    }
    .referral {
      top: 37%;
    }
    .unlimited {
      top: 73%;
    }
    .scroll-down {
      display: none;
    }
  }
`;

const ScrollDownButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: rgba(255, 184, 0, 0.87);
  min-width: 20rem;
  max-width: 22rem;
  border-radius: 5rem;
  padding: 0.5rem 2rem;
  cursor: pointer;

  > span {
    font-family: "Unbounded";
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.2rem;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    white-space: nowrap;
  }
  @media screen and (max-width: ${breakPoints.mdTablet}) {
    top: 23%;
    transform: translateX(-56%);

    min-width: 6rem;
    padding: 0.2rem 0.5rem;
    gap: 0.5rem;
    > svg {
      max-width: 0.8rem;
      max-height: 0.8rem;
    }
    > span {
      font-family: "Unbounded";
      font-size: 0.6rem;
      font-weight: 600;
      line-height: 0.8rem;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;
const ButtonExplore = styled(ButtonCustom)`
  span {
    font-family: "Unbounded";
    font-size: 4.2rem;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0em;
    color: #fafafa;
  }
  &:hover {
    span {
      color: #0047ff;
    }
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  transition: all 0.3s;

  p {
    font-family: "Unbounded";
    font-size: 2.9rem;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #fafafa;
  }
  @media screen and (max-width: ${breakPoints.desktop}) {
    gap: 1rem;

    span {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    bottom: 3%;
    gap: 0.5rem;
    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
    }
    p {
      font-size: 8px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      white-space: nowrap;
    }
  }
`;
