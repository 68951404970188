import { styled } from "styled-components";

function PrivacyPolicyPoolsCall() {
  return (
    <Container>
      <TitleHeader>{"Privacy Policy"}</TitleHeader>

      <div>
        <Title style={{ fontSize: "2rem", marginBottom: "1rem" }}>
          {"Effective Date: May 2024"}
        </Title>
        <Description>
          {`We prioritize your trust and are dedicated to safeguarding your privacy. This Privacy Policy governs the collection, usage, disclosure, and protection of your personal information when you interact with our mobile application and services,
`}
          <a href=" https://play.google.com/store/apps/details?id=com.pools.call"> Pools Call </a>
          {`("App"). Please take the time to thoroughly review this Privacy Policy. If you disagree with any of its terms, we kindly ask that you refrain from accessing the App. This Privacy Policy delineates how Pools Mobility ("we," "us," or "our"), operating through the website`}
          <a href="https://www.poolsmobility.com"> https://www.poolsmobility.com </a>
          {` manages information collected from users of our mobile application, Pools Call (the "App")."`}
        </Description>
      </div>

      <Content>
        <Title>{`Information We Collect`}</Title>
        <Description>
          {`We collect personal information you provide during your interactions with our app, including but not limited to your name, email address, and any additional voluntary information. We gather this data for various purposes, such as account creation, service delivery, and improving user experience.
`}
        </Description>
      </Content>

      <Content>
        <Title>{`How We Use Your Information`}</Title>
        <Description>
          {`We use the information we collect to provide, maintain, and enhance the App, communicate with you, fulfill your requests, provide customer service, and comply with legal obligations.
`}
        </Description>
      </Content>

      <Content>
        <Title>{`Information Sharing`}</Title>
        <Description>
          {`We do not share your personal information with third parties except as described in this Privacy Policy or with your explicit consent.
`}
        </Description>
      </Content>

      <Content>
        <Title>{`Disclosure of Personal Data`}</Title>
        <Description>
          {`We may share your personal data with authorized partners to deliver services. Additionally, we may disclose your information to law enforcement or government agencies to comply with applicable laws. In certain circumstances, such as restructuring or mergers, your personal data may be disclosed. We may also disclose data to safeguard our rights, prevent illegal activities, or fulfill legal obligations.
`}
        </Description>
      </Content>

      <Content>
        <Title>{`Access & Control of Personal Data`}</Title>
        <Description>
          {`You have the right to access, update, or delete your personal data. To exercise these rights, please contact us, and we will verify your identity before processing your request.
`}
        </Description>
      </Content>

      <Content>
        <Title>{`Protection and Retention of Personal Data`}</Title>
        <Description>
          {`We employ physical, technical, and managerial measures to safeguard your personal data from unauthorized access, use, modification, or loss. We retain your information for as long as necessary to fulfill the purposes stated in this Privacy Policy.
`}
        </Description>
      </Content>

      <Content>
        <Title>{`Processing Children's Personal Data`}</Title>
        <Description>
          {`Our app is not directed at children under the age of 13, and we do not knowingly collect personal data from minors. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take appropriate steps to remove such information from our records.
`}
        </Description>
      </Content>

      <Content>
        <Title>{`Third-Party Providers and Services`}</Title>
        <Description>
          {`Our app may integrate with third-party services or contain links to third-party websites. These third parties have their own privacy policies, and we encourage you to review their policies before providing any personal information.
`}
        </Description>
      </Content>


      <Content>
        <Title>{`International Transfers of Personal Data`}</Title>
        <Description>
          {`Your personal data may be transferred to and processed in countries outside of your jurisdiction, where data protection laws may differ. By using our app, you consent to the transfer of your information to these countries and the processing of your data in accordance with this Privacy Policy.
`}
        </Description>
      </Content>

      <Content>
        <Title>{`Updates to This Policy`}</Title>
        <Description>
          {`We may update or amend this Privacy Policy at any time. The latest version will be available on our website at https://www.poolsmobility.com. Major changes may be communicated through different channels.
`}
          <br />
          <br />
          {`If you have questions or concerns about our Privacy Policy, please reach out to our support team at `}
          <a href="mailTo: contact@poolsclub.com">contact@poolsclub.com</a>
        </Description>
      </Content>

      <h1>Thank you for choosing POOLS Call!</h1>
    </Container>
  );
}

export default PrivacyPolicyPoolsCall;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
  max-width: 102.4rem;
  margin: 0 auto;
  padding: 6rem;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  min-height: 100vh;
  color: #434343;

  @media screen and (max-width: 500px) {
    padding: 0.3rem 2rem;
    padding-top: 6rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`;

const TitleHeader = styled.h1`
  font-family: Archivo;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 3rem;
  }
`;

const Title = styled.h2`
  font-family: Archivo;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-family: Archivo;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;
