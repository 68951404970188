import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationKO from "./constant/language/ko.json";
import translationVI from "./constant/language/vi.json";
import translationEN from "./constant/language/en.json";
// import translationCN from "./constant/language/cn.json";
// import translationJP from "./constant/language/jp.json";
// import translationES from "./constant/language/es.json";
// import translationPT from "./constant/language/pt.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ko: {
    translation: translationKO,
  },
  vi: {
    translation: translationVI,
  },
  // jp: {
  //   translation: translationJP,
  // },
  // cn: {
  //   translation: translationCN,
  // },
  // es: {
  //   translation: translationES,
  // },
  // pt: {
  //   translation: translationPT,
  // },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
