import * as React from "react";
import styled from "styled-components";
import { MainLayoutContainer } from "../../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import BgWhy1 from "../assets/images/whychoose/bg-why-3.png";
import BgWhy3 from "../assets/images/whychoose/bg-why-1.png";
import BgWhy2 from "../assets/images/whychoose/bg-why-2.png";
import BgWhy4 from "../assets/images/whychoose/bg-why-4.png";
import Why1 from "../assets/images/whychoose/why-1.png";
import Why2 from "../assets/images/whychoose/why-2.png";
import Why3 from "../assets/images/whychoose/why-3.png";
import ReasonSlide1 from "../assets/images/whychoose/reason-slide-1.png";
import ReasonSlide2 from "../assets/images/whychoose/reason-slide-2.png";
import ReasonSlide3 from "../assets/images/whychoose/reason-slide-3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Grid, Pagination } from "swiper/modules";
import { breakPoints } from "../../../constant/breakpoints";
import "swiper/css/grid";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ScrollAnimation from "react-animate-on-scroll";

const mocks: Array<{
  title: string;
  reasons: Array<string>;
  image: string;
  background: string;
}> = [
  {
    title: `PHONE TURNED ON - REWARDS EARNING ON`,
    reasons: [
      "Multiple Ways to Earn via POOLS PHONE",
      "Sale commission, Referral-invitation Rewards",
      "Game-fi Rewards, Play & Earn",
      "Earn from DApp Rewards",
      "& many more upcomings",
    ],
    image: Why1,
    background: BgWhy1,
  },
  {
    title: `Exclusive Free Premium Big Savings, Zero Cost`,
    reasons: [
      "Pool Owners automatically enjoy complimentary access to premium plans for cutting-edge AI-powered apps like Friendify Premium and various applications within the POOLS Ecosystem.",
      "Save Up to $240!",
      "Stay tuned for the release of this exciting feature.",
    ],
    image: Why2,
    background: BgWhy2,
  },
  {
    title: `Be an Agent - Refer & Earn Big Commissions`,
    reasons: [
      "Unlock unexpected profits via our Easy Referral Rewards program - Refer, Earn & Profit",
      "Start your profitability in Easy 3-Step Earnings",
    ],
    image: Why3,
    background: BgWhy3,
  },
];

const mockSlides: Array<{
  title: string;
  reasons: Array<string>;
  image: string;
}> = [
  {
    title: "POOLS OPEN MARKET",
    reasons: [
      "Shop & Earn in POOLS Market",
      "Earn While You Shop",
      "Exclusive Shopping, Big Rewards",
      "Unlock Rewards Every Time",
      "Shop, Earn, Repeat!",
    ],
    image: ReasonSlide1,
  },
  {
    title: "POOLS ID: Biometric Security",
    reasons: [
      "ID & Payments, Unlocked by You",
      "Fingerprint Authentication Made Simple",
      "No-Face Authentication. Easy!",
      "No More Passwords",
      "Security at Your Touch",
    ],
    image: ReasonSlide2,
  },
  {
    title: "POOLS WALLET",
    reasons: [
      "Military-Grade Security, Effortless Transactions",
      "Global Trade Crypto, Hold, Send, Trade with Confidence",
      "Effortless Digital Asset Management",
      "Decentralized Exchange, Diverse Assets",
      "Optimize, Trade, & Profit",
    ],
    image: ReasonSlide3,
  },
];

function WhyChoosePhone() {
  const { t } = useTranslation();

  return (
    <Container>
      <ScrollAnimation animateIn="fadeIn">
        <Heading>
          <h1>{t("WHY CHOOSE?")}</h1>
          <h5>{t("Be The First - The Future Await")}</h5>
        </Heading>
      </ScrollAnimation>
      <Swiper
        style={{
          width: "100%",
          overflowY: "visible",
        }}
        modules={[Grid, Pagination]}
        breakpoints={{
          0: {
            grid: {
              rows: 1,
            },
            pagination: {
              el: "#whychoose-pagination-swiper",
              clickable: true,
              renderBullet: (_, className) => {
                return `<span class="${className}"></span>`;
              },
            },
          },
          1024: {
            grid: {
              rows: 3,
              fill: "row",
            },
            spaceBetween: 80,
            pagination: {
              el: "",
            },
          },
          1530: {
            grid: {
              rows: 3,
              fill: "row",
            },
            spaceBetween: 120,
          },
        }}
      >
        {mocks.map((item, index) => (
          <SwiperSlide
            style={{
              height: "auto",
            }}
            key={index}
          >
            <MainLayoutContainer
              style={{
                height: "100%",
              }}
            >
              <ScrollAnimation animateIn="fadeInUp">
                <Reason $background={item.background}>
                  <Box $orderFirst={index !== 0 && index % 2 !== 0}>
                    <ScrollAnimation
                      animateIn={index === 1 ? "fadeInRight" : "fadeInLeft"}
                    >
                      <h5>{t(item.title)}</h5>
                      <ul>
                        {item.reasons.map((reason, reasonIdx) => (
                          <li key={`${index}-${reasonIdx}`}>{t(reason)}</li>
                        ))}
                      </ul>
                    </ScrollAnimation>
                  </Box>
                  <div className="item_phone">
                    <img src={item.image} alt={item.title} />
                  </div>
                </Reason>
              </ScrollAnimation>
            </MainLayoutContainer>
          </SwiperSlide>
        ))}
      </Swiper>
      <WhyChoosePagination id="whychoose-pagination-swiper" />
      <MainLayoutContainer>
        <SpecialReason $background={BgWhy4}>
          <ScrollAnimation animateIn="fadeInUp">
            <BoxHorizontal>
              <h5>{t("Trade Smart - Stay Secure")}</h5>
              <div>
                <p>{t("Introducing Cutting-Edge FinTech Innovations:")}</p>
                <ul>
                  <li>
                    {t(
                      "POOLS Wallet - Crypto Securely Stored by Main-net Powered"
                    )}
                  </li>
                  <li>
                    {t(
                      "POOLS ID - ID of the Future powered by biometric data"
                    )}
                  </li>
                  <li>
                    {t("POOLS Open Market - Exclusive Shopping, Big Rewards")}
                  </li>
                </ul>
              </div>
            </BoxHorizontal>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <SwiperContainer
              modules={[Pagination]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 16,
                },
                1024: {
                  slidesOffsetBefore: 40,
                  slidesOffsetAfter: 40,
                  slidesPerView: 1.1,
                  spaceBetween: 24,
                },
              }}
              pagination={{
                el: ".reason-custom-pagination",
                clickable: true,
                renderBullet: (_, className) => {
                  return `<span class="${className}"></span>`;
                },
              }}
            >
              {mockSlides.map((slide, index) => (
                <SlideBox key={index}>
                  <div>
                    <h5>{t(slide.title)}</h5>
                    <ul>
                      {slide.reasons.map((reason, reasonIndex) => (
                        <li key={`${index}-${reasonIndex}`}>{t(reason)}</li>
                      ))}
                    </ul>
                  </div>
                  <img src={slide.image} alt={slide.title} />
                </SlideBox>
              ))}
            </SwiperContainer>
          </ScrollAnimation>
          <CustomPagination className="reason-custom-pagination"></CustomPagination>
        </SpecialReason>
      </MainLayoutContainer>
    </Container>
  );
}

export default WhyChoosePhone;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;

  @media (min-width: ${breakPoints.ipad}) {
    row-gap: 4rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 8rem;
  }
  @media (min-width: ${breakPoints.lgDesktop}) {
    row-gap: 12rem;
  }
`;

const Heading = styled(MainLayoutContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 0.4rem;
  > h1 {
    color: #262626;
    font-family: Unbounded;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
  }
  > h5 {
    color: #727272;
    font-family: Unbounded;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
  }

  @media (min-width: ${breakPoints.mobile}) {
  }
  @media (min-width: ${breakPoints.ipad}) {
    row-gap: 1.6rem;
    > h1 {
      font-size: 40px;
    }
    > h5 {
      font-size: 16px;
    }
  }
  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 2.4rem;
    > h1 {
      font-size: 96px;
    }
    > h5 {
      font-size: 24px;
    }
  }
  @media (min-width: ${breakPoints.desktop}) {
  }
`;

const Reason = styled.div<{ $background?: string }>`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: start;
  column-gap: 5rem;
  row-gap: 3.2rem;
  box-shadow: 0px 12px 48px 0px rgba(0, 71, 255, 0.22);
  background-image: url(${(props) => props.$background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.6rem;
  padding-top: 1.4rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  overflow: hidden;
  > div:last-child {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
  > div img {
    width: 100%;
    height: auto;
  }

  ul,
  p {
    padding-left: 2rem;
    font-size: 12px;
    color: #143079;
    font-family: Lexend;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
  }

  h5 {
    color: #0047ff;
    font-family: Unbounded;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .item_phone:hover {
    transition: all ease 300ms;
    transform: scale(1.05);
  }

  @media (min-width: ${breakPoints.mobile}) {
    padding-top: 2.8rem;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    height: fit-content;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: ${breakPoints.lgDesktop}) {
    border-radius: 3.2rem;
    padding-top: 4.8rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
    ul,
    p {
      font-size: 20px;
      padding-left: 4rem;
    }

    h5 {
      font-size: 40px;
    }
  }
`;

const Box = styled.div<{ $orderFirst: boolean }>`
  border-radius: 18px;
  background: rgba(251, 251, 255, 0.58);
  box-shadow: 0px -9.775px 12.49px 0px #bdcaec inset,
    0px 4.344px 41.054px 0px #fff inset,
    27.152px 36.926px 64.404px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15.600000381469727px);
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  height: 100%;
  @media (min-width: ${breakPoints.mobile}) {
    padding: 2rem 3rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    height: fit-content;
    order: ${(props) => (props.$orderFirst ? "9999" : "0")};
  }
  @media (min-width: ${breakPoints.desktop}) {
    margin-bottom: 2.8rem;
    margin-top: 4rem;
    max-width: 72.7rem;
    row-gap: 2.4rem;
    padding: 45px 68px;
    border-radius: 40px;
  }
`;

const SpecialReason = styled(Reason)`
  width: 100%;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1.6rem;
  padding: 3.2rem 0;
  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 5.8rem;
    padding: 4rem 0;
  }
`;

const BoxHorizontal = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 8rem;
  row-gap: 1.6rem;
  align-items: center;
  padding: 0 1.6rem;

  @media (min-width: ${breakPoints.laptop}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0 4rem;
  }
`;

const SwiperContainer = styled(Swiper)`
  width: 100%;
  overflow-y: visible;
`;

const SlideBox = styled(SwiperSlide)`
  height: auto !important;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1.6rem;
  align-items: center;
  border-radius: 32px;
  background: rgba(251, 251, 255, 0.58);
  box-shadow: 0px -9.775px 12.49px 0px #bdcaec inset,
    0px 4.344px 41.054px 0px #fff inset,
    27.152px 36.926px 64.404px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15.600000381469727px);
  padding: 1.6rem;
  > div {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    > h5 {
      color: #143079;
      font-family: Unbounded;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.03;
    }
  }
  > img {
    width: 100%;
    height: auto;
    border-radius: 15px;
    background: linear-gradient(180deg, #000614 0%, #000 100%), #727272;
    transition: all ease 300ms;
    &:hover {
      transform: scale(1.05);
    }
  }

  @media (min-width: ${breakPoints.laptop}) {
    height: fit-content !important;
    padding: 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    border-radius: 32px;
    > div {
      padding-left: 2.4rem;
      row-gap: 3.2rem;
      > h5 {
        font-size: 31px;
      }
    }
    > img {
      border-radius: 32px;
    }
  }
`;

const CustomPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;

  .swiper-pagination-bullet {
    width: 16px;
    height: 8px;
    border-radius: 5rem;
    background-color: #bfbfbf;
    opacity: 0.5;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 53.3px;
    opacity: 1;
    background-color: #0047ff;
  }

  @media (min-width: ${breakPoints.laptop}) {
    .swiper-pagination-bullet {
      width: 24px;
      height: 12px;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 80px;
    }
  }
`;

const WhyChoosePagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;

  .swiper-pagination-bullet {
    width: 16px;
    height: 8px;
    border-radius: 5rem;
    background-color: #bfbfbf;
    opacity: 0.5;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 53.3px;
    opacity: 1;
    background-color: #0047ff;
  }

  @media (min-width: ${breakPoints.laptop}) {
    .swiper-pagination-bullet {
      width: 24px;
      height: 12px;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 80px;
    }
  }
`;
