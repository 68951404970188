import { useContext } from "react";
import styled from "styled-components";
import EnIcon from "./icons/en.svg";
import VnIcon from "./icons/vn.svg";
import JpIcon from "./icons/jp.svg";
import KoIcon from "./icons/ko.svg";
import SpIcon from "./icons/sp.svg";
import PorIcon from "./icons/por.svg";
import CnIcon from "./icons/cn.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { getLocalItem, setLocalItem } from "../../utils/localStorage";
import useTheme from "../../hooks/use-theme";
import { breakPoints } from "../../constant/breakpoints";

interface OptionType {
  text: string;
  icon: string;
  code?: string;
}

const LanguageContainer = styled.div`
  position: relative;
  width: fit-content;
`;

const Select = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 4px;
  user-select: none;
  > img {
    flex: 1;
    max-width: 4rem !important;
    height: auto;
    @media (max-width: 500px) {
      max-width: 3rem !important;
    }
  }
  > p {
    /* display: none; */
    /* color: #fafafa; */
    color: #0047ff;
    font-family: Archivo;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 43.2px */
  }

  svg {
    /* display: none; */
    width: 1.5rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    column-gap: 8px;
    p,
    svg {
      display: block;
    }
    column-gap: 4px;
    > p {
      font-size: 18px;
    }
  }

  @media (min-width: ${breakPoints.desktop}) {
    column-gap: 12px;
    p,
    svg {
      display: block;
    }
  }
`;

const OptionList = styled.div<{ $display: boolean }>`
  position: absolute;
  top: 100%;
  margin-top: auto.4rem;
  /* background-color: #121212; */
  box-shadow: 0px -9.77464771270752px 12.489827156066895px 0px #bdcaec inset,
    0px 4.344287872314453px 41.05352020263672px 0px #ffffff inset,
    27.15180015563965px 36.926448822021484px 64.40406799316406px 0px #00000040;
  color: #fff;
  transform-origin: top;
  transform: ${(props) => (props.$display ? "scaleY(100%)" : "scaleY(0)")};
  transition: linear 150ms;
  border-radius: 0.4rem;
  overflow: hidden;
  z-index: 9999;
`;

const Option = styled.div<{ $active: boolean }>`
  padding: 0.4rem 0.8rem;
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  cursor: ${(props) => (props.$active ? "default" : "pointer")};
  background-color: ${(props) =>
    props.$active ? "rgba(168, 205, 255, 0.5)" : "transparent"};

  color: ${(props) => (props.$active ? "#0047ff" : "#242729")};

  transition: linear 150ms;
  > img {
    flex: 1;
    max-width: 2.4rem !important;
    height: auto;
  }
  > p {
    font-size: 12px;
    font-family: "Unbounded";
  }
  &:hover {
    background-color: rgba(168, 205, 255, 0.5);
    color: #0047ff;
  }
  user-select: none;

  @media (min-width: ${breakPoints.ipad}) {
    padding: 1.2rem 0.8rem;
    column-gap: 0.8rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    column-gap: 1.2rem;
    > p {
      font-size: 10px;
    }
  }
`;
const Flag = styled.img`
  width: 4rem;
  aspect-ratio: 1/1;
`;

export enum LANGUAGES_CODE {
  Vietnamese = "vi",
  Korea = "ko",
  English = "en",
  Japan = "jp",
  China = "cn",
  Spanish = "es",
  Portuguese = "pt",
}

const options: OptionType[] = [
  {
    icon: EnIcon,
    text: "English",
    code: LANGUAGES_CODE.English,
  },
  {
    icon: KoIcon,
    text: "한국어",
    code: LANGUAGES_CODE.Korea,
  },
  {
    icon: VnIcon,
    text: "Tiếng Việt",
    code: LANGUAGES_CODE.Vietnamese,
  },
  // {
  //   icon: JpIcon,
  //   text: "Japanese",
  //   code: LANGUAGES_CODE.Japan,
  // },
  // {
  //   icon: SpIcon,
  //   text: "Spanish",
  //   code: LANGUAGES_CODE.Spanish,
  // },
  // {
  //   icon: PorIcon,
  //   text: "Portuguese",
  //   code: LANGUAGES_CODE.Portuguese,
  // },
  // {
  //   icon: CnIcon,
  //   text: "Chinese",
  //   code: LANGUAGES_CODE.China,
  // },
];

function LanguageSelector({
  placement = "left",
}: {
  placement?: "left" | "right";
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { setTheme } = useTheme();
  const [displayOpt, setDisplayOpt] = useState<boolean>(false);
  const { i18n, t } = useTranslation();

  const handleLanguage = (key: string) => {
    i18n.changeLanguage(key);
    setLocalItem("language", key);
    setDisplayOpt(false);
    setTheme(key);
  };

  useOnClickOutside(ref, () => setDisplayOpt(false));

  const currentLang = (i18n: any) => {
    switch (i18n?.language) {
      case LANGUAGES_CODE.English:
        return <Flag src={EnIcon} alt="en_icon" />;
      case LANGUAGES_CODE.Vietnamese:
        return <Flag src={VnIcon} alt="vi_icon" />;
      case LANGUAGES_CODE.Korea:
        return <Flag src={KoIcon} alt="ko_icon" />;
      case LANGUAGES_CODE.Japan:
        return <Flag src={JpIcon} alt="jp_icon" />;
      case LANGUAGES_CODE.Spanish:
        return <Flag src={SpIcon} alt="sp_icon" />;
      case LANGUAGES_CODE.Portuguese:
        return <Flag src={PorIcon} alt="por_icon" />;
      case LANGUAGES_CODE.China:
        return <Flag src={CnIcon} alt="cn_icon" />;

      default:
        return <Flag src={EnIcon} alt="en_icon" />;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const lang = getLocalItem("language");
      !lang && setLocalItem("language", LANGUAGES_CODE.English);
      i18n.changeLanguage(lang || LANGUAGES_CODE.English);
    }, 100);
  }, []);

  return (
    <LanguageContainer ref={ref}>
      <Select onClick={() => setDisplayOpt(!displayOpt)}>
        {/* {currentLang(i18n)} */}
        <p>
          {t(options.find((opt) => opt.code === i18n.language)?.text as string)}
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M6 9.5L12 15.5L18 9.5"
            stroke="#FAFAFA"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Select>
      <OptionList className={`placement-${placement}`} $display={displayOpt}>
        {options.map((option, index) => (
          <Option
            key={index}
            $active={option.code === i18n.language}
            onClick={() => {
              handleLanguage(option.code || LANGUAGES_CODE.English);
            }}
          >
            {/* <Flag src={option.icon} alt="icon" /> */}
            <p>{t(option?.text || "")}</p>
          </Option>
        ))}
      </OptionList>
    </LanguageContainer>
  );
}

export default LanguageSelector;
