import * as React from "react";
import Hero from "./home/section/Hero/Hero";
import OwnTheFuture from "./home/section/OwnTheFuture";
import styled from "styled-components";
import PhoneSpecs from "./home/section/PhoneSpecs";
import ListPartner from "./home/section/ListPartner";
import Subscribe from "./home/section/Subscribe";
import WhyChoosePhone from "./home/section/WhyChoosePhone";
import Feature from "./home/section/Feature";
import { JoinUs } from "./about/components/community";
import Support from "./home/section/Support/Support";
import OwnYourLook from "./home/section/OwnYourLook";
import { breakPoints } from "../constant/breakpoints";
import NewHero from "./home/section/Hero/NewHero";

function HomePage() {
  const ownTheFutureRef = React.useRef<HTMLDivElement>(null);
  return (
    <Wrapper>
      <NewHero ownTheFutureRef={ownTheFutureRef} />
      {/* <Hero /> */}
      <div ref={ownTheFutureRef}>
        <OwnTheFuture />
      </div>
      <OwnYourLook />
      <WhyChoosePhone />
      <Feature />
      <PhoneSpecs />
      <ListPartner />
      <JoinUs />
      <Support />
      <Subscribe />
    </Wrapper>
  );
}

export default HomePage;

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  margin-bottom: 4rem;

  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 5.8rem;
    margin-bottom: 5.8rem;
  }
`;
