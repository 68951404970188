import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select/dist/declarations/src/Select";
import Option from "react-select/dist/declarations/src/components/Option";
import { toast } from "react-toastify";
import styled from "styled-components";
import { breakPoints } from "../../../../constant/breakpoints";
import useRequest from "../../../../hooks/use-request";
import Api from "../../../../service";

interface FormInputs {
  first_name: string;
  email: string;
  location: string;
  phone_number: string;
  message: string;
}

const SupportForm = () => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormInputs>();

  const {
    data: supportData,
    error,
    loading,
    doRequest,
  } = useRequest<FormInputs>({
    url: Api.Support.createSupport,
    method: "post",
    onSuccess: () => {
      toast.success("Submit success!");
      reset({
        first_name: "",
        email: "",
        location: "",
        phone_number: "",
        message: "",
      });
    },
  });

  const onSubmit = (formData: FormInputs) => {
    doRequest(formData);
  };

  return (
    <Container>
      <FormGroup>
        <Label htmlFor="first_name">{t("First Name")}</Label>
        <Input
          {...register("first_name", {
            required: {
              value: true,
              message: "Can't be empty!",
            },
          })}
          id="first_name"
          size={1}
          $error={errors.first_name?.message !== undefined}
        />
        <ErrorMessage>
          {errors.first_name?.message ? t(errors.first_name.message) : ""}
        </ErrorMessage>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="email">{t("Email")}</Label>
        <Input
          {...register("email", {
            required: {
              value: true,
              message: "Can't be empty!",
            },
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
              message: "Invalid email!",
            },
          })}
          id="email"
          size={1}
          $error={errors.email?.message !== undefined}
        />
        <ErrorMessage>
          {errors.email?.message ? t(errors.email.message) : ""}
        </ErrorMessage>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="location">{t("Location")}</Label>
        <Input
          {...register("location", {
            required: {
              value: true,
              message: "Can't be empty!",
            },
          })}
          id="location"
          size={1}
          $error={errors.first_name?.message !== undefined}
        />
        <ErrorMessage>
          {errors.first_name?.message ? t(errors.first_name.message) : ""}
        </ErrorMessage>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="phone_number">{t("Phone")}</Label>
        <div className="group-phone">
          <Input
            {...register("phone_number", {
              required: {
                value: true,
                message: "Can't be empty!",
              },
            })}
            id="phone_number"
            size={1}
            $error={errors.first_name?.message !== undefined}
          />
        </div>

        <ErrorMessage>
          {errors.first_name?.message ? t(errors.first_name.message) : ""}
        </ErrorMessage>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="message">{t("Message")}</Label>
        <TextArea
          {...register("message", {
            required: {
              value: true,
              message: "Can't be empty!",
            },
            minLength: {
              value: 20,
              message: "Message must be at least 20 characters!",
            },
          })}
          placeholder={t("Write message")}
          id="message"
          rows={5}
          $error={errors.message?.message !== undefined}
        />
        <ErrorMessage>
          {errors.message?.message ? t(errors.message.message) : ""}
        </ErrorMessage>
      </FormGroup>
      <Button disabled={loading} onClick={handleSubmit(onSubmit)}>
        {t("Submit")}
      </Button>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default SupportForm;

const Container = styled.div`
  flex: 1;
  padding: 4rem;
  border-radius: 2.4rem;
  border: 1px solid #727272;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 100%;
  @media screen and (max-width: ${breakPoints.ipad}) {
    padding: 2rem 1rem;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  .group-phone {
    width: 100%;
    display: flex;
    gap: 1rem;
    > select {
      width: 10rem;
      border: none;
      border-bottom: 1px solid #727272;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";

      //styleName: new1.1/Body/4;
      font-family: "Lexend";
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.7rem;
      letter-spacing: 0em;
      text-align: center;
    }
  }
`;

const Label = styled.label`
  color: #727272;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  @media screen and (max-width: ${breakPoints.ipad}) {
    //styleName: new1.1/Body/4;
    font-family: "Lexend";
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const Input = styled.input<{ $error?: boolean; width?: string }>`
  width: ${(props) => (props.width ? props.width : "100%")};
  padding: 0.8rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid ${(props) => (props.$error ? "#db2929" : "#262626")};
  font-size: 2rem;
  font-family: Lexend;
  @media screen and (max-width: ${breakPoints.ipad}) {
    padding: 0;
    font-size: 1.6rem;
  }
`;

const TextArea = styled.textarea<{ $error?: boolean }>`
  padding: 0.8rem;
  background-color: transparent;
  font-size: 1.6rem;
  font-family: "Lexend";
  resize: vertical;
  border: 1px solid #262626;
  border-radius: 0.4rem;
  outline: none;
  margin-top: 2rem;
`;

const Button = styled.button`
  background-color: #0047ff;
  padding: 1.2rem 5.6rem;
  color: #fff;
  text-align: center;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  border: none;
  width: fit-content;
  border-radius: 50rem;
  cursor: pointer;
  transition: all ease 150ms;
  &:active {
    transform: translateY(-0) scale(0.8);
    opacity: 0.9;
  }
  &:hover {
    transform: translateY(-0.4rem);
  }
  &:disabled {
    background-color: #3c69da;
  }
`;

const ErrorMessage = styled.p`
  margin-top: 0.4rem;
  font-size: 1.2rem;
  font-style: italic;
  color: #db2929;
  font-weight: 300;
  line-height: 1;
  min-height: 1.2rem;
`;
