import React from "react";
import { styled } from "styled-components";
import { menuItems } from "../../../constant/menu";
import Menu from "../../Menu/Menu";
import { breakPoints } from "../../../constant/breakpoints";
import Logo from "../icons/Logo";
import UserIconOutlineCircle from "../icons/UserIconOutlineCircle";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LanguageSelector from "../../LanguageSelector";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isIpad } = useWindowDimensions();
  return (
    <>
      {!isIpad ? (
        <Wrapper id="header">
          <Container>
            <LeftContainer onClick={() => navigate("/")}>
              <Logo />
            </LeftContainer>
            <RightContainer>
              <MenuContainer>
                <Menu items={menuItems} />
              </MenuContainer>
              {/* <UserIconOutlineCircle /> */}
              <LanguageSelector />
            </RightContainer>
          </Container>
        </Wrapper>
      ) : (
        <MobileContainer>
          <LanguageSelector />
        </MobileContainer>
      )}
    </>
  );
};

export default Header;
const MobileContainer = styled.div`
  position: absolute;
  top: 0;
  right: 5%;
  z-index: 999999999;
`;
const Wrapper = styled.header`
  /* background-color: rgba(75, 153, 235, 0.8); */
  background-color: transparent;
  width: 100%;
  overflow: visible;
  position: absolute;
  z-index: 99999999;
  left: 0;
  right: 0;
  top: -4px;
  max-width: 1920px;
  margin: 0 auto;
  @media screen and (max-width: ${breakPoints.ipad}) {
    /* display: none; */
  }
`;

const Container = styled.div`
  margin: 0 auto;
  min-height: 0;
  padding: 10.6rem;
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  max-width: 100%;
  /* @media (min-width: ${breakPoints.laptop}) {
    padding: 2.4rem 1.6rem;
    padding-top: 2.8rem;
    max-width: ${breakPoints.laptop};
  }
  @media (min-width: 1200px) {
    max-width: ${breakPoints.desktop};
  } */
`;

const LeftContainer = styled.div`
  width: fit-content;
  > svg {
    cursor: pointer;
  }
  @media (max-width: ${breakPoints.ipad}) {
    > svg {
      width: 7rem;
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10rem;
`;

const MenuContainer = styled.div`
  display: flex;
`;
