import React from "react";

const TiktokIcon = ({
  width = 32,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M22.1335 7.76C21.2221 6.7195 20.7198 5.38324 20.7202 4H16.6002V20.5333C16.5684 21.428 16.1907 22.2755 15.5466 22.8973C14.9024 23.519 14.0421 23.8665 13.1469 23.8667C11.2535 23.8667 9.68021 22.32 9.68021 20.4C9.68021 18.1067 11.8935 16.3867 14.1735 17.0933V12.88C9.57354 12.2667 5.54688 15.84 5.54688 20.4C5.54688 24.84 9.22688 28 13.1335 28C17.3202 28 20.7202 24.6 20.7202 20.4V12.0133C22.3909 13.2131 24.3967 13.8569 26.4535 13.8533V9.73333C26.4535 9.73333 23.9469 9.85333 22.1335 7.76Z"
        fill="#fff"
      />
    </svg>
  );
};

export default TiktokIcon;
