import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { breakPoints } from "../../../constant/breakpoints";
import ScrollAnimation from "react-animate-on-scroll";

const PhoneSpecs = () => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState<boolean>(false);
  const windowDimensions = useWindowDimensions();
  const isIpad = windowDimensions.width <= 768;
  const listSpecs = [
    [
      {
        title: "Network",
        specs: [
          {
            name: "Technology",
            param: "GSM / CDMA / HSPA / LTE",
          },
          {
            name: "2G bands",
            param: [
              "GSM 850 / 900 / 1800 / 1900 - SIM 1 & SIM 2",
              "CDMA 800 / 1900",
            ],
          },
          {
            name: "3G bands",
            param: "HSDPA 800 / 850 / 900 / 1700(AWS) / 1900 / 2100",
          },
          {
            name: "4G bands",
            param:
              "1, 2, 3, 4, 5, 7, 8, 12, 13, 17, 18, 19, 20, 25, 26, 28, 38, 39, 40, 41, 66",
          },
          {
            name: "Speed",
            param: "HSPA, LTE",
          },
        ],
      },
      {
        title: "Main Camera",
        specs: [
          {
            name: "Triple",
            param: ["100 MP, (wide), PDAF", "5 MP, (macro)", "0.3 MP"],
          },
          {
            name: "Features",
            param: "LED flash",
          },
          {
            name: "Video",
            param: "Yes",
          },
        ],
      },
    ],
    [
      {
        title: "Launch",
        specs: [
          {
            name: "Launch",
            param: "Announced 2024",
          },
          {
            name: "Status",
            param: "Available. Released 2024",
          },
        ],
      },
      {
        title: "Selfie Camera",
        specs: [
          {
            name: "Sensors",
            param:
              "Fingerprint (side-mounted), accelerometer, gyro, proximity, compass",
          },
        ],
      },
    ],
    [
      {
        title: "Body",
        specs: [
          {
            name: "Dimensions",
            param: "165.1 x 75.3 x 10.2 mm (6.5 x 2.96 x 0.40 in)",
          },
          {
            name: "Weight",
            param: "220 g (7.76 oz)",
          },
          {
            name: "SIM",
            param: "Hybrid Dual SIM (Nano-SIM, dual stand-by)",
          },
        ],
      },
      {
        title: "COMMS",
        specs: [
          {
            name: "WLAN",
            param: "Wi-Fi 802.11 a/b/g/n/ac, dual-band",
          },
          {
            name: "Bluetooth",
            param: "5.2, A2DP",
          },
          {
            name: "Positioning",
            param: "GPS, GLONASS, GALILEO, BDS",
          },
          {
            name: "NFC",
            param: "Yes",
          },
          {
            name: "Radio",
            param: "Unspecified",
          },
          {
            name: "USB",
            param: "USB Type-C, OTG",
          },
        ],
      },
    ],
    [
      {
        title: "Display",
        specs: [
          {
            name: "Type",
            param: "IPS LCD, 120Hz",
          },
          {
            name: "Weight",
            param: "6.58 inches, 104.3 cm2 (~83.9% screen-to-body ratio)",
          },
          {
            name: "Resolution",
            param: "1080 x 2408 pixels, 20:9 ratio (~401 ppi density)",
          },
        ],
      },
      {
        title: "Features",
        specs: [
          {
            name: "Sensors",
            param:
              "Fingerprint (side-mounted), accelerometer, gyro, proximity, compass",
          },
        ],
      },
    ],
    [
      {
        title: "Platform",
        specs: [
          {
            name: "OS",
            param: "Android 13",
          },
          {
            name: "Chipset",
            param: "Mediatek Helio G99 (6nm)",
          },
          {
            name: "CPU",
            param: "Octa-core (2x2.2 GHz Cortex-A76 & 6x2.0 GHz Cortex-A55)",
          },
          {
            name: "CPU",
            param: "Octa-core (2x2.2 GHz Cortex-A76 & 6x2.0 GHz Cortex-A55)",
          },
          {
            name: "GPU",
            param: "Mali-G57 MC2",
          },
        ],
      },
      {
        title: "Battery",
        specs: [
          {
            name: "Type",
            param: "5200 mAh, non-removable",
          },
        ],
      },
    ],
    [
      {
        title: "Memory",
        specs: [
          {
            name: "Card slot",
            param: "microSDXC (uses shared SIM slot)",
          },
          {
            name: "Internal",
            param: "256GB 12GB RAM",
          },
        ],
      },
      {
        title: "MISC",
        specs: [
          {
            name: "Colors",
            param: "DARK MYSTIC, GALACTIC BLUE",
          },
        ],
      },
    ],
  ];
  const showListSpecs = useMemo(
    () =>
      showMore ? listSpecs : isIpad ? [[listSpecs[0][0]]] : [listSpecs[0]],
    [isIpad, showMore]
  );
  return (
    <section>
      {!isIpad ? (
        <Container>
          <ScrollAnimation animateIn="fadeIn" animateOnce duration={0.85}>
            <Title>
              <span>{t("POOLS Phone")}</span>
              <h2>{t("Tech-Specs")}</h2>
            </Title>
          </ScrollAnimation>
          {!showMore && (
            <Button>
              <span onClick={() => setShowMore(true)}>{t("Show More")}</span>
            </Button>
          )}
          {showListSpecs.map((specPart, index) => (
            <WrapperSpecifications key={index} $idx={index}>
              {specPart.map((spec, index) => {
                return (
                  <ScrollAnimation
                    animateIn={index === 0 ? "fadeInLeft" : "fadeInRight"}
                    animateOnce
                    duration={0.85}
                    style={{ width: "100%" }}
                  >
                    <WrapperItemsSpecs key={spec.title}>
                      <div className="wrapperHeader">
                        <h5>{t(spec.title)}</h5>
                      </div>
                      <ListItemSpecs>
                        {spec.specs.map((item) => (
                          <div key={item.name} className="itemSpecs">
                            <span className="infoSpecs">{t(item.name)}</span>
                            {Array.isArray(item.param) ? (
                              <ul>
                                {item.param.map((param) => (
                                  <li>{t(param)}</li>
                                ))}
                              </ul>
                            ) : (
                              <span>{t(item.param)}</span>
                            )}
                          </div>
                        ))}
                      </ListItemSpecs>
                    </WrapperItemsSpecs>
                  </ScrollAnimation>
                );
              })}
            </WrapperSpecifications>
          ))}
          {showMore && (
            <Button>
              <span onClick={() => setShowMore(false)}>{t("Show Less")}</span>
            </Button>
          )}
        </Container>
      ) : (
        <ContainerMobile>
          <Title>
            <span>{t("POOLS Phone")}</span>
            <h2>{t("Tech-Specs")}</h2>
          </Title>
          {!showMore && (
            <Button>
              <span onClick={() => setShowMore(true)}>{t("Show More")}</span>
            </Button>
          )}
          {showListSpecs.map((specPart, index) => (
            <WrapperSpecifications key={index} $idx={index}>
              {specPart.map((spec) => {
                return (
                  <WrapperItemsSpecs key={spec.title}>
                    <div className="wrapperHeader">
                      <h5>{t(spec.title)}</h5>
                    </div>
                    <ListItemSpecs>
                      {spec.specs.map((item) => (
                        <div key={item.name} className="itemSpecs">
                          <span className="infoSpecs">{t(item.name)}</span>
                          {Array.isArray(item.param) ? (
                            <ul>
                              {item.param.map((param) => (
                                <li>{t(param)}</li>
                              ))}
                            </ul>
                          ) : (
                            <span>{t(item.param)}</span>
                          )}
                        </div>
                      ))}
                    </ListItemSpecs>
                  </WrapperItemsSpecs>
                );
              })}
            </WrapperSpecifications>
          ))}
          {showMore && (
            <Button>
              <span onClick={() => setShowMore(false)}>{t("Show Less")}</span>
            </Button>
          )}
        </ContainerMobile>
      )}
    </section>
  );
};

export default PhoneSpecs;

const ContainerMobile = styled.div`
  padding: 0 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
`;

const Container = styled.div`
  padding: 0 5rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  text-align: center;
  color: #262626;
  font-family: Unbounded;
  > span {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
  }
  > h2 {
    font-size: 9.6rem;
    line-height: 11.6rem;
    font-weight: 400;
  }
  animation: fadeIn 1s;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    gap: 0.4rem;
    > span {
      font-size: 1rem;
      line-height: 1.24rem;
      font-weight: 400;
    }
    > h2 {
      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: 400;
    }
  }
`;

const WrapperSpecifications = styled.div<{ $idx: number }>`
  display: flex;
  /* gap: 7.795rem; */
  max-width: 100%;
  @media screen and (max-width: ${breakPoints.mDesktop}) {
    flex-direction: column;
    /* gap: 2.4rem; */
  }
  @media screen and (max-width: ${breakPoints.laptop}) {
    flex-direction: column;
    /* gap: 2.4rem; */
  }
`;

const WrapperItemsSpecs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  .wrapperHeader {
    padding: 0 0.906rem;
  }
  > div > h5 {
    font-family: Unbounded;
    width: 15rem;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
  }
  @media screen and (max-width: ${breakPoints.laptop}) {
    > div > h5 {
      width: 15rem;
      font-family: Unbounded;
      font-weight: 400;
      font-size: 2rem;
      line-height: 3rem;
      text-align: center;
    }
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    > div > h5 {
      width: 10rem;
    }
  }
`;

const ListItemSpecs = styled.div`
  max-width: 100%;
  height: 100%;
  padding-left: 2.4rem;
  border-left: 1px solid #262626;
  .itemSpecs {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 2rem 0;
    > span,
    ul {
      font-family: Lexend;
      font-weight: 400;
      font-size: 2rem;
      line-height: 3rem;
      color: #727272;
      width: 80%;
    }
    > ul {
      list-style: none;
    }
    .infoSpecs {
      width: 12rem;
    }
  }
  @media screen and (max-width: ${breakPoints.laptop}) {
    padding: 0 1.6rem;
    .itemSpecs {
      display: flex;
      flex-direction: row;
      gap: 1.6rem;
      padding: 1.6rem 0;
      > span,
      ul {
        font-family: Lexend;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.72rem;
        color: #727272;
        /* width: 80%; */
        width: auto;
      }
      > ul {
        list-style: none;
      }
      .infoSpecs {
        /* width: 12rem; */
        width: auto;
      }
    }
  }
`;

const Button = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  > span {
    font-family: Unbounded;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
    color: #4292ff;
    cursor: pointer;
  }
  @media screen and (max-width: ${breakPoints.laptop}) {
    margin-top: 0;
    > span {
      font-family: Unbounded;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.24rem;
      color: #0047ff;
    }
  }
`;
