import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { breakPoints } from "../../constant/breakpoints";

export const ButtonCustomS = styled.button<{
  loading?: boolean;
  width?: string;
}>`
  position: relative;
  width: ${(props) => props.width || "auto"};

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Unbounded";
  font-size: 20px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #fafafa;

  padding: 1.8rem 3rem;

  white-space: nowrap;

  cursor: pointer;
  border: none;
  background-size: 150% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #0047ff;

  &:hover {
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background-color: #fafafa;
    color: #0047ff;
  }
  &:disabled,
  &.button--disabled {
    box-shadow: none;
    filter: contrast(0.5);
    cursor: not-allowed;
  }

  /* custom */
  &:active:not(:disabled):not(.button--disabled):not(.button--disabled) {
    opacity: 0.7;
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    padding: 0.3rem 2rem;
  }
`;
export function ButtonCustom(
  props: any & { loading?: boolean; width?: string }
) {
  const { t } = useTranslation();

  return (
    <ButtonCustomS {...props} width={props.width}>
      {props.loading ? t("Loading...") : props.children}
    </ButtonCustomS>
  );
}
