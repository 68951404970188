import React from "react";
import { breakPoints } from "../../constant/breakpoints";
import { styled } from "styled-components";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";

interface IProps {
  subTitle: string;
  title: string;
}
const Title = ({ subTitle, title }: IProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="wrapper">
        <span>{t(subTitle)}</span>
        <h2>{t(title)}</h2>
      </div>
    </Container>
  );
};

export default Title;
const Container = styled.div`
  display: flex;
  gap: 8rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12rem;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: unset;
  }
  div > span {
    color: var(--Neutral-Black, #000);

    /* Body/2 */
    font-family: "Lexend";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 4.32rem */
  }
  div > h2 {
    color: var(--Neutral-Black, #000);

    //styleName: new1.1/Heading/h3;
    font-family: "Unbounded";
    font-size: 6.4rem;
    font-weight: 400;
    line-height: 8rem;
    letter-spacing: 0em;
  }
  > div {
    display: flex;
    padding: 0 5rem;
    width: 100%;
    gap: 2rem;
    justify-content: center;
    @media screen and (max-width: ${breakPoints.mDesktop}) {
      flex-wrap: wrap;
      padding: 0 2.4rem;
    }
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    margin-bottom: 4rem;
    gap: 4rem;
    .wrapper {
      gap: 1rem;
    }
    div > h2 {
      //styleName: new1.1/Heading/h5;
      font-family: "Unbounded";
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
    }
    div > span {
      font-family: "Unbounded";
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
`;
