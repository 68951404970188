import { useState, useEffect } from "react";
interface IWindowDimensions {
  width: number;
  height: number;
  isIpad?: boolean;
  isMobile?: boolean;
}
export default function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : 0;
    const height = hasWindow ? window.innerHeight : 0;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    if (hasWindow) {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  const isIpad = windowDimensions.width <= 1024;
  const isMobile = windowDimensions.width <= 768;
  windowDimensions.isIpad = isIpad;
  windowDimensions.isMobile = isMobile;

  return windowDimensions;
}
