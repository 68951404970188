import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import Twinning from "../assets/images/partners/twinning.png";
import Telkomsel from "../assets/images/partners/telkomsel.png";
import Eqbr from "../assets/images/partners/eqbr.png";
import Singtel from "../assets/images/partners/singtel.png";
import Redision from "../assets/images/partners/redision.png";
import Vng from "../assets/images/partners/vng.png";
import TelkomselMobile from "../assets/images/partners/telkomsel-mobile.png";
import { breakPoints } from "../../../constant/breakpoints";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import ScrollAnimation from "react-animate-on-scroll";

import image1 from "../assets/images/partners/1.png";
import image2 from "../assets/images/partners/2.png";
import image3 from "../assets/images/partners/3.png";
import image4 from "../assets/images/partners/4.png";
import image5 from "../assets/images/partners/5.png";


const ListPartner = () => {
  const { t } = useTranslation();
  const [play, setPlay] = useState<boolean>(true);
  const speedAnimate = 20000;
  const windowDimensions = useWindowDimensions();
  const isIpad = windowDimensions.width <= 768;
  const listItem = [
    {
      icon: image1,
    },
    {
      icon: image2,
    },
    {
      icon: image3,
    },
    {
      icon: image4,
    },
    {
      icon: image5,
    }
  ];
  return (
    <SectionContainer>
      <ScrollAnimation animateIn="fadeIn" animateOnce duration={0.85}>
        <Title>
          <span>{t("Our Strategic")}</span>
          <h3>{t("Partners")}</h3>
        </Title>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animateOnce duration={0.85}>
        <Container
          onMouseEnter={() => setPlay(false)}
          onMouseLeave={() => setPlay(true)}
        >
          {isIpad ? (
            <Section $speed={speedAnimate} $play={play}>
              {listItem.map((item, index) => (
                <Item key={index}>
                  <Image src={item.icon}></Image>
                </Item>
              ))}
            </Section>
          ) : (
            <>
              <Section $speed={speedAnimate} $play={play}>
                {listItem.map((item, index) => (
                  <Item key={index}>
                    <Image src={item.icon}></Image>
                  </Item>
                ))}
              </Section>
              <Section $speed={speedAnimate} $play={play}>
                {listItem.map((item, index) => (
                  <Item key={index}>
                    <Image src={item.icon}></Image>
                  </Item>
                ))}
              </Section>
              <Section $speed={speedAnimate} $play={play}>
                {listItem.map((item, index) => (
                  <Item key={index}>
                    <Image src={item.icon}></Image>
                  </Item>
                ))}
              </Section>
            </>
          )}
        </Container>
      </ScrollAnimation>
    </SectionContainer>
  );
};

export default ListPartner;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  gap: 5.1rem;
  @media screen and (max-width: ${breakPoints.ipad}) {
    gap: 2.4rem;
    padding: 0 2.35rem;
  }
`;

const Title = styled.div`
  width: 100%;
  animation: fadeInBottom 1s;
  @keyframes fadeInBottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
    }
  }
  > span {
    font-family: Lexend;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 4.32rem;
    text-align: center;
    color: #1f1f1f;
  }
  > h3 {
    font-family: Unbounded;
    font-weight: 400;
    font-size: 6.4rem;
    line-height: 8rem;
    color: #1f1f1f;
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    > span {
      font-family: Unbounded;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.24rem;
      color: #262626;
    }
    > h3 {
      font-family: Unbounded;
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 3.2rem;
      color: #262626;
    }
  }
`;

const Section = styled.section<{ $speed: number; $play: boolean }>`
  display: flex;
  gap: 2.4rem;
  animation: swipe ${(props) => props.$speed}ms linear infinite backwards;
  animation-play-state: ${(props) => (props.$play ? "running" : "paused")};
  @keyframes swipe {
    0% {
      transform: translate(0);
    }
    100% {
      transform: translate(-100%);
    }
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    animation: none;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 2.8rem;
  padding: 2rem 0;
  animation: fadeInBottom 1s;
  @keyframes fadeInBottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
    }
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    gap: 1.489rem;
    overflow: unset;
  }
`;

const Item = styled.div`
  border-radius: 1.2rem;
  padding: 2.4rem 8rem;
  background: linear-gradient(#0047ff, #65a8fb);
  font-family: Unbounded;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3rem;
  text-align: center;
  color: #ffffff;
  display: flex;
  &:hover {
    transition: transform 300ms;
    cursor: pointer;
    transform: scale(1.1);
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    padding: 0.624rem 1.782rem;
    border-radius: 0.267rem;
  }
`;

const Image = styled.img`
  @media screen and (max-width: ${breakPoints.ipad}) {
    width: 5.32rem;
    height: auto;
    object-fit: scale-down;
  }
`;
