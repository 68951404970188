export const breakPoints = {
  xDesktop: "1920px",
  lgDesktop: "1530px",
  desktop: "1440px",
  mDesktop: "1280px",
  laptop: "1024px",
  lgTablet: "962px",
  mdTablet: "820px",
  ipad: "768px",
  mobile: "640px",
  sMobile: "430px",
  xsMobile: "320px",
};
