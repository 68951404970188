import React from "react";

const MinusIcon = () => {
  return (
    <svg
      width="46"
      height="47"
      viewBox="0 0 46 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.4173 25.0384H9.58398V21.2051H36.4173V25.0384Z"
        fill="#262626"
      />
    </svg>
  );
};

export default MinusIcon;
