import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet } from "react-router-dom";
import UserApi from "../api/user";
import useCart from "../hooks/use-cart";

interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface AuthContextType {
  isLogin: boolean;
  accessToken: string | null;
  user: {
    avatar?: string;
    username: string;
    email: string;
    fullname: string;
    id: number;
    provider: string;
    confirmed: boolean;
    blocked: boolean;
    createdAt: boolean;
    updatedAt: boolean;
  } | null;
  setUser: React.Dispatch<any>;
  login: (data: any) => void;
  logout: () => void;
  setIsLogin?: React.Dispatch<boolean>;
}

// Create the context
const AuthContext = createContext<AuthContextType>({
  /*eslint-disable */
  isLogin: false,
  accessToken: null,
  user: null,
  setUser: () => {},
  login: () => {},
  logout: () => {},
  setIsLogin: () => {},
  /*eslint-disable */
});

// Custom Provider component
export const AuthContextProvider = (props: Props) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [user, setUser] = useState<any>();
  // const [isLogin, setIsLogin] = useState<boolean>(!!accessToken);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const { createNewCart } = useCart();

  const logoutHandler = useCallback(() => {
    setAccessToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    setIsLogin(false);
  }, []);

  const loginHandler = ({
    accessToken,
    user,
  }: {
    accessToken: string;
    user: any;
  }) => {
    setAccessToken(accessToken);
    localStorage.setItem("token", accessToken);
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
    setIsLogin(true);
    if (user.cart) {
      createNewCart({
        items: user.cart.items,
        totalPrice: user.cart.totalPrice,
        refCode: user.cart.refCode,
        refCodeExpiredAt: user.cart.refCodeExpiredAt,
      });
    }
  };
  const fetchProfile = async () => {
    const res = await UserApi.getProfile();
    if (res) {
      setUser(res);
      if (res?.cart) {
        createNewCart({
          items: res.cart.items,
          totalPrice: res.cart.totalPrice,
          refCode: res.cart.refCode,
          refCodeExpiredAt: res.cart.refCodeExpiredAt,
        });
      }
    }
  };

  useEffect(() => {
    if (accessToken) {
      fetchProfile();
    }
  }, [accessToken]);

  return (
    <AuthContext.Provider
      value={{
        isLogin,
        user,
        accessToken,
        setUser,
        login: loginHandler,
        logout: logoutHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useInfoUser = () => useContext(AuthContext);

export const AuthOutlet = (props: any) => {
  return (
    <AuthContextProvider>
      {props.children ? props.children : <Outlet />}
    </AuthContextProvider>
  );
};
export default AuthContext;
