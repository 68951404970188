// useRequest.ts
import { useState, useEffect } from 'react';
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

interface UseRequestProps<T> {
    url: string;
    method?: 'get' | 'post' | 'put' | 'delete'; // Add other HTTP methods as needed
    data?: T;
    onSuccess?: (data: T) => void;
}

interface UseRequestResult<T> {
    data: T | null;
    error: string | null;
    loading: boolean;
    doRequest: (data: T) => void;
    onSuccess?: (data: T) => void;
}

const useRequest = <T>({ url, method = 'get', onSuccess }: UseRequestProps<T>): UseRequestResult<T> => {
    const [dataState, setData] = useState<T | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchData = async (data?: T) => {
        setError(null);
        try {
            const config: AxiosRequestConfig = {
                method,
                url,
                data,
            };

            setLoading(true);
            const response: AxiosResponse<T> = await axios(config);
            setData(response.data);
            // Call onSuccess callback if provided
            if (onSuccess && method !== 'get') {
                onSuccess(response.data);
            }
        } catch (err: any) {
            setError(err?.response?.data?.message as string);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (method === 'get') {
            fetchData(); // Automatically fetch on mount
        }
    }, [url, method]);

    return { data: dataState, error, loading, doRequest: fetchData };
};

export default useRequest;
