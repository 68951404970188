import { styled } from "styled-components";

function TermOfUsePolicy() {
  return (
    <Container>
      <TitleHeader>{"Terms of Use"}</TitleHeader>

      <div>
        <Title style={{ fontSize: "2rem", marginBottom: "1rem" }}>
          {"Last Updated: January 2024"}
        </Title>
        <Description>
          {`These Terms of Use ("Terms") govern your access to and use of the website, services,
            and any content provided by POOLS Phone ("POOLS Phone," "we," or
            "us"). You agree to be bound by these Terms by accessing or using POOLS Phone.
          `}
        </Description>
      </div>

      <Content>
        <Title>{`1. Acceptance of Terms:`}</Title>
        <Description>
          {`By using POOLS Phone, you acknowledge that you have read, understood, and
            agreed to be bound by these Terms. If you do not agree to these Terms, do not
            access or use the POOLS Phone website or services.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`2. Registration:`}</Title>
        <Description>
          {`To access certain features on POOLS Phone, you may be required to create an
            account. You are responsible for providing accurate and complete information
            during the registration process. You must also choose a strong and secure password
            that you will keep confidential. Any unauthorized use of your account must be
            reported immediately to POOLS Phone
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`3. User Conduct:`}</Title>
        <Description>
          {`You agree to use POOLS Phone solely for lawful purposes and in a manner consistent
            with these Terms. You shall not use the platform to transmit or post any material that
            is false, defamatory, abusive, obscene, offensive, or otherwise objectionable. You shall
            not engage in any activities that may interfere with or disrupt the functionality of
            POOLS Phone or other users' ability to use the platform.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`4. Intellectual Property:`}</Title>
        <Description>
          {`All content on POOLS Phone, including but not limited to text, graphics, logos,
            images, audio clips, videos, and software, is owned or licensed by POOLS Phone and
            is subject to copyright and other intellectual property rights. You may not modify,
            reproduce, distribute, or use any content from POOLS Phone without prior written
            permission from POOLS Phone.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`5. Third-Party Content:`}</Title>
        <Description>
          {`POOLS Phone may contain links to third-party websites or resources that are not
            owned or controlled by POOLS Phone. We do not endorse or assume any
            responsibility for the content or availability of these third-party websites. Your
            interactions with any third-party websites are solely between you and such third
            parties
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`6. Limitation of Liability:`}</Title>
        <Description>
          {`POOLS Phone and its affiliates, officers, directors, employees, agents, or suppliers
            shall not be liable for any indirect, incidental, special, or consequential damages
            arising from or in connection with your use of POOLS Phone or its services. You agree
            to indemnify and hold POOLS Phone harmless from any claims, damages, or losses
            incurred as a result of your violation of these Terms or any applicable laws.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`7. Termination:`}</Title>
        <Description>
          {`POOLS Phone may, in its sole discretion, suspend or terminate your access to the
            platform at any time without notice or liability. Upon termination, your right to use
            POOLS Phone will immediately cease, and you shall destroy any copies of any
            content obtained from the platform.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`8. Changes to Terms:`}</Title>
        <Description>
          {`POOLS Phone may revise these Terms at any time by updating this page. It is your
            responsibility to review these Terms periodically. Your continued use of POOLS
            Phone after any changes to the Terms signifies your acceptance of those changes.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`9. Governing Law:`}</Title>
        <Description>
          {`These Terms shall be governed by and construed following the laws of Vietnam. Any
            disputes arising under these Terms shall be subject to the exclusive jurisdiction of
            the courts located in Vietnam.
          `}
          <br />
          <br />
          {`If you have any further questions or concerns regarding our Terms of Use, please
          don't hesitate to reach out to our support team: `}
          <a href="mailTo: contact@poolsclub.com">contact@poolsclub.com</a>
        </Description>
      </Content>
    </Container>
  );
}

export default TermOfUsePolicy;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
  max-width: 102.4rem;
  margin: 0 auto;
  padding: 6rem;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  min-height: 100vh;
  color: #434343;

  @media screen and (max-width: 500px) {
    padding: 0.3rem 2rem;
    padding-top: 6rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`;

const TitleHeader = styled.h1`
  font-family: Archivo;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 3rem;
  }
`;

const Title = styled.h2`
  font-family: Archivo;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-family: Archivo;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;
