import React from "react";

const PlusIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.084 12.2217V0.72168H15.9173V12.2217H27.4173V16.055H15.9173V27.555H12.084V16.055H0.583984V12.2217H12.084Z"
        fill="#262626"
      />
    </svg>
  );
};

export default PlusIcon;
