import * as React from "react";
import styled from "styled-components";
import { MainLayoutContainer } from "../../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import Poster from "../assets/images/video-poster.png";
import PosterMobile from "../assets/images/video-poster-mobile.png";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { breakPoints } from "../../../constant/breakpoints";
import ScrollAnimation from "react-animate-on-scroll";

const videoDesktop =
  "https://daziu008xh8wa.cloudfront.net/materials-lib/videos/1707208742420.mp4";
const videoMobile =
  "https://daziu008xh8wa.cloudfront.net/materials-lib/videos/1707208772171.mp4";

function OwnTheFuture() {
  const { t } = useTranslation();
  const [isPlay, setIsplay] = React.useState<boolean>(false);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const { isMobile } = useWindowDimensions();

  return (
    <section id="own-the-future">
      <Container>
        <Heading>
          <ScrollAnimation animateIn="fadeIn">
            <Title>{t("Own The Future")}</Title>
            <SubTitle>{t("Power Of Phone-to-Earn")}</SubTitle>
          </ScrollAnimation>
        </Heading>
        <ScrollAnimation animateIn="fadeInUp">
          <VideoContainer>
            <VideoWrapper>
              <video
                onPause={() => setIsplay(false)}
                onPlay={() => setIsplay(true)}
                ref={videoRef}
                src={isMobile ? videoMobile : videoDesktop}
                poster={isMobile ? PosterMobile : Poster}
                controls={isPlay}
              />
              <VideoControl $display={!isPlay}>
                <Button
                  onClick={() => {
                    if (videoRef.current) {
                      videoRef.current.play();
                    }
                  }}
                >
                  <p>{t("Play Trailer")}</p>
                </Button>
              </VideoControl>
            </VideoWrapper>
          </VideoContainer>
        </ScrollAnimation>
      </Container>
    </section>
  );
}

export default OwnTheFuture;

const Container = styled(MainLayoutContainer)`
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  @media (min-width: ${breakPoints.ipad}) {
    row-gap: 4rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 6rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
    row-gap: 8rem;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 0.4rem;
  @media (min-width: ${breakPoints.mobile}) {
    row-gap: 0.8rem;
  }
  @media (min-width: ${breakPoints.ipad}) {
    row-gap: 1.6rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 2rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
    row-gap: 2.4rem;
  }
`;

const Title = styled.h1`
  color: #262626;
  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.16;
  @media (min-width: ${breakPoints.mobile}) {
    font-size: 48px;
  }
  @media (min-width: ${breakPoints.ipad}) {
    font-size: 60px;
  }
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 80px;
  }
  @media (min-width: ${breakPoints.desktop}) {
    font-size: 96px;
  }
`;

const SubTitle = styled.h5`
  color: #262626;
  font-family: Unbounded;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.33;
  @media (min-width: ${breakPoints.mobile}) {
    font-size: 16px;
  }
  @media (min-width: ${breakPoints.ipad}) {
    font-size: 20px;
  }
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 24px;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  :hover {
    transition: all linear 300ms;
    transform: scale(1.01);
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  max-width: 1530px;
  height: auto;
  border-radius: 1.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.71);
  background: #101010;
  box-shadow: 0px 12px 48px 0px rgba(0, 71, 255, 0.22);
  overflow: hidden;
  > video {
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
    object-fit: fill;
  }
  @media (min-width: ${breakPoints.ipad}) {
    font-size: 20px;
    > video {
      aspect-ratio: unset;
    }
  }
  @media (min-width: ${breakPoints.laptop}) {
    border-radius: 59.303px;
    border-width: 4.942px;
  }
`;

const VideoControl = styled.div<{ $display: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: ${(props) => (props.$display ? "flex" : "none")};
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  padding: 0.8rem 1.2rem;
  border-radius: 12rem;
  background-color: #0047ff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  column-gap: 1rem;
  border: none;
  cursor: pointer;
  transition: all ease 300ms;
  &:active {
    opacity: 0.8;
    transform: scale(0.9);
  }
  &:hover {
    transform: scale(1.1);
  }
  > p {
    color: #fafafa;
    font-family: Unbounded;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media (min-width: ${breakPoints.ipad}) {
    padding: 1.2rem 4rem;
    > p {
      font-size: 32px;
    }
  }
  @media (min-width: ${breakPoints.laptop}) {
    > p {
      font-size: 41.55px;
    }
  }
`;
