import { styled } from "styled-components";

function PrivacyPolicy() {
  return (
    <Container>
      <TitleHeader>{"Privacy Policy"}</TitleHeader>

      <div>
        <Title style={{ fontSize: "2rem", marginBottom: "1rem" }}>
          {"Effective Date: January 2024"}
        </Title>
        <Description>
          {`Thank you for choosing POOLS Phone. Your privacy is important to us,
          and we are dedicated to safeguarding your personal data. This Privacy Policy outlines
          how we collect, use, and disclose your information. Please carefully review the details
          below, which apply to our website, products, and services.
          `}
        </Description>
      </div>

      <Content>
        <Title>{`1. Collection and Use of Personal Data`}</Title>
        <Description>
          {`We collect personal data provided by you during your interaction with our website,
          products, or services. This may include, but is not limited to, your name, gender,
          enterprise name, job position, postal and email addresses, phone number, login
          information, photos, and certificate information. We collect this information for
          various purposes, such as creating an account, fulfilling transactions, providing
          services, contacting you, sending product/service information, offering technical
          support, improving our offerings, and complying with legal requirements.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`2. Cookies & Similar Technologies`}</Title>
        <Description>
          {`We utilize cookies and similar technologies to enhance user experience and
            customize content on our website. Cookies are small text files stored on your
            computer or mobile device, enabling websites to remember your preferences.
            Additionally, we may use web beacons and pixel tags to track visits and gather
            statistical data. By using our website, you consent to the use of cookies and similar
            technologies.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`3. Disclosure of Personal Data`}</Title>
        <Description>
          {`We may share your personal data with authorized partners to provide services.
            Additionally, we may disclose your information to law enforcement or government
            agencies to comply with applicable laws. In certain circumstances, such as
            restructuring or mergers, your personal data may be disclosed. We may also disclose
            data to protect our rights, prevent illegal activity, or fulfill legal obligations.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`4. Access & Control of Personal Data`}</Title>
        <Description>
          {`You have the right to access, update, correct, or delete your personal data. You can
            also withdraw your consent for data processing. To exercise these rights, please
            contact us, and we will verify your identity before processing your request.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`5. Protection and Retention of Personal Data`}</Title>
        <Description>
          {`We employ physical, technical, and managerial measures to protect your personal
            data from unauthorized access, use, modification, or loss. We retain your information
            for as long as necessary to fulfill the purposes stated in this Privacy Policy.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`6. Processing Children's Personal Data`}</Title>
        <Description>
          {`Our website, products, and services are not intended for children. If we collect
            personal data from a child with parental consent, we will only use or disclose the
            data as permitted by law.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`7. Third-Party Providers and Services`}</Title>
        <Description>
          {`Our website may contain links to third-party websites and services. We are not
            responsible for their privacy practices and data protection policies. Please review the
            privacy policies of third parties before providing them with your personal data.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`8. International Transfers of Personal Data`}</Title>
        <Description>
          {`We may process or access your personal data in countries/regions with different data
            protection laws. We will take measures to ensure compliance with this Privacy Policy
            and applicable laws.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`9. Updates to This Policy`}</Title>
        <Description>
          {`We may update or change this Privacy Policy at any time. The latest version will be
            posted on our website. Major changes may be communicated through different
            channels.
          `}
          <br />
          <br />
          {`If you have questions or concerns about our Privacy Policy, please reach out to our
          support team: `}
          <a href="mailTo: contact@poolsclub.com">contact@poolsclub.com</a>
        </Description>
      </Content>
    </Container>
  );
}

export default PrivacyPolicy;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
  max-width: 102.4rem;
  margin: 0 auto;
  padding: 6rem;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  min-height: 100vh;
  color: #434343;

  @media screen and (max-width: 500px) {
    padding: 0.3rem 2rem;
    padding-top: 6rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`;

const TitleHeader = styled.h1`
  font-family: Archivo;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 3rem;
  }
`;

const Title = styled.h2`
  font-family: Archivo;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-family: Archivo;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;
