import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakPoints } from "../../../../constant/breakpoints";

interface IProps {
  title: string;
  sub: string;
}
const HeroFeature = ({ title, sub }: IProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <h3>{t(title)}</h3>
      <p>{t(sub)}</p>
    </Container>
  );
};

export default HeroFeature;
const Container = styled.div`
  position: absolute;
  top: 0;
  border-radius: 18px;
  background: rgba(251, 251, 255, 0.58);
  box-shadow: 0px -9.775px 12.49px 0px #bdcaec inset,
    0px 4.344px 41.054px 0px #fff inset,
    27.152px 36.926px 64.404px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15.600000381469727px);
  padding: 1.2rem;
  width: fit-content;
  transition: all ease 0.3s;
  &:hover {
    transform: scale(1.1);
  }

  > h3 {
    font-family: "Unbounded";
    font-size: 54px;
    font-weight: 600;
    line-height: 67px;
    letter-spacing: 0em;
    text-align: center;
    color: #0047ff;
    white-space: nowrap;
  }
  > p {
    font-family: "Lexend";
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #143079;
    white-space: nowrap;
  }
  @media screen and (max-width: ${breakPoints.desktop}) {
    > h3 {
      font-size: 36px;
      line-height: 1;
    }
    > p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: ${breakPoints.mdTablet}) {
    padding: 0.5rem 1rem;

    > h3 {
      font-size: 10px;
      line-height: 1;
    }
    > p {
      font-size: 6px;
      line-height: 1;
    }
  }
`;
