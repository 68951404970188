import React from "react";
import { styled } from "styled-components";
import Title from "../../../../components/Title/Title";
import Questions from "./Questions";
import SupportForm from "./SupportForm";
import { MainLayoutContainer } from "../../../../components/layout/Layout";
import { breakPoints } from "../../../../constant/breakpoints";

const Support = () => {
  return (
    <Container>
      <Title subTitle="Phone" title="SUPPORT" />
      <Content>
        <Questions />
        <SupportForm />
      </Content>
    </Container>
  );
};

export default Support;

const Container = styled(MainLayoutContainer)``;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5%;
  @media screen and (max-width: ${breakPoints.ipad}) {
    flex-direction: column;
    gap: 4rem;
  }
`;
