import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import CorporatePartnership from "../../../home-backup/component/contact/images/CorporatePartnership.png";
import { ButtonGradient } from "../../../../components/ButtonGradient";
import ArrowIcon from "../../../../components/layout/icons/ArrowIcon";
import Facebook from "./images/facebook.png";
import Twitter from "./images/twitter.png";
import Instagram from "./images/skill-icons_instagram.png";
import Tiktok from "./images/tiktok.png";
import Discord from "./images/discord.png";
import Telegram from "./images/telegram.png";
import Youtube from "./images/youtube.png";
import Banner from "./images/ourCommunity.png";
import { breakPoints } from "../../../../constant/breakpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import ScrollAnimation from "react-animate-on-scroll";

export const JoinUs = () => {
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();
  const isIpad = windowDimensions.width <= 820;
  const slideData = [
    {
      urlImg: CorporatePartnership,
      title: "We Care Ear Health",
    },
    {
      urlImg: CorporatePartnership,
      title: "AI EARS Friends",
    },
    {
      urlImg: CorporatePartnership,
      title: "Our Team",
    },
  ];

  const listSocial = [
    {
      name: "Facebook",
      icon: Facebook,
      link: "https://www.facebook.com/bpoolsclub",
    },
    {
      name: "Instagram",
      icon: Instagram,
      link: "https://www.instagram.com/poolclubs123",
    },
    {
      name: "Twitter",
      icon: Twitter,
      link: "https://twitter.com/pools_club",
    },
    {
      name: "Youtube",
      icon: Youtube,
      link: "https://www.youtube.com/@poolscontact",
    },
    {
      name: "Tiktok",
      icon: Tiktok,
      link: "https://www.tiktok.com/@poolsclubvn",
    },
    // {
    //   name: "Discord",
    //   icon: Discord,
    //   link: "https://www.tiktok.com/@aiears",
    // },
    {
      name: "Telegram",
      icon: Telegram,
      link: "https://t.me/Poolsclub",
    },
  ];

  return (
    <JoinUsContainer>
      <div className="wrapper">
        {!isIpad && <span>{t("Official Channels")}</span>}
        <h2>{t("Join Us")}</h2>
      </div>
      <div>
        {listSocial.map((social) => (
          <SocialItem href={social.link} target="_blank">
            <div>
              <img src={social.icon} alt="social icon"></img>
            </div>
            <h6>{t(social.name)}</h6>
          </SocialItem>
        ))}
      </div>
    </JoinUsContainer>
  );
};

const AboutCommunity = () => {
  const { t } = useTranslation();

  const analyzeData = [
    {
      approach: "Approaching",
      amount: "30+",
      description: "Channel distribution countries",
    },
    {
      approach: "Approaching",
      amount: "1800+",
      description: "Sales Points",
    },
    {
      approach: "Approaching",
      amount: "100+",
      description: "Partner channels",
    },
  ];

  return (
    <>
      <HeaderExplore>
        <span>{t("EXPLORE")}</span>
        <h3>{t("Our Community")}</h3>
      </HeaderExplore>
      <Container>
        <div className="imgBlack">
          <BannerCommuniy src={Banner}></BannerCommuniy>
        </div>
        <Introduce>
          <h3>
            {t(
              "A vibrant hub where audiophiles, musicians, and tech enthusiasts converge to discuss and innovate at the intersection of sound artistry and technology."
            )}
          </h3>
          <div>
            <p>
              {t(
                "Welcome to the AI EARS Community! Step into a realm where audiophiles and AI aficionados converge to envision and shape the future of AI-enhanced listening. Let every inquiry ignite our collective imagination, and may each shared story propel us towards the zenith of auditory and AI integration, fostering a vibrant space for exploration and innovation."
              )}
            </p>
            <span>
              {t("(New Community for AI EARS Coming Soon - Stay Tuned!)")}
            </span>
          </div>
        </Introduce>
        {/* <CardWrapper>
          <Swiper
            spaceBetween={20}
            modules={[Navigation]}
            slidesPerView={1.2}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              820: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            style={{
              //@ts-ignore
              "--swiper-pagination-color": "#000",
              display: "flex",
              gap: "2.1rem",
              padding: "0 5rem",
            }}
          >
            {slideData.map((slide, index) => (
              <SwiperSlide key={index}>
                <CardItem>
                  <img src={slide.urlImg} alt={slide.title}></img>
                  <span>{slide.title}</span>
                  <CustomButton>
                    {t("Learn More")}
                    <ArrowIcon />
                  </CustomButton>
                </CardItem>
              </SwiperSlide>
            ))}
          </Swiper>
        </CardWrapper> */}
        <Partner>
          <h2>{t("We have partners all over the world")}</h2>
          <div>
            {analyzeData.map((analyze, index) => (
              <AnalyzePartner
                key={index}
                animateIn="fadeInDown"
                animateOnce
                duration={0.85}
                delay={index * 50}
              >
                <p>{t(analyze.approach)}</p>
                <h3>{t(analyze.amount)}</h3>
                <span>{t(analyze.description)}</span>
              </AnalyzePartner>
            ))}
          </div>
        </Partner>
        <JoinUs />
      </Container>
    </>
  );
};

export default AboutCommunity;

const HeaderExplore = styled.div`
  margin-top: 10rem;
  margin-bottom: 8rem;
  padding-left: 5rem;
  h3 {
    color: var(--Neutral-Black, #000);

    /* Heading/h3 */
    font-family: "Big Shoulders Display";
    font-size: 6.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 8rem; /* 125% */
  }
  span {
    color: var(--Neutral-Black, #000);

    /* Body/2 */
    font-family: Archivo;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 4.32rem */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12rem;
  @media screen and (max-width: ${breakPoints.ipad}) {
    gap: 4rem;
  }
`;

const Introduce = styled.div`
  padding: 0 5rem;
  display: flex;
  gap: 1.6rem;
  @media screen and (max-width: ${breakPoints.laptop}) {
    flex-direction: column;
  }
  h3 {
    color: var(--Grey-9, #262626);
    /* Heading/h3 */
    font-family: "Big Shoulders Display";
    font-size: 6.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 8rem; /* 125% */
    width: 80.3rem;
    @media screen and (max-width: ${breakPoints.laptop}) {
      width: 100%;
    }
    @media screen and (max-width: ${breakPoints.ipad}) {
      color: var(--Grey-10, #1f1f1f);
      /* Body/Mobile/Heading/h3 */
      font-family: "Big Shoulders Display";
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 4rem; /* 125% */
    }
  }
  div {
    width: 100.1rem;
    @media screen and (max-width: ${breakPoints.laptop}) {
      width: 100%;
    }
  }
  p {
    color: var(--Grey-9, #262626);
    /* Body/2 */
    font-family: Archivo;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 4.32rem */
    margin-bottom: 5rem;
    @media screen and (max-width: ${breakPoints.ipad}) {
      color: var(--Grey-7, var(--Neutral-Grey-7, #727272));
      /* Body/4 */
      font-family: Archivo;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 170%; /* 2.72rem */
    }
  }
  span {
    color: var(--Grey-9, #262626);
    font-family: Archivo;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    @media screen and (max-width: ${breakPoints.ipad}) {
      color: var(--Grey-7, var(--Neutral-Grey-7, #727272));
      font-family: Archivo;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 170%;
    }
  }
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 2.1rem;
  padding: 0 5rem;
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  // width: calc(100% / 3);
  span {
    color: var(--Grey-9, #262626);
    font-family: Archivo;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 180%; /* 5.76rem */
  }
`;

const Partner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8rem;
  text-center: center;
  h2 {
    color: var(--Neutral-Black, #000);

    text-align: center;
    /* Heading/h1 */
    font-family: "Big Shoulders Display";
    font-size: 12rem;
    font-style: normal;
    font-weight: 400;
    line-height: 14.5rem; /* 120.833% */
    @media screen and (max-width: ${breakPoints.ipad}) {
      color: var(--Grey-10, #1f1f1f);
      text-align: center;

      /* Body/Mobile/Heading/h2 */
      font-family: "Big Shoulders Display";
      font-size: 4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 5rem; /* 125% */
    }
  }
  > div {
    display: flex;
    padding: 0 20rem;
    justify-content: space-evenly;
    @media screen and (max-width: ${breakPoints.mDesktop}) {
      flex-direction: column;
      gap: 1.6rem;
      align-items: center;
      padding: 0 0;
    }
  }
`;

const AnalyzePartner = styled(ScrollAnimation)`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  > h3 {
    transition: all ease 300ms;
    &:hover {
      transform: scale(1.1);
    }
  }
  h3 {
    color: var(--Primary-brown, #b27a48);
    text-align: center;
    font-family: "Big Shoulders Display";
    font-size: 12rem;
    font-style: normal;
    font-weight: 700;
    line-height: 14.5rem; /* 120.833% */
  }
  span {
    color: var(--Grey-9, #262626);
    text-align: center;
    /* Body/2 */
    font-family: Archivo;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 4.32rem */
    whitespace: no-wrap;
  }
  p {
    color: var(--Grey-7, var(--Neutral-Grey-7, #727272));
    text-align: center;

    /* Body/2 */
    font-family: Archivo;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 4.32rem */
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    h3 {
      color: var(--Primary-brown, #b27a48);
      text-align: center;
      font-family: "Big Shoulders Display";
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 5rem; /* 125% */
    }
    span {
      color: var(--Grey-9, #262626);
      text-align: center;

      /* Body/4 */
      font-family: Archivo;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 170%; /* 2.72rem */
    }
    p {
      color: var(--Grey-7, var(--Neutral-Grey-7, #727272));
      text-align: center;
      /* Body/4 */
      font-family: Archivo;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 170%; /* 2.72rem */
    }
  }
`;

const JoinUsContainer = styled.div`
  display: flex;
  gap: 8rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12rem;
  .wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: unset;
  }
  div > span {
    color: var(--Neutral-Black, #000);

    /* Body/2 */
    font-family: "Lexend";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 4.32rem */
  }
  div > h2 {
    color: var(--Neutral-Black, #000);

    //styleName: new1.1/Heading/h3;
    font-family: "Unbounded";
    font-size: 6.4rem;
    font-weight: 400;
    line-height: 8rem;
    letter-spacing: 0em;
  }
  > div {
    display: flex;
    padding: 0 5rem;
    width: 100%;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: ${breakPoints.mDesktop}) {
      padding: 0 2.4rem;
    }
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    margin-bottom: 4rem;
    gap: 4rem;
    div > h2 {
      color: var(--Grey-10, #1f1f1f);
      text-align: center;

      /* Body/Mobile/Heading/h2 */
      font-family: "Big Shoulders Display";
      font-size: 4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 5rem; /* 125% */
    }
  }
`;

const SocialItem = styled.a`
  display: flex;
  flex-direction: column;
  gap: 7rem;
  padding: 2rem;
  align-items: flex-start;
  border-radius: 1.2rem;
  border: 1px solid var(--Grey-5, #bfbfbf);
  width: 34.8rem;
  min-width: 5.4rem;
  transition: all ease 300ms;
  &:active {
    background-color: #727272;
    > h6 {
      color: #fff;
    }
  }
  img {
    width: 60%;
  }
  h6 {
    color: #727272;
    /* Heading/h4 */
    font-family: "Big Shoulders Display";
    font-size: 4.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 6rem; /* 125% */
  }
  @media (min-width: ${breakPoints.laptop}) {
    img {
      width: 100%;
    }
    &:hover {
      background-color: #727272;
      > h6 {
        color: #fff;
      }
    }
  }
  @media screen and (max-width: ${breakPoints.laptop}) {
    padding: 0.79rem 2.2rem 0.61rem 0.8rem;
    gap: 1.9rem;
    width: 8.4rem;
    h6 {
      color: var(--Grey-5, #bfbfbf);
      /* Body/Mobile/Heading/h6 */
      font-family: "Big Shoulders Display";
      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.6rem; /* 130% */
    }
  }
`;

const BannerCommuniy = styled.img`
  width: 100%;
  height: auto;
`;

const CustomButton = styled(ButtonGradient)`
  padding: 0.8rem 2.8rem;
  border-radius: 50rem;
  border: none;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-top: 4.8rem;
  > span {
    margin-right: 0.9rem;
    font-color: #000;
    font-family: Archivo;
    font-size: 2rem;
    font-weight: 400;
    line-height: 150%;
  }
`;
