import { styled } from "styled-components";

function PrivacyPolicyPoolsDashboard() {
  return (
    <Container>
      <TitleHeader>{"Privacy Policy for Pools Dashboard"}</TitleHeader>

      <div>
        <Title style={{ fontSize: "2rem", marginBottom: "1rem" }}>
          {"Effective Date: May 2024"}
        </Title>
      </div>

      <Content>
        <Title>{`Privacy Policy`}</Title>
        <Description>
          {`Pools Dashboard ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our application. Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the application.`}
        </Description>
      </Content>

      <Content>
        <Title>{`1. Information We Collect`}</Title>
        <Description>
          <b>Personal Information</b>
          <br /> <br />
          {`We may collect personal information, such as your name, email address, and contact information when you register for the app or use our services.
          `}
          <br />
          <br />
          <b>Usage Data</b>
          <br />
          <br />
          {`We collect information about how you use the app, including your preferences, Scratch usage, and interaction with app features.
          `}
          <br />
          <br />
          <b>Location Data</b>
          <br />
          <br />
          {`We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.
          `}
             <b>Device Data</b>
          <br />
          <br />
          {`We may collect information about your mobile device, including the device ID, model and manufacturer, operating system, and version.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`2. How We Use Your Information`}</Title>
        <Description>
          {`We use the information we collect in various ways, including to:
          `}
          <br />
          <br />
          {`Please note that the Application utilizes third-party services that have their own Privacy Policy about handling data. Below are the links to the Privacy Policy of the third-party service providers used by the Application:
          `}
          <br />
          <br />
          <ul>
            <li>
              <p>Provide, operate, and maintain our application.</p>
            </li>
            <li>
              <p>Improve, personalize, and expand our application.</p>
            </li>
            <li>
              <p>Understand and analyze how you use our application.</p>
            </li>
            <li>
              <p>Develop new products, services, features, and functionality.</p>
            </li>
            <li>
              <p>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the application, and for marketing and promotional purposes.</p>
            </li>
            <li>
              <p>Process your transactions and manage your orders.</p>
            </li>
            <li>
              <p>Send you text messages and push notifications.</p>
            </li>
            <li>
              <p>Find and prevent fraud.</p>
            </li>
            <li>
              <p>For compliance with legal obligations.</p>
            </li>
          </ul>
        </Description>
      </Content>

      <Content>
        <Title>{`3. Disclosure of Your Information`}</Title>
        <Description>
          {`We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
          `}
          <br />
          <br />
          <b>By Law or to Protect Rights</b>
          <br />
          <br />
          {`If we believe the release of information about you is necessary to respond to legal processes, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.
          `}
          <br />
          <br />
          <b>Third-Party Service Providers</b>
          <br />
          <br />
          {`We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
          `}
          <br />
          <br />
          <b>Business Transfers</b>
          <br />
          <br />
          {`We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`4. Data Security`}</Title>
        <Description>
          {`We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`5. Responsibility for the App`}</Title>
        <Description>
          {`Playground Planet Ltd. will be responsible for any issues or problems that occur with the app, ensuring prompt resolution and support for affected users.
          `}
        </Description>
      </Content>


      <Content>
        <Title>{`6. Your Privacy Rights`}</Title>
        <Description>
          {`Depending on your location, you may have certain rights regarding your personal information. These rights may include:
          `}
          <br/>
          <br/>
          <ul>
            <li>
              <p>The right to access your personal information.</p>
            </li>
            <li>
              <p>The right to request the correction or deletion of your personal information.</p>
            </li>
            <li>
              <p>The right to object to the processing of your personal information.</p>
            </li>
            <li>
              <p>The right to restrict the processing of your personal information.</p>
            </li>
            <li>
              <p>The right to data portability.</p>
            </li>
          </ul>
        </Description>
      </Content>

      <Content>
        <Title>{`7. Update Background Location Permissions Declaration`}</Title>
        <Description>
          {`Pools Dashboard requires background location permissions to provide location-based services, such as location-based suggestions and enhancements. This information is used to improve the user experience and is not shared with third parties for advertising purposes.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`8. Update All Files Access Permissions Declaration`}</Title>
        <Description>
          {`Pools Dashboard requires background location permissions to provide location-based services, such as location-based suggestions and enhancements. This information is used to improve the user experience and is not shared with third parties for advertising purposes.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`9. App Access Instructions (Functionality Restrictions)`}</Title>
        <Description>
          {`Certain functionalities of Pools Dashboard may be restricted based on user permissions and settings. Users can modify these settings to grant the necessary permissions to fully utilize all app features.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`10. News Apps Declaration`}</Title>
        <Description>
          {`Pools Dashboard does not classify as a news app. It does not publish or distribute news content but focuses on providing an experience where users can manage all their monetized rewards apps in one place with Pools Dashboard.
          `}
        </Description>
      </Content>


      <Content>
        <Title>{`11. Advertising ID Declaration`}</Title>
        <Description>
          {`Pools Dashboard uses the Advertising ID to provide personalized advertising experiences. This ID helps us deliver relevant ads based on your usage patterns and preferences. Users can opt-out of personalized ads through their device settings.
          `}
        </Description>
      </Content>

      
      <Content>
        <Title>{`12. Government Apps Declaration`}</Title>
        <Description>
          {`Pools Dashboard is not a government application and does not provide government services or functionality.
          `}
        </Description>
      </Content>

      
      <Content>
        <Title>{`13. Financial Features Declaration`}</Title>
        <Description>
          {`Pools Dashboard does not offer any financial services or features. It does not process financial transactions or store any financial information.
          `}
        </Description>
      </Content>

      
      <Content>
        <Title>{`14. Health Declaration`}</Title>
        <Description>
          {`Pools Dashboard does not collect or process health information. It does not integrate with any health or fitness tracking services.
          `}
        </Description>
      </Content>

      
      <Content>
        <Title>{`15. Changes to This Privacy Policy`}</Title>
        <Description>
          {`We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
          `}
        </Description>
      </Content>

      <Content>
        <Title>{`16. Contact Us`}</Title>
        <Description>
          {`If you have questions or concerns about our Privacy Policy, please reach out to our support team at `}
          <a href="mailTo: contact@poolsclub.com"> contact@poolsclub.com </a>
          <br/>
          <br/>
          {`By using Pools Dashboard, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
          `}
        </Description>
      </Content>
    </Container>
  );
}

export default PrivacyPolicyPoolsDashboard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
  max-width: 102.4rem;
  margin: 0 auto;
  padding: 6rem;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  min-height: 100vh;
  color: #434343;

  @media screen and (max-width: 500px) {
    padding: 0.3rem 2rem;
    padding-top: 6rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`;

const TitleHeader = styled.h1`
  font-family: Archivo;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 3rem;
  }
`;

const Title = styled.h2`
  font-family: Archivo;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-family: Archivo;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 320px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  > ul{
    margin-left: 40px;
  }
`;
