import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { breakPoints } from "../../../constant/breakpoints";
import { ButtonCustom } from "../../Button/ButtonCustom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import FooterBottom from "./FooterBottom";
import FooterBottomMobile from "./FooterBottomMobile";

function Footer() {
  const { t } = useTranslation();
  const { isIpad } = useWindowDimensions();

  return (
    <>
      <Container>
        <Content>
          <h2>{t("Become a retail")}</h2>
          {/* <p>
            {t(
              "Lorem ipsum dolor sit amet consectetur. Malesuada amet tristique egestas nulla neque."
            )}
          </p> */}
          <ButtonContainer>
            <ButtonCustom>
              {t("Be a retail")}
            </ButtonCustom>
          </ButtonContainer>
        </Content>
      </Container>
      {!isIpad ? <FooterBottom /> : <FooterBottomMobile />}
    </>
  );
}

export default Footer;

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 6rem 20.8rem 4rem 20.8rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 6rem;
  border-radius: 12rem 12rem 0rem 0rem;
  background: #121212;
  @media screen and (max-width: ${breakPoints.mDesktop}) {
    padding: 6rem 15.2rem 4rem 15.2rem;
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    padding: 6rem 3.2rem 4rem 3.2rem;
    border-radius: 5rem 5rem 0rem 0rem;
    gap: 3.2rem;
  }
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  color: #fff;
  > h2 {
    //styleName: new1.1/Heading/h4;
    font-family: "Unbounded";
    font-size: 4.8rem;
    font-weight: 400;
    line-height: 6rem;
    letter-spacing: 0em;
    text-align: center;
  }
  > p {
    //styleName: new1.1/Body/4;
    font-family: "Lexend";
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.7rem;
    letter-spacing: 0em;
    text-align: center;
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    > h2 {
      font-size: 3.8rem;
      font-weight: 400;
      line-height: 3.2rem;
      letter-spacing: 0em;
      text-align: center;
    }
    p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.7rem;
      letter-spacing: 0em;
      text-align: center;
    }
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    //styleName: new1.1/Body/medium 2;
    font-family: "Lexend";
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
