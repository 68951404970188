import React, { useState } from "react";
import { IMenu } from "../../constant/menu";
import { styled } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { breakPoints } from "../../constant/breakpoints";
import { useTranslation } from "react-i18next";

const MenuItem = ({ item }: { item: IMenu }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const homePage = pathname === "/" && item.url === "/";
  const isActive = pathname.includes(item.url) && item.url !== "/";

  return (
    <Container
      $active={homePage || isActive}
      onMouseEnter={() => setShowDropdownMenu(true)}
      onMouseLeave={() => setShowDropdownMenu(false)}
    >
      <Link to={item.url}>{t(item.label)}</Link>
      {item.children && (
        <SubMenuContainer $show={showDropdownMenu}>
          {item.children.map((child) => (
            <MenuItem key={child.id} item={child} />
          ))}
        </SubMenuContainer>
      )}
    </Container>
  );
};

export default MenuItem;

const Container = styled.li<{ $active?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 7rem;
  padding: 0.4rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;
  /* ${({ $active }) =>
    $active && "border-bottom: 2px solid var(--Primary-gradient, #FEF7EC);"} */
  > a,
  span {
    /* Body/2 */
    font-family: "Unbounded";
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5; /* 4.32rem */
    color: #fafafa;
    transition: all 0.2s;
    white-space: nowrap;
    &:hover {
      color: #0044ff;
      filter: brightness(1.2);
    }
  }
  cursor: pointer;

  @media (min-width: ${breakPoints.laptop}) {
    min-width: 9rem;
    > a,
    span {
      font-size: 1.3rem;
      line-height: 180%; /* 4.32rem */
    }
  }
  @media (min-width: ${breakPoints.desktop}) {
    min-width: 10rem;
    > a,
    span {
      font-size: 1.3rem;
    }
  }
`;
const SubMenuContainer = styled.ul<{ $show: boolean }>`
  z-index: 10;
  position: absolute;
  top: 100%;
  background-color: #141414;
  padding: 2rem 4rem;
  transform-origin: top center;
  user-select: none;
  transform: ${({ $show }) => ($show ? "scaleY(1)" : "scaleY(0)")};
  transition: ease-in-out 150ms;
  li {
    width: 100%;
  }
`;
