import React from "react";

const ArrowDownIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ii_2205_1049)">
        <rect
          x="20.8359"
          y="0.112549"
          width="20.0674"
          height="20.0674"
          rx="10.0337"
          transform="rotate(90 20.8359 0.112549)"
          fill="#FBFBFF"
          fill-opacity="0.58"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.3325 8.37853C5.79324 7.89238 6.56082 7.87178 7.04697 8.33251L11.0637 12.1392L15.0805 8.33251C15.5667 7.87178 16.3342 7.89238 16.7949 8.37853C17.2557 8.86468 17.2351 9.63227 16.749 10.0929L11.898 14.6904C11.4301 15.1337 10.6974 15.1337 10.2295 14.6904L5.37854 10.0929C4.89238 9.63227 4.87178 8.86468 5.3325 8.37853Z"
        fill="#404040"
      />
      <defs>
        <filter
          id="filter0_ii_2205_1049"
          x="0.767578"
          y="-1.04811"
          width="20.0684"
          height="21.7439"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.16066" />
          <feGaussianBlur stdDeviation="0.741534" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.74 0 0 0 0 0.7918 0 0 0 0 0.925 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2205_1049"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.51585" />
          <feGaussianBlur stdDeviation="2.43739" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_2205_1049"
            result="effect2_innerShadow_2205_1049"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ArrowDownIcon;
