import React, { useState } from "react";
import { styled } from "styled-components";
import PlusIcon from "../../../../icons/PlusIcon";
import { useTranslation } from "react-i18next";
import MinusIcon from "../../../../icons/MinusIcon";
import { breakPoints } from "../../../../constant/breakpoints";

interface IQuestion {
  question: string;
  answer: string;
  id: number;
}
const questions: IQuestion[] = [
  {
    id: 1,
    question: "How can I order POOLS PHONE as a gift?",
    answer:
      "Just enter the recipient's address when you checkout! For special requests or gift-wrapping options, feel free to contact our friendly customer support team for help.",
  },
  {
    id: 2,
    question: "Do you ship internationally?",
    answer:
      "Absolutely! We ship POOLS Phone to many countries around the world. Designed to be accessible for everyone, we're happy to bring the POOLS experience to customers everywhere.",
  },
  {
    id: 3,
    question: "How long will it take for my order to ship?",
    answer:
      "Delivery times depend on where you live, the shipping method you choose, and customs processing. You can find estimated delivery times on your order confirmation, or chat with our customer support team for more details.",
  },
  {
    id: 4,
    question: "Do you accept returns?",
    answer:
      "You can request a full refund for any order before it ships. Once shipped, we encourage you to contact us as soon as possible if you need to return your phone. We'll review your request and gladly work with you to find a solution.",
  },
];
const Questions = () => {
  const { t } = useTranslation();
  const [currentQuestionsActive, setCurrentQuestionsActive] = useState<
    number[]
  >([]);

  const onClickQuestionHandler = (id: number) => {
    if (currentQuestionsActive.includes(id)) {
      setCurrentQuestionsActive((prev) => prev.filter((item) => item !== id));
    } else {
      setCurrentQuestionsActive((prev) => [...prev, id]);
    }
  };

  return (
    <Container>
      {questions.map((item, index) => (
        <QuestionItemWrapper key={index}>
          <Break />
          <Question onClick={() => onClickQuestionHandler(item.id)}>
            <span>{t(item.question)}</span>
            {currentQuestionsActive.includes(item.id) ? (
              <MinusIcon />
            ) : (
              <PlusIcon />
            )}
          </Question>
          <Answer $isActive={currentQuestionsActive.includes(item.id)}>
            {t(item.answer)}
          </Answer>
        </QuestionItemWrapper>
      ))}
      <Break />
    </Container>
  );
};

export default Questions;

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  @media screen and (max-width: ${breakPoints.ipad}) {
    gap: 1rem;
  }
`;
const QuestionItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media screen and (max-width: ${breakPoints.ipad}) {
    gap: 1rem;
  }
`;
const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;
  > span {
    display: inline-block;
    //styleName: new1.1/Body/2;
    font-family: "Lexend";
    font-size: 24px;
    font-weight: 400;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    gap: 2rem;
    > span {
      //styleName: new1.1/Body/4;
      font-family: "Lexend";
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.7rem;
      letter-spacing: 0em;
      text-align: left;
    }
    > svg {
      max-width: 1.5rem;
      max-height: 3rem;
    }
  }
`;
const Answer = styled.div<{ $isActive: boolean }>`
  //styleName: new1.1/Body/3;

  font-family: "Lexend";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #727272;
  display: ${({ $isActive }) => ($isActive ? "block" : "none")};
  /* height: ${({ $isActive }) => ($isActive ? "auto" : "0")};
  overflow: hidden; */

  transition: 0.3s ease;
  @media screen and (max-width: ${breakPoints.mdTablet}) {
    font-size: 1.4rem;
  }
`;
const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: #727272;
`;
