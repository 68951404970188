import React from "react";

const FacebookIcon = ({
  width = 32,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M18.6673 17.9998H22.0007L23.334 12.6665H18.6673V9.99984C18.6673 8.6265 18.6673 7.33317 21.334 7.33317H23.334V2.85317C22.8993 2.79584 21.258 2.6665 19.5247 2.6665C15.9047 2.6665 13.334 4.87584 13.334 8.93317V12.6665H9.33398V17.9998H13.334V29.3332H18.6673V17.9998Z"
        fill="#fff"
      />
    </svg>
  );
};

export default FacebookIcon;
