import { useTranslation } from "react-i18next";
import "./custom.css";
import styled from "styled-components";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { PaymentMethod } from ".";
import { toast } from "react-toastify";
import PaypalIcon from "./icons/paypal.svg";
import StripeIcon from "./icons/stripe.svg";
import PoolIcon from "./icons/pools.svg";
import { breakPoints } from "../../constant/breakpoints";
import { useAccount, useWaitForTransaction } from "wagmi";
import { usePayment } from "../../hooks/useContract";
import Api from "../../service";
import { useNavigate } from "react-router-dom";
import useCart from "../../hooks/use-cart";
import FormV2 from "../Purchase/components/FormV2";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const LinkTerm = styled.a`
  text-decoration: none;
  color: #3765de;
  cursor: pointer;
  font-size: 1.7rem;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.4rem;
  }
`;
const PayMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
`;
const Title = styled.span`
  color: var(--Grey-70, #515151);
  text-align: right;
  font-family: "Archivo";
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 2.6rem */
`;
const PaymentMethodBox = styled.div<{
  isActive?: boolean;
  isDisable?: boolean;
}>`
  display: flex;
  padding: 2rem 4rem;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  border-radius: 1.6rem;
  border: 1px solid ${({ isActive }) => (isActive ? "#545454" : "#d9d9d9")};
  background-color: ${({ isActive }) => isActive && "#a3cbff"};
  /* cursor: ${({ isDisable }) => (!isDisable ? "pointer" : "not-allowed")}; */
  cursor: pointer;
  opacity: ${({ isDisable }) => (!isDisable ? 1 : 0.5)};
  span {
    color: var(--gray-scale-black-80, #2d2d2d);
    text-align: center;
    font-family: "Archivo";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  svg {
    max-height: 5.8rem;
  }
  img {
    max-width: 5.8rem;
    max-height: 5.8rem;
  }
  @media (max-width: ${breakPoints.mobile}) {
    img {
      max-width: 3rem;
    }
    span {
      font-size: 1.6rem;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const MethodContainer = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: ${breakPoints.mobile}) {
    flex-direction: column;
  }
`;

const PolicyContainer = styled.div`
  display: "flex";
  font-size: "1.6rem";
  gap: "1rem";
  align-items: "center";
  margin-top: "4rem";
  label {
    margin-left: 0.5rem;
    color: #000;
    font-family: Rubik;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media (max-width: ${breakPoints.mobile}) {
    margin: 2rem 0;
    label {
      font-size: 1.4rem;
    }
  }
`;

const Step3 = ({
  checkAgree,
  setCheckAgree,
  setMethod,
  method,
  order_code,
  totalPools,
  setLoadingSubmit,
}: {
  checkAgree: boolean;
  setCheckAgree: Dispatch<SetStateAction<boolean>>;
  setMethod: Dispatch<SetStateAction<PaymentMethod | null>>;
  method: string | null;
  order_code: string;
  totalPools: number;
  setLoadingSubmit: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { address } = useAccount();

  const { changePaymentMethod } = useCart();
  const windowDimension = useWindowDimensions();
  const isIpad = windowDimension.width <= 820;

  const navigate = useNavigate();

  // contract
  const {
    data: orderCryptoData,
    writeAsync: orderCrypto,
    error: errorPrepare,
  } = usePayment({
    order_code: order_code,
    total: totalPools,
    value: totalPools,
  });

  const {
    isError,
    isLoading: loadingTransaction,
    isSuccess,
  } = useWaitForTransaction({
    hash: orderCryptoData?.hash,
  });

  const handleOrderFromContract = useCallback(async () => {
    setLoadingSubmit(true);
    try {
      await orderCrypto?.();
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoadingSubmit(false);
    }
  }, [order_code, totalPools, orderCrypto]);

  const checkoutOrderCrypto = async () => {
    try {
      if (!address) return;
      const response = await Api.Payment.checkoutOrderCrypto({
        orders_code: order_code,
        wallet_address: address,
      });
      if (response?.data) {
        navigate("/purchase/approved?type=success");
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const setMethodPayment = (method: PaymentMethod) => {
    setMethod(method);
    changePaymentMethod(method);
  };

  useEffect(() => {
    if (isError) {
      toast.error(t("Something went wrong"));
      setLoadingSubmit(false);
    }
    if (isSuccess) {
      checkoutOrderCrypto();
    }
    if (loadingTransaction) {
      setLoadingSubmit(true);
    }
    if (errorPrepare && order_code) {
      toast.error(t("You do not have enough balance"));
      setLoadingSubmit(false);
    }
  }, [isError, errorPrepare, loadingTransaction, isSuccess]);

  useEffect(() => {
    if (order_code) {
      handleOrderFromContract();
    }
  }, [order_code, handleOrderFromContract]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    changePaymentMethod(method as PaymentMethod);
  }, []);

  return (
    <Container>
      {/* <PayMethodContainer>
        <Title>{t("Pay by Visa Card")}</Title>
        <PaymentMethodBox
          onClick={(e) => {
            toast.info(t("Coming Soon"));
          }}
        >
          <span>{t("Coming Soon")}</span>
        </PaymentMethodBox>
      </PayMethodContainer> */}
      <PayMethodContainer>
        <Title>{t("International Transfer")}</Title>
        <MethodContainer>
          <PaymentMethodBox
            isActive={method === PaymentMethod.Paypal}
            onClick={(e) => setMethodPayment(PaymentMethod.Paypal)}
          >
            <img src={PaypalIcon} alt="" />
            <span>{t("PayPal")}</span>
          </PaymentMethodBox>
          {/* coming soon */}
          <PaymentMethodBox
            // isActive={method === PaymentMethod.Stripe}
            // onClick={(e) => setMethodPayment(PaymentMethod.Stripe)}
            onClick={() => {
              toast.info(t("Coming Soon"));
            }}
            isDisable={true}
          >
            <img src={StripeIcon} alt="" />
            <span>{t("Stripe")}</span>
          </PaymentMethodBox>
        </MethodContainer>
      </PayMethodContainer>

      <PayMethodContainer>
        <Title>{t("Pay by Cryptocurrency")}</Title>
        {/* coming soon */}
        <PaymentMethodBox
          // isActive={method === PaymentMethod.Pools}
          // onClick={(e) => setMethodPayment(PaymentMethod.Pools)}
          isDisable={true}
          onClick={() => {
            toast.info(t("Coming Soon"));
          }}
        >
          <img src={PoolIcon} alt="" />
          <span>POOLS</span>
        </PaymentMethodBox>
      </PayMethodContainer>

      {/*  */}
      {isIpad && (
        <div style={{ margin: "2rem 0" }}>
          <FormV2 hideBtn={true} hideRefInput={true} />
        </div>
      )}
      {/*  */}
      <PolicyContainer>
        <input
          type="checkbox"
          id="checkbox"
          checked={checkAgree}
          onChange={(e) => {
            setCheckAgree(e.target.checked);
          }}
        />
        <label htmlFor="checkbox" style={{ color: "black" }}>
          {t("I confirm that I have read, consent and agree to Start24's")}{" "}
        </label>
        {/*  */}
        <LinkTerm
          href="https://daziu008xh8wa.cloudfront.net/assets/START24_TERMS+OF+USE.pdf"
          target="_blank"
        >
          {t("Term of Use")}
        </LinkTerm>
        {/*  */}
        <label htmlFor="checkbox" style={{ color: "black" }}>
          {t("and")}{" "}
        </label>
        <LinkTerm
          href="https://daziu008xh8wa.cloudfront.net/assets/START24_PRIVACY+POLICY.pdf"
          target="_blank"
        >
          {t("Privacy Policy")}.
        </LinkTerm>
        {/*  */}
        <label htmlFor="checkbox" style={{ color: "black" }}>
          {t(
            "For more details regarding delivery and refund policies, see our"
          )}{" "}
        </label>
        <LinkTerm
          href="https://daziu008xh8wa.cloudfront.net/assets/START24_DELIVERY+POLICY.pdf"
          target="_blank"
        >
          {t("Delivery Policy")}
        </LinkTerm>
        {/*  */}
        <label htmlFor="checkbox" style={{ color: "black" }}>
          {t("and")}{" "}
        </label>
        <LinkTerm
          href="https://daziu008xh8wa.cloudfront.net/assets/START24_REFUND+POLICY.pdf"
          target="_blank"
        >
          {t("Refund Policy")}.
        </LinkTerm>
      </PolicyContainer>
    </Container>
  );
};

export default Step3;
