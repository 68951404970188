import * as React from "react";
import styled from "styled-components";
import { MainLayoutContainer } from "../../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import Feature1 from "../assets/images/interactive/interactive-1.png";
import Feature2 from "../assets/images/interactive/interactive-2.png";
import Feature3 from "../assets/images/interactive/interactive-3.png";
import Feature4 from "../assets/images/interactive/interactive-4.png";
import Feature5 from "../assets/images/interactive/interactive-5.png";
import Feature6 from "../assets/images/interactive/interactive-6.png";
import Feature7 from "../assets/images/interactive/interactive-7.png";
import Feature8 from "../assets/images/interactive/interactive-8.png";
import Feature9 from "../assets/images/interactive/interactive-9.png";
import Feature10 from "../assets/images/interactive/interactive-10.png";
import Feature11 from "../assets/images/interactive/interactive-11.png";
import { breakPoints } from "../../../constant/breakpoints";
import ScrollAnimation from "react-animate-on-scroll";

function Feature() {
  const { t } = useTranslation();

  return (
    <section>
      <Container>
        <ScrollAnimation animateIn="fadeIn">
          <Heading>
            <h5>{t("Key Highlight")}</h5>
            <h1>{t("Speical Features")}</h1>
          </Heading>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
          <Content>
            <Row $column={12}>
              <Column $span={5}>
                <Image src={Feature2} alt="" />
                <Image src={Feature1} alt="" />
              </Column>
              <Column $span={7}>
                <Row $columnMobile={2} $column={2}>
                  <Column>
                    <Image src={Feature7} alt="" />
                  </Column>
                  <Column>
                    <Image src={Feature6} alt="" />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Image src={Feature5} alt="" />
                  </Column>
                </Row>
              </Column>
            </Row>
            <Row $column={12}>
              <Column $span={7}>
                <Image src={Feature4} alt="" />
              </Column>
              <Column $span={5}>
                <Image src={Feature3} alt="" />
              </Column>
            </Row>
            <Row $column={12}>
              <Column $span={5}>
                <Image src={Feature8} alt="" />
              </Column>
              <Column $span={7}>
                <Image src={Feature9} alt="" />
              </Column>
            </Row>
            <Row $column={12}>
              <Column $span={7}>
                <Image src={Feature10} alt="" />
              </Column>
              <Column $span={5}>
                <Image src={Feature11} alt="" />
              </Column>
            </Row>
          </Content>
        </ScrollAnimation>
      </Container>
    </section>
  );
}

export default Feature;

const Container = styled(MainLayoutContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;

  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 12rem;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 0.4rem;
  > h5 {
    color: #262626;
    font-family: Unbounded;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
  }
  > h1 {
    color: #262626;
    font-family: Unbounded;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
  }
  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 2.4rem;
    > h5 {
      font-size: 24px;
    }
    > h1 {
      font-size: 96px;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;

  img:hover {
    transition: all ease 300ms;
    transform: scale(1.05);
  }

  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 2.4rem;
  }
`;

const Row = styled.div<{
  $column?: number;
  $gap?: number;
  $columnMobile?: number;
}>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.$columnMobile ? props.$columnMobile : 1)},
    minmax(0, 1fr)
  );
  gap: 0.8rem;
  @media (min-width: ${breakPoints.laptop}) {
    gap: 2.4rem;
    grid-template-columns: repeat(
      ${(props) => (props.$column ? props.$column : 1)},
      minmax(0, 1fr)
    );
  }
`;

const Column = styled.div<{ $span?: number }>`
  display: flex;
  flex-direction: column;
  grid-column: span 1 / span 1;
  row-gap: 0.8rem;
  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 2.4rem;
    grid-column: span ${(props) => (props.$span ? props.$span : 1)} / span
      ${(props) => (props.$span ? props.$span : 1)};
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  @media (min-width: ${breakPoints.laptop}) {
    border-radius: 2.4rem;
  }
`;
