import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import BackgroundSubscribe from "../assets/images/subscribe/background-subscribe.png";
import BackgroundNoPhone from "../assets/images/subscribe/background-no-phone.png";
import BackgroundNoPhoneMb from "../assets/images/subscribe/background-no-phone-mb.png";
import TwoPhone from "../assets/images/subscribe/two-phone.png";
import TwoPhoneMobile from "../assets/images/subscribe/two-phone-mobile.png";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { breakPoints } from "../../../constant/breakpoints";
import ScrollAnimation from "react-animate-on-scroll";
import { useForm } from "react-hook-form";
import useRequest from "../../../hooks/use-request";
import Api from "../../../service";
import Spin from "../../../components/Spin";
type FormInputs = {
  user_email: string;
  type: "USER" | "AGENT";
};
const Subscribe = () => {
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();
  const isMdDesktop = windowDimensions.width <= 1280;
  const isIpad = windowDimensions.width <= 768;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormInputs>();

  const {
    data: subscribeData,
    error: subscribeError,
    loading,
    doRequest,
  } = useRequest<FormInputs>({
    url: Api.Subscriber.subscribe,
    method: "post",
    onSuccess: () => {
      toast.success("Submit success!");
      reset({
        user_email: "",
      });
    },
  });
  //
  const onSubmit = (formData: FormInputs) => {
    doRequest({ ...formData, type: "USER" });
  };
  if (subscribeError) {
    toast.error(subscribeError);
  }
  if (errors) {
    toast.error(errors.user_email?.message);
  }
  return (
    <section>
      {!isMdDesktop ? (
        <ScrollAnimation animateIn="fadeInUp" animateOnce duration={0.85}>
          <Container>
            <ScrollAnimation animateIn="fadeInUp" animateOnce duration={0.85}>
              <WrapperImage>
                <img src={TwoPhone}></img>
              </WrapperImage>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce duration={0.85}>
              <WrapperSubscription>
                <WrapperIntroduce>
                  <h3>{t("Pre-Order Today!")}</h3>
                  <h2>{t("The Future Awaits")}</h2>
                  <span>{t("Be Among the First - Be Extraordinary")}</span>
                </WrapperIntroduce>
                <WrapperForm>
                  {/* <h3>{t("Subscribe & Don't Miss Out")}</h3> */}
                  <Form>
                    <input
                      {...register("user_email", {
                        required: {
                          value: true,
                          message: "Can't be empty!",
                        },
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                          message: "Invalid email!",
                        },
                      })}
                      placeholder={t("Subscribe & Don't Miss Out")}
                      size={1}
                      type="email"
                      required
                    ></input>
                    <button onClick={handleSubmit(onSubmit)} disabled={loading}>
                      {loading ? <Spin color="#fff" /> : t("Subscribe")}
                    </button>
                  </Form>
                </WrapperForm>
              </WrapperSubscription>
            </ScrollAnimation>
          </Container>
        </ScrollAnimation>
      ) : (
        <ContainerMobile>
          <ScrollAnimation animateIn="fadeInUp" animateOnce duration={0.85}>
            <WrapperSubscription>
              <WrapperIntroduce>
                <h3>{t("Pre-Order Today!")}</h3>
                <h2>{t("The Future Awaits")}</h2>
                <span>{t("Be Among the First - Be Extraordinary")}</span>
              </WrapperIntroduce>
              <WrapperForm>
                {/* <h3>{t("Subscribe & Don't Miss Out")}</h3> */}
                <Form>
                  <input
                    placeholder={t("Subscribe & Don't Miss Out")}
                    {...register("user_email", {
                      required: {
                        value: true,
                        message: "Can't be empty!",
                      },
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                        message: "Invalid email!",
                      },
                    })}
                    size={1}
                    type="email"
                    required
                  ></input>
                  <button onClick={handleSubmit(onSubmit)} disabled={loading}>
                    {loading ? <Spin color="#fff" /> : t("Subscribe")}
                  </button>
                </Form>
              </WrapperForm>
            </WrapperSubscription>
          </ScrollAnimation>
          <WrapperImage>
            <img src={isIpad ? TwoPhoneMobile : TwoPhone}></img>
          </WrapperImage>
        </ContainerMobile>
      )}
    </section>
  );
};

export default Subscribe;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6rem;
  padding: 6rem 5rem 0rem;
  height: 100%;
  background-image: url(${BackgroundNoPhone});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0 12px 48px 1px rgba(0, 71, 255, 0.22);
  @media screen and (min-width: ${breakPoints.desktop}) {
    min-height: 614px;
  }
`;

const ContainerMobile = styled.div`
  display: flex;
  gap: 4.203rem;
  flex-direction: column;
  padding: 2.697rem 2.4rem 0;
  background-image: url(${BackgroundNoPhoneMb});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0 12px 48px 1px rgba(0, 71, 255, 0.22);
`;

const WrapperImage = styled.div`
  width: 100%;
  height: 100%;
  > img {
    width: 100%;
    height: auto;
    min-height: 100%;
  }
  @media screen and (max-width: ${breakPoints.lgTablet}) {
    display: flex;
    justify-content: center;
  }
`;

const WrapperSubscription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin: 9.55rem 0;
  padding: 0 17.4rem;
  width: 100%;
  max-width: 984px;
  text-align: center;
  @media screen and (min-width: ${breakPoints.mDesktop}) and (max-width: ${breakPoints.lgDesktop}) {
    padding: 0 0;
  }
  @media screen and (min-width: ${breakPoints.mDesktop}) and (max-width: ${breakPoints.desktop}) {
    padding: 0 0;
    max-width: 637px;
  }
  @media screen and (min-width: ${breakPoints.laptop}) and (max-width: ${breakPoints.mDesktop}) {
    max-width: 100%;
  }
  @media screen and (max-width: ${breakPoints.laptop}) {
    margin: 0 0;
    padding: 0 0;
    gap: 2.4rem;
    max-width: 100%;
  }
`;

const WrapperIntroduce = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Unbounded;
  text-align: center;
  gap: 0.8rem;
  > h3 {
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 3rem;
    color: #143079;
  }
  > h2 {
    font-weight: 400;
    font-size: 5.4rem;
    line-height: 6rem;
    color: #0047ff;
  }
  > span {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.7rem;
    color: #143079;
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    > h3 {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 3rem;
      color: #143079;
    }
    > h2 {
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 6rem;
      color: #0047ff;
    }
    > span {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #143079;
    }
  }
`;

const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  > h3 {
    font-family: Lexend;
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 5.12rem;
    text-align: center;
    color: #1f1f1f;
  }
`;

const Form = styled.form`
  padding-left: 2.4rem;
  border-radius: 5rem;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  box-shadow: 0 12px 48px rgba(0, 71, 255, 0.22);
  > input {
    outline: none;
    border: none;
    width: 100%;
    border-radius: 5rem;

    &::placeholder {
      font-family: Lexend;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.72rem;
      color: rgba(139, 142, 153, 0.7);
    }
    &[type="email"] {
      font-family: Lexend;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.72rem;
    }
  }
  > button {
    border-radius: 5rem;
    background-color: #0047ff;
    border: none;
    padding: 1.5rem 5.6rem;
    font-family: Lexend;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.8rem;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
      background-color: #ffffff;
      color: #0047ff;
    }
  }
  @media screen and (max-width: ${breakPoints.ipad}) {
    > input {
      &::placeholder {
        font-size: 1.4rem;
        line-height: 2.1rem;
      }
      &[type="email"] {
        font-size: 1.4rem;
        line-height: 2.1rem;
      }
    }
    > button {
      padding: 0.65rem 1.6rem;
      font-family: Archivo;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.72rem;
      color: #fafafa;
    }
  }
`;
