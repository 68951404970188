import React from "react";

const InstagramIcon = ({
  width = 32,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M22.6582 2.66748H9.32487C5.64297 2.66748 2.6582 5.65225 2.6582 9.33415V22.6675C2.6582 26.3494 5.64297 29.3341 9.32487 29.3341H22.6582C26.3401 29.3341 29.3249 26.3494 29.3249 22.6675V9.33415C29.3249 5.65225 26.3401 2.66748 22.6582 2.66748Z"
        stroke="#fff"
        stroke-width="1.73333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.3238 15.1619C21.4884 16.2715 21.2988 17.4048 20.7822 18.4006C20.2655 19.3963 19.448 20.2037 18.446 20.7081C17.444 21.2124 16.3084 21.388 15.2009 21.2098C14.0933 21.0316 13.0702 20.5086 12.2769 19.7154C11.4837 18.9222 10.9608 17.899 10.7826 16.7915C10.6044 15.6839 10.7799 14.5484 11.2843 13.5464C11.7886 12.5443 12.5961 11.7269 13.5918 11.2102C14.5875 10.6935 15.7208 10.504 16.8305 10.6685C17.9624 10.8364 19.0103 11.3638 19.8194 12.1729C20.6285 12.9821 21.156 14.03 21.3238 15.1619Z"
        stroke="#fff"
        stroke-width="1.73333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.3281 8.66748H23.3415"
        stroke="#fff"
        stroke-width="1.73333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default InstagramIcon;
