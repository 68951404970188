import request from "../utils/request";

const UserApi = {
  getProfile: async () => {
    const response: any = await request.get("/user/profile");
    if (response?.statusCode === 200) {
      return response?.data;
    } else {
      return null;
    }
  },
  updateProfile: async (data: { fullname: string; avatar?: string }) => {
    const response: any = await request.post("/user/update/profile", data);
    if (response.statusCode === 200) {
      return response?.data;
    } else {
      return null;
    }
  },
  updatePassword: async (data: {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }) => {
    return await request.post("/user/update/password", data);

  },
  updateAvatar: async (avatar: File) => {
    const response: any = await request.post(
      "/user/update/avatar",
      { avatar },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.statusCode === 200) {
      return response?.data;
    } else {
      return null;
    }
  },
  subcribe: async () => { },
};

export default UserApi;
